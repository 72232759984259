<template>
	<div class="swimlane-view" v-if="swimlanes.length > 0">
		<div class="date-grid">
			<div class="date-grid-row" v-for="(date, index) of dates" :key="index">
				<span
					class="date-grid-label"
					:class="{
						'display-month': isFirstOfMonth(date, index),
					}"
				>
					{{ date | moment(isFirstOfMonth(date, index) ? "D. MMM" : "D.") }}
				</span>
			</div>
		</div>

		<div class="swimlanes">
			<swimlane
				class="swimlane"
				v-for="(swimlane, index) of swimlanes"
				:entity="entity"
				:now="now"
				:slots="swimlane"
				:key="index"
				:style="{
					// 'z-index': swimlanes.length - index,
				}"
				@edit="$listeners.edit"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Swimlane from "./Swimlane.vue";

@Component({
	components: { Swimlane },
})
export default class SwimlaneView extends Vue {
	@Prop({ required: true })
	entity!: any;

	@Prop({ default: [] })
	events!: any[];

	now = new Date();
	dates: Date[] = [];
	swimlanes: any[] = [];

	get sorted() {
		return this.events.sort((a, b) => {
			const av = a.start.valueOf();
			const bv = b.start.valueOf();

			return av > bv ? 1 : av < bv ? -1 : 0;
		});
	}

	isFirstOfMonth(date: Date, index: number) {
		return index === 0 || date.getDate() === 1;
	}

	@Watch("events", { deep: true, immediate: true })
	onEventsChange() {
		this.generateDatesArray();
		this.generateSwimlanesArray();
	}

	// Generates an array of all dates in ranges found in events property.
	generateDatesArray() {
		this.dates = [];

		if (this.sorted.length <= 0) return;

		// const lastDate = this.events.reduce((prev, current) => prev.end.valueOf() > current.end.valueOf() ? prev : current).end;
		const lastDate = this.sorted[this.sorted.length - 1].end;
		let current = new Date(this.now.valueOf());

		while (current.valueOf() < lastDate.valueOf()) {
			this.dates.push(current);
			current = new Date(current.valueOf() + 1000 * 60 * 60 * 24); // tslint:disable-line
		}
	}

	// Generates array arrays representing swimlanes and events in that swimlane,
	// adding events to the first swimlane without overlapping events.
	generateSwimlanesArray() {
		this.swimlanes = [];

		const swimlanes: any[] = [];
		let previous: any = null;
		let overlap = 0;

		this.sorted.forEach((slot) => {
			if (previous && slot.start.valueOf() < previous.end.valueOf()) {
				overlap = overlap + 1;
			} else {
				overlap = 0;
			}

			if (!swimlanes[overlap]) {
				swimlanes[overlap] = [];
			}

			swimlanes[overlap].push(slot);
			previous = slot;
		});

		this.swimlanes = swimlanes;
	}
}
</script>

<style lang="scss" scoped>
$date-grid-height: 30px;
$date-grid-label-width: 100px;

.swimlane-view {
	position: relative;
}

.date-grid {
	&-row {
		border-top: 1px solid rgba(255, 255, 255, 0);
		box-sizing: border-box;
		height: $date-grid-height;
	}

	&-row + &-row {
		border-top: 1px solid lightgrey;
	}

	&-label {
		box-sizing: border-box;
		color: lightgrey;
		display: block;
		line-height: $date-grid-height;
		padding-right: 20px;
		pointer-events: none;
		user-select: none;
		text-align: right;
		width: $date-grid-label-width;

		&.display-month {
			color: rgba(0, 0, 0, 0.6);
		}
	}
}

.swimlanes {
	border-left: 1px solid lightgray;
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: $date-grid-label-width;
}

.swimlane {
	margin: 0 20px 0;
	position: relative;
	width: 280px;
}

.swimlane + .swimlane {
	margin-left: 0;
}
</style>
