<template>
	<v-dialog class="sz-dialog-card-form" v-model="isOpen" :max-width="maxWidth">
		<sz-card-form
			:data="data"
			:title="title"
			:error-message="errorMessage"
			:success-message="successMessage"
			:submit="submit"
			:cancel="_cancel"
			:fields="fields"
		/>
	</v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { FormField } from "../interfaces";

@Component
export default class SzDialogCardForm extends Vue {
	@Prop({ default: false })
	value!: boolean;

	@Prop({ default: () => ({}) })
	data!: any;

	@Prop()
	title!: string;

	@Prop()
	errorMessage!: string;

	@Prop()
	successMessage!: string;

	@Prop({ required: true, type: [Function] })
	submit!: any;

	@Prop({ type: [Function] })
	cancel!: any;

	@Prop({ required: true, type: [Array] })
	fields!: FormField[];

	@Prop({ type: [String, Number] })
	maxWidth!: string | number;

	get isOpen() {
		return !!this.value;
	}

	set isOpen(value: boolean) {
		this.$emit("input", value);
	}

	async _cancel(e: Event) {
		if (this.cancel) {
			return this.cancel(e);
		}

		this.isOpen = false;
	}
}
</script>
