import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { NotesState } from "./types";

export const state: NotesState = {
	notes: [],
};

export const store: Module<NotesState, RootState> = {
	actions,
	getters,
	mutations,
	state,
};

export default store;
