<template>
	<v-container fluid fill-height>
		<v-layout align-center justify-center>
			<v-flex xs12 sm8 md6>
				<v-card>
					<v-card-title primary-title>
						<h1>{{ $t("errors.404.title") }}</h1>
					</v-card-title>

					<v-card-text>
						<p>{{ $t("errors.404.infoText") }}</p>

						<p>
							<router-link :to="{ path: '/' }">{{ $t("errors.404.goToHome") }}</router-link>
						</p>
					</v-card-text>
				</v-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
	title: () => "errors.404.title",
})
export default class GlobalError404 extends Vue {}
</script>
