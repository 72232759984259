<template>
	<v-card color="warning">
		<v-card-title class="title">{{ $t("errors.404.title") }}</v-card-title>

		<v-card-text>{{ $t("errors.404.infoText") }}</v-card-text>
	</v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
	title: () => "errors.404.title",
})
export default class AdminError404 extends Vue {}
</script>
