<template>
	<div class="sz-input sz-text-editor">
		<label>{{ label }}</label>
		<quill-editor v-model="szValue" :options="editorOptions"></quill-editor>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { quillEditor } from "vue-quill-editor";

const DEFAULT_FORMATS = ["bold", "color", "italic", "strike", "underline", "header", "list", "emoji"];

const DEFAULT_CONTAINER = [
	[{ header: [1, 2, false] }],
	["bold", "italic", "underline", "strike"],
	[{ color: [] }],
	[{ list: "ordered" }, { list: "bullet" }],
	["emoji"],
];

const DEFAULT_HANDLERS = {
	emoji: function () {},
};

@Component({
	components: { quillEditor },
})
export default class SzTextEditor extends Vue {
	@Prop()
	value!: string;

	@Prop()
	label!: string;

	@Prop({ default: () => DEFAULT_FORMATS })
	formats!: string[];

	@Prop({ default: () => DEFAULT_CONTAINER })
	container!: any[][];

	@Prop({ default: () => DEFAULT_HANDLERS })
	handlers!: { [key: string]: Function };

	@Prop({ default: true })
	emojiToolbar!: boolean;

	@Prop({ default: false })
	emojiTextarea!: boolean;

	@Prop({ default: true })
	emojiShortname!: boolean;

	szValue: string = "";

	get editorOptions() {
		return {
			formats: this.formats,
			modules: {
				toolbar: {
					container: this.container,
					handlers: this.handlers,
				},
				"emoji-toolbar": this.emojiToolbar,
				"emoji-textarea": this.emojiTextarea,
				"emoji-shortname": this.emojiShortname,
			},
		};
	}

	@Watch("value", { immediate: true })
	protected _onValueChange() {
		this.szValue = this.value;
	}

	@Watch("szValue")
	protected _onSzValueChange() {
		this.$emit("input", this.szValue);
	}
}
</script>

<style lang="scss">
.sz-text-editor {
	padding: 0;
	border: none;

	> label {
		color: rgba(0, 0, 0, 0.54);
		display: block;
		font-size: 16px;
		height: 20px;
		line-height: 20px;
		cursor: pointer;
		user-select: none;
		margin-bottom: 5px;
	}

	.quill-editor {
		width: 100%;

		.ql-editor {
			min-height: 100px;

			h1,
			h2,
			p,
			ul,
			ol {
				margin-bottom: 15px;
			}

			h2 {
				font-weight: normal;
			}
		}
	}
}
</style>
