<template>
	<div class="sz-input plain sz-color-swatches">
		<v-menu v-model="showMenu">
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" :style="{ 'background-color': szValue }">
					<v-icon>colorize</v-icon>
				</v-btn>
			</template>

			<swatches v-model="szValue" :palette="palette" />
		</v-menu>

		<label @click="showMenu = true">{{ label }}</label>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Swatches from "@/components/ColorPicker/Swatches.vue";

@Component({
	components: { Swatches },
})
export default class SzColorSwatches extends Vue {
	@Prop()
	value!: string;

	@Prop()
	label!: string;

	@Prop()
	palette!: string[][];

	szValue: string = "";
	showMenu = false;

	@Watch("value", { immediate: true })
	protected _onValueChange() {
		this.szValue = this.value;
	}

	@Watch("szValue")
	protected _onSzValueChange() {
		this.$emit("input", this.szValue);
	}
}
</script>

<style lang="scss">
.sz-color-swatches {
	&.sz-input {
		gap: 8px;
		padding: 0;
	}

	> label {
		color: rgba(0, 0, 0, 0.54);
		font-size: 16px;
		height: 18px;
		// line-height: 20px;
		cursor: pointer;
		user-select: none;
	}

	.v-btn {
		margin-left: 0;
		margin-top: 0;
		margin-bottom: 0;
		padding: 0 7px;
		min-width: 0;
	}
}
</style>
