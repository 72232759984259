<template>
	<div class="content-card" :class="{ collapsible: isCollapsible, collapsed: isCollapsed }">
		<div class="toolbar" v-if="hasToolbarOrTitle">
			<div class="title" :class="[titleExtraClass]" @click="toggleCollapse">
				<div v-if="isCollapsible" class="collapse-toggle">
					<v-icon>play_arrow</v-icon>
				</div>
				<span class="title-content">
					<slot name="title">{{ title }}</slot>
				</span>
			</div>

			<div class="actions" v-show="!isCollapsed">
				<slot name="toolbar-actions" />
			</div>
		</div>

		<div class="sz-content-card__content" v-show="!isCollapsed">
			<slot>
				<v-card-text>
					<slot name="content" />
				</v-card-text>
			</slot>
		</div>

		<v-card-actions
			class="sz-content-card__actions grey lighten-4"
			v-if="!hideActions && hasActions"
			v-show="!isCollapsed"
		>
			<slot name="actions">
				<slot name="actions-left" />
				<v-spacer />
				<slot name="actions-right" />
			</slot>
		</v-card-actions>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SzContentCard extends Vue {
	@Prop()
	title!: string;

	@Prop()
	titleExtraClass!: string;

	@Prop()
	hideActions!: boolean;

	@Prop({ default: false })
	collapsible!: boolean;

	@Prop({ default: false })
	collapsed!: boolean;

	isCollapsed = false;

	@Watch("collapsed", { immediate: true })
	onCollapsedChange() {
		this.isCollapsed = this.collapsed;
	}

	get isCollapsible() {
		return !!this.collapsible;
	}

	get hasActions() {
		return !!this.$slots.actions || !!this.$slots["actions-left"] || !!this.$slots["actions-right"];
	}

	get hasTitle() {
		return !!this.$slots.title || !!this.title;
	}

	get hasToolbar() {
		return !!this.$slots.toolbar;
	}

	get hasToolbarOrTitle() {
		return this.hasToolbar || this.hasTitle;
	}

	toggleCollapse() {
		if (!this.isCollapsible) return;
		this.isCollapsed = !this.isCollapsed;
	}
}
</script>

<style>
.content-card {
	display: flex;
	flex-direction: column;
	background: white;
	border: 1px solid var(--colors-border);
	border-radius: 10px;
	box-shadow: var(--shadow);
	overflow: hidden;

	+ .content-card {
		margin: 24px 0 0;
	}
}

.toolbar {
	display: flex;
	background: var(--colors-focus);

	> * {
		padding: 0.5rem 1rem;
	}

	.title {
		flex: 1;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		font-weight: bold;

		.collapse-toggle {
			cursor: pointer;
			transition: 0.3s ease;
			transform: rotate(90deg);
			text-decoration: none !important;

			> * {
				display: block;
			}

			i {
				color: black;
			}

			&:hover i {
				color: black;
			}
		}
	}

	.actions {
		display: flex;
		gap: 0.4rem;
		align-items: center;
	}
}

.collapsible {
	.toolbar {
		.title {
			cursor: pointer;

			&:hover {
				.title-content {
					text-decoration: underline;
				}
			}
		}
	}
}

.collapsed {
	.toolbar {
		.title {
			.collapse-toggle {
				transform: rotate(0deg);
			}
		}
	}
}
</style>
