<template>
	<div class="sz-clipboard-button">
		<v-tooltip top>
			<template v-slot:activator="{ on }">
				<v-btn icon small ref="button" v-on="on">
					<v-icon>file_copy</v-icon>
				</v-btn>
			</template>

			<span>{{ _getText("tooltipText") }}</span>
		</v-tooltip>

		<v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="2000" bottom left>
			{{ snackbarText }}
		</v-snackbar>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ClipboardJS from "clipboard";

@Component
export default class SzClipboardButton extends Vue {
	@Prop()
	target!: HTMLInputElement;

	@Prop()
	tooltipText!: string;

	@Prop()
	successText!: string;

	@Prop()
	errorText!: string;

	snackbar = false;
	snackbarColor = "";
	snackbarText = "";

	mounted() {
		const button = this.$refs.button;

		const clipboard = new ClipboardJS((button as any).$el, {
			target: () => this.target,
		});

		clipboard.on("success", () => {
			this.snackbarText = this._getText("successText");
			this.snackbarColor = "info";
			this.snackbar = true;
		});

		clipboard.on("error", () => {
			this.snackbarText = this._getText("errorText");
			this.snackbarColor = "error";
			this.snackbar = true;
		});
	}

	_getText(which: "successText" | "errorText" | "tooltipText") {
		return this[which] || (this.$t("comp.clipboardButton." + which) as string);
	}
}
</script>
