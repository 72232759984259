<template>
	<div class="client-entity-details-templates">
		<templates-editor :entity="entity" />

		<sz-card-form
			style="margin-top: 2rem"
			:title="$t(`comp.templatesEditor.bannerSettings.title`)"
			:error-message="$t(`comp.templatesEditor.bannerSettings.errorMessage`)"
			:success-message="$t(`comp.templatesEditor.bannerSettings.successMessage`)"
			:collapsible="true"
			:collapsed="true"
			:data="entity"
			:submit="submit"
			:submit-text="$t(`generic.actions.update`)"
			:fields="[
				{
					key: 'config.bannerFallbackText',
					type: 'text-editor',
					label: $t('comp.templatesEditor.bannerSettings.fallbackTextLabel'),
				},
			]"
		/>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import TemplatesEditor from "@/components/TemplatesEditor";

@Component<ClientEntityDetailsTemplates>({
	title: (vm) => ["generic.templates", vm.entity.name],
	components: { TemplatesEditor },
})
export default class ClientEntityDetailsTemplates extends Vue {
	@Prop({ required: true })
	id!: string;

	get entity() {
		return this.$store.getters.getEntity(this.id);
	}

	async submit(data: any) {
		return this.$store.dispatch("updateEntity", data);
	}
}
</script>
