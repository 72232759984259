<template>
	<div class="s-entity-list">
		<s-entity-list-item
			v-for="entity of sortedEntities"
			:key="entity.id"
			:entity="entity"
			:enable-drag="isDraggable(entity)"
			:enable-drop="isDroppable(entity)"
			:enable-interaction="enableInteraction"
			@dragging="onItemDragging"
			@dropped="onItemDropped"
		>
			<div class="s-entity-list__children" v-if="$scrinz.entityCanHaveChildren(entity)">
				<s-entity-list
					v-if="hasChildren(entity)"
					:entity="entity"
					:droppable="isDroppable(entity)"
					:enable-interaction="enableInteraction"
					:parent="entity.id"
					:sortBy="sortBy"
					:sortDirection="sortDirection"
					@dragging="onChildDragging"
				/>

				<div class="s-entity-list__children__empty" v-else>{{ $t("Group empty") }}</div>
			</div>
		</s-entity-list-item>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import { sortEntitiesByNaturalName, sortEntitiesByType } from "@scrinz/helpers";

import store from "@/store";
import SEntityListItem from "./ListItem.vue";

@Component({
	name: "s-entity-list",
	components: { SEntityListItem },
})
export default class SEntityList extends Vue {
	@Prop()
	entity!: EntityInterface;

	@Prop({ default: false, type: [Boolean, Number] })
	droppable!: boolean | number;

	@Prop({ type: [String, Number] })
	parent!: number;

	@Prop({ default: true })
	enableInteraction!: boolean;

	@Prop({ default: "name" })
	sortBy!: string;

	@Prop({ default: "ASC" })
	sortDirection!: string;

	dragging: boolean | number = false;
	dragHover: boolean | number = false;

	get entities() {
		if (!this.entity) {
			return [store.getters.entityContext];
		}

		return store.getters.getChildrenOfEntity(this.entity);
	}

	get sortedEntities() {
		let entities: EntityInterface[] = this.entities;

		switch (this.sortBy) {
			case "name":
				entities = sortEntitiesByNaturalName(entities);
				break;
			case "type":
				entities = sortEntitiesByType(entities);
				break;
		}

		return this.sortDirection.toUpperCase() === "DESC" ? entities.reverse() : entities;
	}

	hasChildren(entity: EntityInterface) {
		return store.getters.getChildrenOfEntity(entity).length > 0;
	}

	isDragOver(entity: EntityInterface) {
		return this.dragHover === entity.id;
	}

	isDraggable(_entity: EntityInterface) {
		return this.$can("admin") && this.enableInteraction && !!this.parent;
	}

	isDroppable(entity: EntityInterface) {
		return (
			this.$can("admin") && this.enableInteraction && this.dragging !== entity.id && (this.droppable || this.dragging)
		);
	}

	onChildDragging(value: number) {
		this.dragging = value;
		this.$emit("dragging", value);
	}

	onItemDragging(value: number) {
		this.dragging = value;
		this.$emit("dragging", value);
	}

	onItemDropped(_value: EntityInterface) {
		// console.log("dropped", this.entities[0].name, value);
	}
}
</script>

<style lang="scss">
.s-entity-list {
	border-radius: 4px;

	&__children {
		position: relative;
		border-top: 1px solid rgba(0, 0, 0, 0.12);
		padding-left: 18px;
		margin: 0;

		&__empty {
			color: grey;
			padding: 10px 0;
		}

		&.hover {
			background: lightcoral;
		}

		&.drop-hint {
			&:after {
				position: absolute;
				display: block;
				content: "";
				top: 0;
				bottom: 0;
				left: 0;
				width: 45px;
				background: red;
			}
		}
	}

	&.drop-over {
		&:after {
			background: blue;
		}
	}
}
</style>
