<template>
	<layout-fluid-card class="entitites" v-if="$can('admin') && entityId">
		<template slot="title">{{ $t("route.client.entities.title") }}</template>

		<v-btn-toggle slot="toolbar-actions" v-if="$can('admin')" group borderless rounded dense mandatory>
			<v-btn color="secondary" @click="toggleSort('name')" text>
				{{ $t("generic.name") }}
				<template v-if="sortBy == 'name'">
					<v-icon class="btn-icon" v-show="sortDirection == 'ASC'" small>arrow_upward</v-icon>
					<v-icon class="btn-icon" v-show="sortDirection == 'DESC'" small>arrow_downward</v-icon>
				</template>
			</v-btn>
			<v-btn color="secondary" @click="toggleSort('type')" text>
				{{ $t("generic.type") }}
				<template v-if="sortBy == 'type'">
					<v-icon class="btn-icon" v-show="sortDirection == 'ASC'" small>arrow_upward</v-icon>
					<v-icon class="btn-icon" v-show="sortDirection == 'DESC'" small>arrow_downward</v-icon>
				</template>
			</v-btn>
		</v-btn-toggle>

		<s-entity-list v-if="entities.length > 0" :sortBy="sortBy" :sortDirection="sortDirection" />
	</layout-fluid-card>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { EntityInterface } from "@scrinz/dtos";
import LayoutFluidCard from "@/layouts/FluidCard.vue";
import SEntityList from "@/components/Entity/List.vue";

@Component({
	title: "route.client.entities.title",
	components: { SEntityList: SEntityList, LayoutFluidCard },
})
export default class ClientEntities extends Vue {
	@Getter("entityContextId")
	entityId!: number;

	@Getter entities!: EntityInterface[];

	sortBy: string = "name";
	sortDirection: string = "ASC";

	mounted() {
		if (this.entityId) this.redirectToEntityIfNotAdmin(this.entityId.toString());
	}

	@Watch("entityId")
	onEntityIdChanged() {
		this.redirectToEntityIfNotAdmin(this.entityId.toString());
	}

	redirectToEntityIfNotAdmin(id: string) {
		if (this.$can("admin")) return;
		this.$router.push({ name: "client--entity", params: { id } });
	}

	toggleSort(by: string) {
		if (by == this.sortBy) {
			if (this.sortDirection == "ASC") {
				this.sortDirection = "DESC";
			} else {
				this.sortDirection = "ASC";
			}
		}

		if (by != this.sortBy) {
			this.sortDirection = "ASC";
			this.sortBy = by;
		}
	}
}
</script>

<style lang="scss" scoped>
.s-entity-list {
	// margin: 10px 20px;
	margin: 1rem;
}

.btn-icon {
	transform: translateY(-1px);
}
</style>
