import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { SessionState } from "./types";

export const getters: GetterTree<SessionState, RootState> = {
	ability(state) {
		return state.ability;
	},

	hasSession(state) {
		return !!(state.token && state.userId);
	},

	hasTimedOut(state) {
		return state.userId && !state.token;
	},

	token(state) {
		return state.token;
	},

	userId(state) {
		return state.userId;
	},
};
