<template>
	<div class="user-menu" v-if="user">
		<v-menu left>
			<template v-slot:activator="{ on }">
				<div v-on="on" class="user-menu__chip">
					<div class="user-menu__chip__avatar">
						<vue-gravatar :email="user.email" />
					</div>
					<div class="user-menu__chip__name">
						{{ user.name ? user.name : user.email }}
					</div>
				</div>
			</template>

			<v-list>
				<v-menu v-if="organizations.length > 1" left>
					<template v-slot:activator="{ on }">
						<v-list-item @click.prevent="" v-on="on">
							<v-list-item-content>
								{{ organizationsInContext[0].name }}
							</v-list-item-content>
							<v-list-item-avatar>
								<v-icon>business</v-icon>
							</v-list-item-avatar>
						</v-list-item>
					</template>

					<v-list>
						<v-list-item v-for="org in organizations" :key="org.id" @click="setEntityContextId(org.id)">
							<v-list-item-content>{{ org.name }}</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>

				<v-divider v-if="organizations.length > 1" />

				<v-list-item to="/admin" v-if="$can('admin')">
					<v-list-item-content>{{ $t("comp.userMenu.admin") }}</v-list-item-content>
					<v-list-item-avatar>
						<v-icon>vpn_key</v-icon>
					</v-list-item-avatar>
				</v-list-item>

				<v-divider v-if="$can('admin')" />

				<v-list-item to="/settings">
					<v-list-item-content>{{ $t("comp.userMenu.settings") }}</v-list-item-content>
					<v-list-item-avatar>
						<v-icon>settings</v-icon>
					</v-list-item-avatar>
				</v-list-item>

				<v-list-item to="/auth/logout">
					<v-list-item-content>{{ $t("comp.userMenu.logout") }}</v-list-item-content>
					<v-list-item-avatar>
						<v-icon>lock_open</v-icon>
					</v-list-item-avatar>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class UserMenu extends Vue {
	@Getter user!: any;
	@Getter organizations!: any[];
	@Getter organizationsInContext!: any[];

	setEntityContextId(id: number) {
		if (id === this.organizationsInContext[0].id) return;
		void this.$store.dispatch("setEntityContext", id);
		this.$router.push(`/entities/${id}`);
	}
}
</script>

<style lang="scss">
.user-menu {
	&__chip {
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		gap: 0.8rem;
		padding: 0 1.2rem 0 0;
		background: #e0e0e0;
		border-radius: 90rem;
		overflow: hidden;
		font-size: 1.1rem;
		max-width: 100%;

		&__avatar {
			border-radius: 50%;
			overflow: hidden;

			img {
				display: block;
				width: 2.5rem;
			}
		}

		&__name {
			text-overflow: ellipsis;
		}
	}
}
</style>
