import axios from "axios";

import { API_URL } from "@/constants";

import store from "@/store";

const http = axios.create({
	baseURL: API_URL,
});

http.interceptors.response.use(
	(value) => value,
	async (error) => {
		if (error.response) {
			if (error.response.status === 401) {
				await store.dispatch("setToken", null);
			}
		} else if (error.message === "Network Error") {
			// Set connectivity to false, since we encounted network error.
			await store.dispatch("setServerConnectivity", false);

			// If endpoint being requested is not `/status` and a connectivity test
			// isn't already running.
			if (error.config.url.replace(error.config.baseURL) !== "/status" && !store.getters.isTestingConnectivity) {
				// Return a Promise of testing connectivity.
				const res = store.dispatch("testConnectivity", true);

				// Throw error if not successful.
				if (!res) {
					throw error;
				}

				// Return a resend of the request.
				return http.request(error.config);
			}
		}

		// If nothing else, throw error.
		throw error;
	},
);

export default http;
