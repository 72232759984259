import Vue from "vue";
import Vuetify from "vuetify";
import * as components from "vuetify/lib/components";
// import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify, { components });

export default new Vuetify({
	icons: {
		iconfont: "mdiSvg",
	},
	theme: {
		dark: false,
		themes: {
			light: {
				primary: {
					base: "#019939",
					darken1: "#00872e",
					darken2: "#007623",
					darken3: "#00580e",
					darken4: "#000000",
					lighten1: "#12a842",
					lighten2: "#45b55e",
					lighten3: "#6ac27a",
					lighten4: "#96d2a0",
				},
				secondary: "#826d51",
			},
			// secondary: {
			// 	base: "#475368",
			// 	darken1: "#374152",
			// 	darken2: "#252d3b",
			// 	darken3: "#000000",
			// 	darken4: "#000000",
			// 	lighten1: "#58667f",
			// 	lighten2: "#657590",
			// 	lighten3: "#7c88a1",
			// 	lighten4: "#929db3",
			// },
			// dark: {
			// 	primary: {
			// 		base: "#019939",
			// 		darken1: "#00872e",
			// 		darken2: "#007623",
			// 		darken3: "#00580e",
			// 		darken4: "#000000",
			// 		lighten1: "#12a842",
			// 		lighten2: "#45b55e",
			// 		lighten3: "#6ac27a",
			// 		lighten4: "#96d2a0",
			// 	},
			// 	secondary: "#3D3326",
			// }
		},
	},
	//theme,
	// customProperties: true,
	// iconfont: "md",
});
