import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

import { AppState } from "./types";
export * from "./types";

const state: AppState = {
	app: {
		drawer: {
			open: false,
		},
	},
};

export const AppStore: Module<AppState, RootState> = {
	actions,
	getters,
	mutations,
	state,
};

export default AppStore;
