import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

import { AssetsState } from "./types";
export * from "./types";

const state: AssetsState = {
	assets: [],
};

export const AssetsStore: Module<AssetsState, RootState> = {
	actions,
	getters,
	mutations,
	state,
};

export default AssetsStore;
