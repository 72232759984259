<template>
	<v-select
		class="context-switcher"
		solo
		light
		v-model="entityContextId"
		:items="organizations"
		item-text="name"
		item-value="id"
	/>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class ContextSwitcher extends Vue {
	@Getter organizations!: any[];

	get entityContextId() {
		return this.$store.getters.entityContextId;
	}

	set entityContextId(id) {
		void this.$store.dispatch("setEntityContext", id);
	}
}
</script>

<style lang="scss" scoped>
.context-switcher {
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	z-index: 199;
	width: 120px;

	& ::v-deep .v-input__slot {
		margin: 0;
	}

	& ::v-deep .v-text-field__details {
		display: none;
	}
}
</style>
