<template>
	<div id="debug-route">
		<h1>Debug route</h1>
	</div>
</template>

<script lang="ts">
	import { Component, Prop, Vue } from "vue-property-decorator";

	@Component
	export default class DebugRoute extends Vue {
		@Prop() someProp!: string;

		mounted() {
			console.log(this.$props);
		}
	}
</script>
