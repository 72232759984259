<template>
	<div
		class="asset-uploader"
		:style="{
			width: `${width + 2}px`,
			height: `${height + 2}px`,
		}"
	>
		<v-card class="asset-uploader--controls" v-if="asset">
			<v-card-text v-if="!isVideo()">
				<v-select v-if="pdf" v-model.number="asset.page" :label="$t(`comp.assetUploader.page`)" :items="pdfPages" />

				<v-layout row class="asset-uploader--controls--scale">
					<!-- <v-text-field
						class="prefix-auto-width"
						v-model.number="asset.scale"
						hide-details
						solo
						text
						:prefix="$t(`comp.assetUploader.scale:`)"
					/> -->

					<v-slider
						v-model.number="asset.scale"
						:label="$t(`comp.assetUploader.scale`)"
						:max="2"
						:min="0.1"
						:step="0.1"
						hide-details
					/>

					<v-text-field v-model.number="asset.scale" class="mt-0" single-line hide-details />
				</v-layout>
			</v-card-text>

			<v-card-actions>
				<v-spacer />
				<v-btn text color="error" @click="clearAsset">{{ $t("comp.assetUploader.clearAsset") }}</v-btn>
			</v-card-actions>
		</v-card>

		<div class="current" v-if="asset">
			<video v-if="isVideo()" :width="width" :height="height" muted autoplay loop crossorigin="anonymous">
				<source :src="originalImageUrl" />
			</video>

			<asset-uploader-cropper v-else ref="cropper" :id="id" :asset="asset" :width="width" :height="height">
				<img v-if="isImage()" :src="originalImageUrl" crossorigin="anonymous" />

				<asset-uploader-pdf-canvas
					v-if="isPdf()"
					:source="originalImageUrl"
					:page="asset.page"
					@loaded="pdf = $event"
				/>
			</asset-uploader-cropper>
		</div>

		<dropzone
			v-if="!asset"
			:id="id"
			:ref="id"
			:options="options"
			:include-styling="false"
			@vdropzone-file-added="onFileAdded"
			class="dropzone"
		>
			<!-- @vdropzone-sending="onSending"
			@vdropzone-success="onSuccess"
			@vdropzone-error="onError" -->
			<div class="dropzone-message">
				<div class="dz-message" data-dz-message>
					<span>{{ $t("comp.assetUploader.dropOrSelectUpload") }}</span>
				</div>
			</div>
		</dropzone>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
// import PDFJS from "pdfjs-dist";

import { AssetObject, AssetTypeEnum, EntityInterface } from "@scrinz/dtos";
import { API_URL } from "@/constants";
import store from "@/store";

import Dropzone from "vue2-dropzone";
import AssetUploaderCropper from "./Cropper.vue";
import AssetUploaderPdfCanvas from "./PdfCanvas.vue";

@Component({
	components: { AssetUploaderCropper, AssetUploaderPdfCanvas, Dropzone },
})
export default class AssetUploader extends Vue {
	id: string = `asset-uploader-${Math.random() * 1000}`; // tslint:disable-line

	@Prop({ required: true, type: [Object] })
	entity!: EntityInterface;

	@Prop({ default: 480 })
	width!: number;

	@Prop({ default: 895 })
	height!: number;

	asset: AssetObject | null = null;

	@Prop()
	value!: string;

	pdf: any /* PDFJS.Document */ | null = null;

	options = {
		headers: {
			Authorization: `Bearer ${store.getters.token}`,
		},
		autoQueue: false,
		maxFiles: 1,
		url: `${API_URL}/assets/upload`,
	};

	// tslint:disable:no-magic-numbers
	scaleOptions = [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
	// tslint:enable:no-magic-numbers

	errorMessage: string = "";

	get originalImageUrl() {
		return (this.asset && `${API_URL}/assets/${this.asset.id}/original?cache=${new Date().getTime()}`) || null;
	}

	get pdfPages() {
		return (this.pdf && Array.from({ length: this.pdf.numPages }, (_, k) => k + 1)) || [];
	}

	isImage() {
		return this.asset && this.asset.type === AssetTypeEnum.Image;
	}

	isVideo() {
		return this.asset && this.asset.type === AssetTypeEnum.Video;
	}

	isPdf() {
		return this.asset && this.asset.type === AssetTypeEnum.Pdf;
	}

	@Watch("value", { deep: true, immediate: true })
	async fetchAsset() {
		this.asset = this.value ? await this.$store.dispatch("fetchAsset", this.value) : null;
	}

	async onFileAdded(file: any) {
		const res = await this.$store.dispatch("uploadAsset", {
			file,
			entity: this.entity,
		});

		(this.$refs[this.id] as any).removeFile(file);

		this.$emit("input", res.id);
	}

	async save() {
		if (!this.asset) return;

		let cropped: Blob | null = null;

		if (this.isVideo()) {
			cropped = new Blob([`file:///${this.originalImageUrl}`], { type: this.asset.mime });
		} else {
			const cropper = this.$refs["cropper"] as any;
			if (cropper) cropped = (await cropper.getCropped()) as Blob | null;
		}

		if (cropped) {
			await this.$store.dispatch("saveCroppedAsset", {
				cropped,
				id: this.asset.id,
			});
		}

		const asset = await this.$store.dispatch("updateAsset", this.asset);

		this.$emit("input", asset.id);

		return asset;
	}

	async clearAsset() {
		this.pdf = null;
		this.$emit("input", null);
	}
}
</script>

<style lang="scss" scoped>
.prefix-auto-width {
	& ::v-deep .v-text-field__prefix {
		width: auto;
	}
}

.asset-uploader {
	border: 1px solid grey;
	display: inline-block;
	position: relative;
	overflow: hidden;

	&--controls {
		max-width: 50%;

		position: absolute;
		top: 0;
		right: 0;
		z-index: 1000;

		&--scale {
			& ::v-deep .v-text-field {
				margin: 0 0 0 10px;
				padding: 0;
				max-width: 40px;

				&__slot input {
					text-align: center;
				}
			}

			& ::v-deep .v-slider {
				min-width: 4rem;
			}

			.v-input--slider {
				// margin-top: -10px;
				margin: 0;

				& ::v-deep .v-input__slot {
					margin: 0;
				}
			}
		}
	}

	.current {
		height: 100%;
		width: 100%;

		img {
			display: block;
			max-height: none;
			max-width: none;
		}
	}

	.dropzone {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		min-height: auto;
		border: none;
		padding: 0;
		background: transparent;
	}
}
</style>
