<template>
	<div :class="classes">
		<v-icon class="sz-empty-state__icon" v-if="icon" :color="iconColor" :size="iconSize" v-text="icon" />

		<div class="sz-empty-state__body">
			<p class="sz-empty-state__label headline" v-if="label">{{ label }}</p>

			<div class="sz-empty-state__contents" v-if="hasContentsOrDescription">
				<slot>
					<p>{{ description }}</p>
				</slot>
			</div>

			<div class="sz-empty-state__actions" v-if="hasActions">
				<slot name="actions" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import Colorable from "vuetify/es5/mixins/colorable";
import Themeable from "vuetify/es5/mixins/themeable";

interface DynamicClassList {
	[className: string]: boolean;
}

@Component({
	mixins: [Colorable, Themeable],
})
export default class SzEmptyState extends Vue {
	@Prop()
	description!: string;

	@Prop()
	icon!: string;

	@Prop({ default: "secondary" })
	iconColor!: string;

	@Prop({ default: 160 })
	iconSize!: number;

	@Prop()
	label!: string;

	themeClasses!: DynamicClassList;

	get classes() {
		return {
			"sz-empty-state": true,
			...this.themeClasses,
		};
	}

	get hasActions() {
		return !!this.$slots["actions"];
	}

	get hasContents() {
		return !!this.$slots["default"];
	}

	get hasDescription() {
		return !!this.description;
	}

	get hasContentsOrDescription() {
		return this.hasContents || this.hasDescription;
	}
}
</script>

<style lang="scss">
.sz-empty-state {
	$sz: &;

	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	padding: 2rem;
	gap: 1rem;
	margin: 0 auto;

	&__icon {
		@at-root .theme--light.v-icon#{&} {
			color: rgba(0, 0, 0, 0.26);
		}
	}

	&__label {
		margin: 0;
	}

	&__contents {
		margin: 1em 0;
		font-size: 16px;
		line-height: 24px;

		p {
			margin: 0;
		}

		p + p {
			margin-top: 0.5em;
		}
	}

	&__actions {
		.v-btn {
			margin: 0;
		}
	}

	@media screen and (min-width: 560px) {
		flex-direction: row;
		gap: 2rem;
		padding: 2rem;
	}

	@media screen and (min-width: 1264px) {
		padding: 4rem;
	}
}
</style>
