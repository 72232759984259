import * as Cookie from "es-cookie";
import { Action, ActionTree } from "vuex";

import { EntityInterface } from "@scrinz/dtos";
import http from "@/http";
import { RootState } from "@/store";
import { EntitiesState } from "./types";

const ENTITY_ID_COOKIE = "entityId";

const onSessionOrConnectivityChange: Action<EntitiesState, RootState> = async ({ dispatch, getters }) => {
	if (getters.hasSession && getters.hasConnectivity) {
		await dispatch("fetchEntities");
	}
};

export const actions: ActionTree<EntitiesState, RootState> = {
	async init({ dispatch }) {
		await dispatch("setEntityContext", Cookie.get(ENTITY_ID_COOKIE));
	},

	onSessionChange: onSessionOrConnectivityChange,
	onConnectivityChange: onSessionOrConnectivityChange,

	async fetchEntities({ commit, dispatch, getters, state }) {
		const res = await http.get("/entities");

		if (res && res.status === 200) {
			commit("UPDATE_ENTITIES", res.data);
		} else {
			throw new Error(`Failed to fetch entities.`);
		}

		const entityContext = getters.entityContextId;

		if (!entityContext || !getters.getEntity(entityContext)) {
			await dispatch("setEntityContext", state.entities[0].id);
		}
	},

	async createEntity({ commit }, entity: EntityInterface) {
		const res = await http.post("/entities", entity);

		if (res && res.status === 201) {
			// Add newly created flag.
			res.data.newlyCreated = true;

			commit("UPDATE_ENTITY", res.data);
		} else {
			throw new Error(`Failed to create entity.`);
		}
	},

	async updateEntity({ commit, getters }, entity: EntityInterface) {
		const res = await http.put(`/entities/${entity.id}`, entity);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to update entity with id "${entity.id}".`);
		}

		// Add newly updated flag.
		res.data.newlyUpdated = true;

		commit("UPDATE_ENTITY", res.data);

		return getters.getEntity(entity.id);
	},

	async deleteEntity(
		{ commit, getters },
		payload: { entity: EntityInterface; withChildren?: "delete" | "move"; moveTo?: EntityInterface },
	) {
		const { entity, withChildren, moveTo } = payload;
		const res = await http.delete(`/entities/${entity.id}`, {
			data: {
				withChildren,
				moveTo,
			},
		});

		commit("UPDATE_ENTITIES", res.data);

		return getters.getEntity(entity.id);
	},

	async regenerateEntityApiKey({ commit }, entity: EntityInterface) {
		const res = await http.post(`/entities/${entity.id}/regenerate-api-key`);

		if (!res || res.status !== 201) {
			throw new Error(`Failed to regenerate API key for entity with id "${entity.id}".`);
		}

		commit("UPDATE_ENTITY", res.data);
	},

	async addEntityUserRelation(
		{ commit },
		payload: {
			entity: EntityInterface;
			members: any[];
		},
	) {
		const { entity, members } = payload;
		const res = await http.post(`/entities/${entity.id}/users`, members);

		if (!res || res.status !== 201) {
			throw new Error(`Failed to add members.`);
		}

		const relations = res.data as any[];
		for (const relation of relations) {
			commit("UPDATE_ENTITY_USER_RELATION", relation);
		}
	},

	async removeEntityUserRelation(
		{ commit },
		payload: {
			entity: EntityInterface;
			member: any;
		},
	) {
		const { entity, member } = payload;
		const res = await http.delete(`/entities/${entity.id}/users/${member.id}`);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to remove member.`);
		}

		commit("REMOVE_ENTITY_USER_RELATION", {
			entityId: entity.id,
			userId: member.id,
		});
	},

	async updateMemberPermissions(
		{ commit },
		{
			entity,
			member,
			permissions,
		}: {
			entity: EntityInterface;
			member: any;
			permissions: any;
		},
	) {
		const res = await http.put(`/entities/${entity.id}/users/${member.id}`, permissions);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to update member permissions.`);
		}

		commit("UPDATE_ENTITY_USER_RELATION", res.data);
	},

	// async fetchMembersOfEntity({}, entity: EntityInterface): Promise<EntityUserRelationObject[]> {
	// 	const res = await http.get(`/entities/${entity.id}/users`);

	// 	if (!res || res.status !== 200) {
	// 		throw new Error("Couldn't get members of entity.");
	// 	}

	// 	return res.data;
	// },

	// async fetchInheritedMembersOfEntity(
	// 	{ dispatch, getters },
	// 	entity: EntityInterface,
	// ): Promise<EntityUserRelationObject[]> {
	// 	if (!entity.parentId) return [];

	// 	const parent = await getters.getEntity(entity.parentId);
	// 	const members: EntityUserRelationObject[] = await dispatch("fetchMembersOfEntity", parent);

	// 	if (parent.parentId) {
	// 		const parentMembers = await dispatch("fetchInheritedMembersOfEntity", parent);

	// 		if (parentMembers && parentMembers instanceof Array) {
	// 			parentMembers.forEach((pm) => {
	// 				if (!members.find((m) => m.userId === pm.userId)) {
	// 					members.push(pm);
	// 				}
	// 			});
	// 		}
	// 	}

	// 	return members;
	// },

	async setEntityContext({ commit }, id: number | string | null) {
		if (typeof id === "string") {
			id = parseInt(id, 10);
		}

		if (typeof id === "number" && !isNaN(id)) {
			commit("SET_ENTITY_CONTEXT", id);
			Cookie.set(ENTITY_ID_COOKIE, `${id}`);

			return;
		}

		commit("SET_ENTITY_CONTEXT", null);
		Cookie.remove(ENTITY_ID_COOKIE);
	},
};
