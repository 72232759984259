import { MutationTree } from "vuex";

import { NotesState } from "./types";

// tslint:disable:naming-convention
export const mutations: MutationTree<NotesState> = {
	RESET_STATE(state) {
		state.notes = [];
	},

	DELETE_NOTE(state, note) {
		const idx = state.notes.findIndex((n) => n.id === note.id);

		if (idx === -1) return;

		state.notes.splice(idx, 1);
	},

	UPDATE_NOTE(state, note) {
		note.start = new Date(note.start);
		if (note.end) note.end = new Date(note.end);

		const current = state.notes.find((n) => n.id === note.id);

		if (current) {
			Object.assign(current, note);
		} else {
			state.notes.push(note);
		}
	},

	UPDATE_NOTES(state, notes: any[]) {
		notes.forEach((note) => {
			mutations.UPDATE_NOTE(state, note);
		});
	},
};
