import { MutationTree } from "vuex";

import { StatusState } from "./types";

// tslint:disable:naming-convention
export const mutations: MutationTree<StatusState> = {
	SET_API_INSTALLED_STATE(state, apiInstalledState: boolean) {
		state.isApiInstalled = apiInstalledState;
	},

	SET_ONLINE_STATE(state, onlineState: boolean) {
		state.isOnline = onlineState;
	},

	SET_IS_TESTING_CONNECTIVITY(state, testingConnectivity: boolean) {
		state.isTestingConnectivity = testingConnectivity;
	},

	SET_SERVER_CONNECTIVITY(state, serverConnectivity: boolean) {
		state.hasConnectivity = serverConnectivity;
	},
};
