<template>
	<div class="client-entity-details-notes">
		<note-board :entity="entity" />
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import NoteBoard from "@/components/NoteBoard";

@Component<ClientEntityDetailsNotes>({
	title: (vm) => ["generic.notes", vm.entity.name],
	components: { NoteBoard },
})
export default class ClientEntityDetailsNotes extends Vue {
	@Prop({ required: true })
	id!: string;

	get entity() {
		return this.$store.getters.getEntity(this.id);
	}
}
</script>
