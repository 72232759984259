<template>
	<nav class="entity-breadcrumbs">
		<ol>
			<li>~</li>

			<li v-for="parent of parents" :key="parent.id">
				<router-link :to="`/entities/${parent.id}`">
					{{ parent.name }}
				</router-link>
			</li>

			<li class="current">
				<router-link :to="`/entities/${entity.id}`">
					<v-icon>{{ $scrinz.getIconForEntityType(entity.type) }}</v-icon>
					{{ entity.name }}
				</router-link>
			</li>
		</ol>
	</nav>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import store from "@/store";

@Component
export default class EntityBreadcrumbs extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ default: false })
	isParent!: boolean;

	get parent() {
		return store.getters.getEntity(this.entity.parentId);
	}

	get parents() {
		const parents: EntityInterface[] = [];
		let current = this.entity;

		while (current.parentId) {
			current = store.getters.getEntity(current.parentId);
			if (!current) break;
			parents.unshift(current);
		}

		return parents;
	}
}
</script>

<style lang="scss" scoped>
.entity-breadcrumbs {
	display: inline;

	ol {
		display: inline;
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			margin: 0 0.2rem;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&:not(:last-child):not(:first-child):after {
				content: "/";
				margin: 0 0.2rem;
			}

			&.current {
				font-weight: bold;
				font-size: 1.4em;

				a:hover {
					text-decoration: none;
				}
			}
		}
	}

	a {
		text-decoration: none;
		color: #333;

		&:hover {
			text-decoration: underline;
		}
	}
}
</style>
