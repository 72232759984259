<template>
	<canvas class="asset-uploader-pdf-canvas" ref="canvas" />
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { getDocument, PDFDocumentProxy } from "pdfjs-dist";
import * as pdfjs from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";

declare const pdfjsWorker: any;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

@Component
export default class AssetUploaderPdfCanvas extends Vue {
	@Prop({ required: true })
	source!: string;

	@Prop({ default: 1 })
	page!: number;

	document!: PDFDocumentProxy;

	@Watch("source", { immediate: true })
	async loadPdf() {
		console.log(this.source);

		this.document = await getDocument(this.source).promise;
		await this.loadPage();
	}

	@Watch("page")
	async loadPage() {
		if (!this.document) return;

		const canvas = this.$refs["canvas"] as HTMLCanvasElement;
		const page = await this.document.getPage(this.page);
		const viewport = page.getViewport({ scale: 1 });

		canvas.height = viewport.height;
		canvas.width = viewport.width;

		const canvasContext = canvas.getContext("2d") as CanvasRenderingContext2D;

		await page.render({ canvasContext, viewport });
		this.$emit("loaded", this.document);
	}
}
</script>

<style lang="scss" scoped>
.asset-uploader-pdf-canvas {
	background: none;
	max-width: none;
}
</style>
