<template>
	<div :class="classes"></div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class DisplayOnlineStatus extends Vue {
	@Prop({ required: true })
	display!: any;

	get isRegistered() {
		return !!this.display.registrationHandle;
	}

	get isOffline() {
		return this.display.onlineStatus === 3;
	}

	get isPending() {
		return this.display.onlineStatus === 2;
	}

	get isOnline() {
		return this.display.onlineStatus === 1;
	}

	get classes() {
		return {
			"display-online-status": true,
			"display-online-status--pending": this.isPending,
			"display-online-status--online": this.isOnline,
			"display-online-status--offline": this.isOffline,
		};
	}
}
</script>

<style lang="scss">
.display-online-status {
	background: grey;
	border-radius: 4px;
	display: inline-block;
	width: 20px;
	height: 20px;

	&--offline {
		background: red;
	}

	&--online {
		background: green;
	}
}
</style>
