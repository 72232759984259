<template>
	<div class="client-entity-details-general">
		<sz-content-card :collapsible="true" :collapsed="true" :title="$t(`route.client.entityDetails.general.info.title`)">
			<template slot="toolbar-actions">
				<!-- <v-btn v-if="$scrinz.isDisplay(entity)" color="primary" :href="previewLink" target="_blank">
					{{ $t("route.client.entityDetails.general.info.previewDisplay") }}
				</v-btn> -->
			</template>

			<template slot="content">
				<dl>
					<dt>{{ $t("generic.name") }}</dt>
					<dd>
						<sz-input :value="entity.name" readonly plain v-sz-autosize v-sz-click-select />
					</dd>

					<dt>{{ $t("generic.type") }}</dt>
					<dd>
						<sz-input
							:value="$t('route.client.entities.types.' + $scrinz.getEntityTypeName(entity))"
							readonly
							plain
							v-sz-autosize
							v-sz-click-select
						/>
					</dd>

					<dt>{{ $t("generic.smsCodeWord") }}</dt>
					<dd>
						<sz-input
							:value="entity.config.codeWord"
							:placeholder="
								$t('route.client.entityDetails.general.info.noSmsCodeWord', [
									$t('route.client.entities.types.' + $scrinz.getEntityTypeName(entity).toLowerCase()),
								])
							"
							readonly
							plain
							:monospace="!!entity.config.codeWord"
							v-sz-autosize
							v-sz-click-select
						/>
					</dd>

					<dt>{{ $t("generic.smsPhoneNumber") }}</dt>
					<dd>
						<sz-input
							:value="smsNumber"
							:placeholder="
								$t('route.client.entityDetails.general.info.noSmsPhoneNumber', [
									$t($scrinz.getEntityTypeName(entity)).toLowerCase(),
								])
							"
							readonly
							plain
							:monospace="!!smsNumber"
							v-sz-autosize
							v-sz-click-select
						/>
					</dd>
				</dl>
			</template>
		</sz-content-card>

		<sz-content-card
			v-if="$can('admin') && $scrinz.isDisplay(entity)"
			:collapsible="true"
			:title="$t(`route.client.entityDetails.general.display.title`)"
		>
			<template slot="content">
				<dl>
					<dt>{{ $t("generic.displayId") }}</dt>
					<dd>
						<sz-input :value="hashid" readonly plain :monospace="true" v-sz-autosize v-sz-click-select>
							<template slot="actions" slot-scope="{ input }">
								<sz-clipboard-button :target="input.inputField" />
							</template>
						</sz-input>
					</dd>

					<dt>{{ $t("generic.apiKey") }}</dt>
					<dd>
						<sz-input
							class="apiKey"
							:value="entity.apiKey"
							:placeholder="$t('route.client.entityDetails.general.display.noApiKey')"
							readonly
							plain
							:monospace="!!entity.apiKey"
							v-sz-autosize
							v-sz-click-select
						>
							<template slot="actions" slot-scope="{ input }">
								<sz-clipboard-button :target="input.inputField" v-if="entity.apiKey" />

								<v-tooltip top>
									<template v-slot:activator="{ on }">
										<v-btn v-on="on" icon small @click="regenerateApiKey">
											<v-icon>autorenew</v-icon>
										</v-btn>
									</template>

									<span>{{ $t("route.client.entityDetails.general.display.regenerateApiKey") }}</span>
								</v-tooltip>
							</template>
						</sz-input>
					</dd>

					<dt>ID / API Key</dt>
					<dd>
						<sz-input :value="idApiKey" readonly play monospace v-sz-autosize v-sz-click-select>
							<template slot="actions" slot-scope="{ input }">
								<sz-clipboard-button :target="input.inputField" />
							</template>
						</sz-input>
					</dd>

					<dt>Auto register</dt>
					<dd>
						<sz-input monospace>
							<template slot="actions" slot-scope="{ input }">
								<v-btn small @click="attachAutoRegistrationToken(input.inputField.value)">Register</v-btn>
							</template>
						</sz-input>
					</dd>
				</dl>
			</template>
		</sz-content-card>

		<sz-card-form
			v-if="$can('admin')"
			:collapsible="true"
			:collapsed="true"
			:title="$t(`route.client.entityDetails.settings.entity.title`)"
			:error-message="$t(`route.client.entityDetails.settings.entity.errorMessage`)"
			:success-message="$t(`route.client.entityDetails.settings.entity.successMessage`)"
			:data="entity"
			:submit="submit"
			:submit-text="$t(`generic.actions.update`)"
			:fields="[
				{ key: 'name', label: $t('generic.name') },
				{ key: 'organizationIdentifier', label: $t('generic.organizationIdentifier') },
				{ key: 'location.street', label: $t('generic.geo.street') },
				{ key: 'location.zip', label: $t('generic.geo.zip') },
				{ key: 'location.city', label: $t('generic.geo.city') },
			]"
		/>

		<sz-card-form
			:collapsible="true"
			:collapsed="true"
			:title="$t(`route.client.entityDetails.settings.display.title`)"
			:error-message="$t(`route.client.entityDetails.settings.display.errorMessage`)"
			:success-message="$t(`route.client.entityDetails.settings.display.successMessage`)"
			:data="entity"
			:submit="submit"
			:submit-text="$t(`generic.actions.update`)"
			:fields="[
				{ key: 'config.displayInformation', label: $t('route.client.entityDetails.settings.display.informationText') },
				{
					$can: 'admin',
					key: 'orientation',
					type: 'select',
					label: $t('generic.orientation'),
					inputType: 'number',
					options: [
						{ value: 'horizontal', text: $t('generic.orientations.horizontal') },
						{ value: 'vertical', text: $t('generic.orientations.vertical') },
					],
				},
				{
					$can: 'admin',
					key: 'config.codeWord',
					label: $t('generic.smsCodeWord'),
				},
				{
					// $can: &quot;admin&quot;,
					key: 'config.showCodeWord',
					type: 'switch',
					label: $t('route.client.entityDetails.settings.display.showSmsCodeWord'),
				},
				{
					key: 'config.noteBoardBackgroundColor',
					type: 'color-swatches',
					label: $t('route.client.entityDetails.settings.display.noteBoardBackgroundColor'),
					palette: [['#e0d6d0'], ['#f7d4d2'], ['#eeeeee'], ['#d0e5be'], ['#daedf1']],
				},
			]"
		/>

		<transit-times-stop-selector :entity="entity" />
		<users-card :entity="entity" />
		<auto-approve-numbers-card :entity="entity" />

		<!-- <settings-features :id="id" /> -->

		<sz-content-card v-if="$can('admin')" :collapsible="true" :collapsed="true">
			<template slot="title">{{ $t("route.client.entityDetails.settings.housekeeping.title") }}</template>

			<template slot="content">
				<v-btn color="error" @click="showDeleteDialog = true">
					{{ $t("route.client.entityDetails.settings.housekeeping.deleteEntity") }}
				</v-btn>
			</template>
		</sz-content-card>

		<entity-delete-dialog v-model="showDeleteDialog" :entity="entity" />
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import http from "@/http";
import store from "@/store";
import { EntityDeleteDialog } from "@/components/Entity";
import { AutoApproveNumbersCard } from "@/components/AutoApproveNumbers";
import { UsersCard } from "@/components/Users";
import TransitTimesStopSelector from "@/components/TransitTimesStopSelector";

import SettingsFeatures from "@/routes/Client/EntityDetails/Settings/Features.vue";

import { encodeHashid } from "@scrinz/hashids";
import { DISPLAY_URL, CONTENT_SMS_NUMBER } from "@/constants";

@Component<ClientEntityDetailsSettings>({
	title: (vm) => ["route.client.entityDetails.settings.title", vm.entity.name],
	components: {
		EntityDeleteDialog,
		SettingsFeatures,
		AutoApproveNumbersCard,
		UsersCard,
		TransitTimesStopSelector,
	},
})
export default class ClientEntityDetailsSettings extends Vue {
	@Prop({ required: true })
	id!: string;

	showDeleteDialog = false;

	get entity() {
		return store.getters.getEntity(this.id);
	}

	get hashid() {
		return encodeHashid("display", this.entity.id);
	}

	get previewLink() {
		return `${DISPLAY_URL}/preview/${this.hashid}`; // tslint:disable-line
	}

	get smsNumber() {
		return CONTENT_SMS_NUMBER;
	}

	get idApiKey() {
		return `${this.hashid}:${this.entity.apiKey}`;
	}

	async regenerateApiKey() {
		if (
			!this.entity.apiKey ||
			confirm(this.$t("route.client.entityDetails.general.display.regenerateApiKeyConfirm") as string)
		) {
			await this.$store.dispatch("regenerateEntityApiKey", this.entity);
		}
	}

	async attachAutoRegistrationToken(inputValue: string) {
		try {
			await http.post("display/registration/attach", {
				displayId: this.entity.id,
				identifier: inputValue,
			});
			alert(`Display successfully attached to token.`);
		} catch (e) {
			console.error(e);
			alert(`Could not attach display to token with identifier "${inputValue}".`);
		}
	}

	async submit(data: any) {
		return this.$store.dispatch("updateEntity", data);
	}
}
</script>

<style lang="scss" scoped>
.no-api-key {
	font-style: italic;
}

dl {
	margin: 0;
	// line-height: 2.4;
	display: grid;
	grid-template: auto / 120px 1fr;
	gap: 8px;

	// dt,
	// dd {
	// 	min-height: 33px;
	// }

	dt {
		align-self: center;
		// clear: left;
		display: block;
		font-weight: 600;
		// float: left;
		min-width: 120px;
		text-align: right;

		&:after {
			content: ":";
		}
	}

	dd {
		display: flex;
		margin: 0;
	}

	.sz-input {
		font-size: 1.1em;
		min-height: 38px;
	}
}
</style>
