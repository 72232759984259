<template>
	<v-data-table hide-default-footer :headers="headers" :items="sorted" :items-per-page="-1">
		<template v-slot:item.user="{ item }">
			<v-tooltip top v-if="isApiUser(item)">
				<template v-slot:activator="{ on }">
					<span v-on="on">
						<strong>{{ item.name }} </strong>
						<em>({{ $t("comp.usersList.apiUser") }} <v-icon small>link</v-icon>)</em>
					</span>
				</template>
				{{ $t("comp.usersList.apiUserDescription") }}
			</v-tooltip>

			<v-tooltip top v-else-if="item.name">
				<template v-slot:activator="{ on }">
					<strong v-on="on">{{ item.name }}</strong>
				</template>
				{{ item.email }}
			</v-tooltip>

			<strong v-else>
				{{ item.email }}
			</strong>
			<em v-if="isApiUser(item) && getUserById(item.createdByUserId)"> ({{ $t("comp.usersList.createdByUser", [getUserById(item.createdByUserId).email]) }}) </em>

			<em v-if="isCurrentUser(item)"> ({{ $t("comp.usersList.itsYou") }}) </em>

			<em v-if="!isDirectMember(item)">
				({{ $t("generic.inheritedFrom") }}
				<router-link :to="`/entities/${inheritedFrom(item).id}/members`">{{ inheritedFrom(item).name }}</router-link
				>)
			</em>
		</template>

		<!-- <template v-slot:item.role="{ item }">
			{{ $t($scrinz.getRoleName(getRole(item))) }}
		</template> -->

		<template v-slot:item.auto-approve="{ item }">
			<v-tooltip top :disabled="isDirectMember(item)">
				<template v-slot:activator="{ on }">
					<v-checkbox
						color="primary"
						hide-details
						class="d-inline-block"
						v-model="permissions[item.id].autoApprove"
						v-on="on"
						:true-value="true"
						:false-value="false"
						off-icon="check_box_outline_blank"
						on-icon="check_box"
						:disabled="!isDirectMember(item)"
						@change="setAutoApprove($event, item)"
					/>
				</template>

				{{ $t("comp.usersList.navigateToInOrderToEdit", [inheritedFrom(item).name]) }}
			</v-tooltip>
		</template>

		<template v-slot:item.sms-notification="{ item }">
			<v-tooltip top :disabled="isDirectMember(item) && !disableSmsNotificationsCheckbox(item)">
				<template v-slot:activator="{ on }">
					<v-checkbox
						color="primary"
						hide-details
						class="d-inline-block"
						v-model="permissions[item.id].smsNotifications"
						v-on="on"
						:true-value="true"
						:false-value="false"
						off-icon="check_box_outline_blank"
						on-icon="check_box"
						:disabled="!isDirectMember(item) || disableSmsNotificationsCheckbox(item)"
						@change="setSmsNotifications($event, item)"
					/>
				</template>

				<span v-if="disableSmsNotificationsCheckbox(item)">
					{{ $t("comp.usersList.maxNumberOfSmsNotifications", [maxSmsNotifications]) }}
				</span>

				<span v-else-if="!isDirectMember(item)">
					{{ $t("comp.usersList.navigateToInOrderToEdit", [inheritedFrom(item).name]) }}
				</span>
			</v-tooltip>
		</template>

		<template v-slot:item.actions="{ item }">
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn text icon v-if="isDirectMember(item) && !isCurrentUser(item)" v-on="on" @click="remove(item)">
						<v-icon>delete</v-icon>
					</v-btn>
				</template>

				{{ $t("comp.usersList.removeMember", [entity.name]) }}
			</v-tooltip>
		</template>
	</v-data-table>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityInterface, EntityUserRelationObject } from "@scrinz/dtos";

@Component
export default class UsersList extends Vue {
	@Prop({ required: true })
	entity!: EntityInterface;

	maxSmsNotifications = 2;

	get headers() {
		return [
			{ text: this.$t("generic.user"), value: "user", align: "left", sortable: false },
			// { text: this.$t("generic.role"), value: "role", align: "center", sortable: false },
			{ text: this.$t("comp.usersList.autoApproveSms"), value: "auto-approve", align: "center", sortable: false },
			{ text: this.$t("comp.usersList.smsNotifications"), value: "sms-notification", align: "center", sortable: false },
			{ value: "actions", sortable: false },
		];
	}

	get users(): any[] {
		return this.$store.getters.usersFor(this.entity);
	}

	get sorted() {
		return this.users;
	}

	get permissions() {
		const users = [];

		for (const user of this.users) {
			const entity = this.inheritedFrom(user);

			users[user.id] = user.entityRelations.find((e: EntityUserRelationObject) => e.entityId === entity.id);
		}

		return users;
	}

	get smsNotificationsUsed() {
		let used = 0;

		for (const perm of this.permissions) {
			if (!perm) continue;
			if (perm.smsNotifications) used = used + 1;
		}

		return used;
	}

	inheritedFrom(user: any) {
		return this.isDirectMember(user) ? this.entity : this.$store.getters.getEntity(user.entityRelations[0].entityId);
	}

	isApiUser(user: any) {
		return user.type === "api";
	}

	isCurrentUser(user: any) {
		return this.$store.getters.user.id === user.id;
	}

	isDirectMember(user: any) {
		return !!user.entityRelations.find((e: EntityUserRelationObject) => e.entityId === this.entity.id);
	}

	getUserById(id: number) {
		return this.$store.getters.userById(id);
	}

	getRole(user: any) {
		return this.isDirectMember(user)
			? user.entityRelations.find((e: EntityUserRelationObject) => e.entityId === this.entity.id).role
			: user.entityRelations[0].role;
	}

	async setAutoApprove(event: Event, user: any) {
		await this.updateMemberPermissions(user, {
			autoApprove: !!event,
		});
	}

	disableSmsNotificationsCheckbox(user: any) {
		const used = this.smsNotificationsUsed;
		const max = this.maxSmsNotifications;
		const userEnabled = this.permissions[user.id].smsNotifications;

		return used >= max && !userEnabled;
	}

	async setSmsNotifications(state: boolean, user: any) {
		await this.updateMemberPermissions(user, {
			smsNotifications: state,
		});
	}

	async updateMemberPermissions(member: any, permissions: any) {
		await this.$store.dispatch("updateMemberPermissions", {
			member,
			permissions,
			entity: this.inheritedFrom(member),
		});
	}

	async remove(member: any) {
		const entity = this.inheritedFrom(member);
		const confirmed = await this.$confirm(
			this.$t(`comp.usersList.confirmDelete.text`, {
				member: member.email,
				entity: entity.name,
			}),
			{
				color: "error",
				title: this.$t(`comp.usersList.confirmDelete.title`),
			},
		);

		if (!confirmed) return;

		await this.$store.dispatch("removeEntityUserRelation", {
			entity,
			member,
		});
	}
}
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
	margin: 0;
}
</style>
