<template>
	<sz-content-card
		:collapsible="true"
		:collapsed="true"
		:title="$t(`route.client.entityDetails.members.users.title`, entity)"
		:hide-actions="isEmpty"
	>
		<users-add-dialog v-model="showAddDialog" :data="addData" :entity="entity" />
		<users-list :entity="entity" />

		<template slot="actions-right">
			<v-btn text @click="showAddDialog = true">{{ $t("route.client.entityDetails.members.users.addMember") }}</v-btn>
		</template>
	</sz-content-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import clone from "lodash/clone";

import { EntityInterface } from "@scrinz/dtos";

import UsersList from "./List.vue";
import UsersAddDialog from "./AddDialog.vue";

@Component({
	components: {
		UsersAddDialog,
		UsersList,
	},
})
export default class UsersCard extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	showAddDialog = false;
	addData: any = this.EMPTY;

	// tslint:disable-next-line
	get EMPTY(): any {
		return clone({});
	}

	get isEmpty() {
		return this.members.length === 0;
	}

	get members() {
		return this.$store.getters.usersFor(this.entity);
	}
}
</script>
