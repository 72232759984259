<template>
	<div :class="classes">
		<div class="templates-editor__slot__content__wrapper">
			<div class="templates-editor__slot__content__actions">
				<span v-if="isEditable">{{ $t("generic.actions.edit") }} <v-icon small>settings</v-icon></span>
				<span v-if="isLocked">{{ $t("generic.state.locked") }} <v-icon small>lock</v-icon></span>
				<span v-if="isInherited">
					{{ $t("generic.inheritedFrom") }}
					<router-link :to="{ name: $route.name, params: { ...$route.params, id: inheritedFrom.id } }">{{
						inheritedFrom.name
					}}</router-link>
				</span>
			</div>

			<div class="templates-editor__slot__content__preview" :class="{ 'has-asset': asset }">
				<div class="templates-editor__slot__content__preview__template">
					<div
						class="templates-editor__slot__content__preview__template__text"
						:class="template.templateId"
						v-html="template.templateText"
					></div>

					<img v-if="imageUrl" :src="imageUrl" />
					<span v-else>{{ $t("comp.templatesEditor.noAssetUploaded") }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class TemplatesEditorSlotContent extends Vue {
	@Prop({ required: true })
	template!: any;

	@Prop()
	asset!: any;

	@Prop()
	imageUrl!: string;

	@Prop()
	isHover!: boolean;

	@Prop()
	isEditable!: boolean;

	@Prop()
	isLocked!: boolean;

	@Prop()
	isInherited!: boolean;

	get classes() {
		return {
			"templates-editor__slot__content": true,
			"templates-editor__slot__content--hover": this.isHover,
		};
	}

	get inheritedFrom() {
		return this.$store.getters.getEntity(this.template.entityId);
	}
}
</script>

<style lang="scss">
.templates-editor__slot__content {
	padding: 5%;
	height: 100%;

	&__wrapper {
		overflow: hidden;
		height: 100%;
	}

	&__actions {
		text-align: right;

		span {
			opacity: 0;
			transition: 0.2s ease all;
		}
	}

	&--hover &__actions span {
		opacity: 1;
	}

	&__preview {
		margin: 1rem 0 0 auto;
		// border: 1px solid grey;
		background: white;
		// max-width: 80%;
		overflow: hidden;

		img {
			display: block;
			max-width: 100%;
		}

		p {
			font-size: 0.275em;
			line-height: 1em;
		}

		&__template {
			position: relative;

			&__text {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;

				text-align: center;
				color: white;
				padding: 20px;

				font-size: 30px;
				line-height: 35px;

				> * {
					margin: 0 0 0.5em;
				}

				h1 {
					font-size: 2em;
					line-height: 1em;
				}

				h2 {
					font-size: 1.5em;
					line-height: 1em;
				}

				ol,
				ul {
					padding: 0;
					list-style-position: inside;
				}

				&--dark {
					color: #333;
				}
			}
		}
	}
}
</style>
