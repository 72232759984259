import { MutationTree } from "vuex";

import { AppState } from "./types";

// tslint:disable:naming-convention
export const mutations: MutationTree<AppState> = {
	SET_APP_DRAWER(state, value: boolean) {
		state.app.drawer.open = value;
	},
};
