<template>
	<div class="note-board">
		<!-- <div>{{ $t(`comp.noteBoard.title`, entity) }}</div> -->
		<div>
			<v-btn color="primary" ml-2 @click="edit()">{{ $t(`comp.noteBoard.newNote`) }}</v-btn>
		</div>

		<div class="filter">
			<span
				class="filter__item"
				:class="{ 'filter__item--selected': showUnpublishedNotes }"
				@click="showUnpublishedNotes = !showUnpublishedNotes"
				>{{ $t("comp.noteBoard.filter.showUnpublished") }}</span
			>
			<span
				class="filter__item"
				:class="{ 'filter__item--selected': showActiveNotes }"
				@click="showActiveNotes = !showActiveNotes"
				>{{ $t("comp.noteBoard.filter.showActive") }}</span
			>
			<span
				class="filter__item"
				:class="{ 'filter__item--selected': showScheduledNotes }"
				@click="showScheduledNotes = !showScheduledNotes"
				>{{ $t("comp.noteBoard.filter.showScheduled") }}</span
			>
			<span
				class="filter__item"
				:class="{ 'filter__item--selected': showExpiredNotes }"
				@click="showExpiredNotes = !showExpiredNotes"
				>{{ $t("comp.noteBoard.filter.showExpired") }}</span
			>
			<span
				class="filter__item"
				:class="{ 'filter__item--selected': showInheritedNotes }"
				@click="showInheritedNotes = !showInheritedNotes"
				>{{ $t("comp.noteBoard.filter.showInherited") }}</span
			>
		</div>

		<div class="notes" v-if="sortedNotes && sortedNotes.length > 0">
			<template v-if="stickyNotes.length > 0">
				<note-board-note
					v-for="(note, index) in stickyNotes"
					:key="index"
					:entity="entity"
					:note="note"
					:index="index"
					:list-length="stickyNotes.length"
					:edit="edit"
				/>
			</template>

			<note-board-note
				v-for="(note, index) in regularNotes"
				:key="index + stickyNotes.length"
				:entity="entity"
				:note="note"
				:index="index"
				:list-length="regularNotes.length"
				:edit="edit"
			/>
		</div>

		<sz-loading-state v-else-if="loading" />

		<sz-empty-state v-else :label="$t(`comp.noteBoard.empty.label`)" icon="note">
			<p>{{ $t(`comp.noteBoard.empty.description1`) }}</p>
			<p>{{ $t(`comp.noteBoard.empty.description2`) }}</p>

			<template slot="actions">
				<v-btn color="primary" @click="edit()">{{ $t(`comp.noteBoard.empty.createFirstNote`) }}</v-btn>
			</template>
		</sz-empty-state>

		<edit-note-dialog v-model="showEditDialog" :note="editNote" />
	</div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import { Note } from "@/models/Note";

import EditNoteDialog from "./EditNoteDialog.vue";
import NoteBoardNote from "./Note.vue";

@Component({
	components: { EditNoteDialog, NoteBoardNote },
})
export default class NoteBoard extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	now = new Date();

	showInheritedNotes: boolean = true;
	showUnpublishedNotes: boolean = true;
	showActiveNotes: boolean = true;
	showScheduledNotes: boolean = true;
	showExpiredNotes: boolean = false;

	loading = true;
	showEditDialog: boolean = false;
	editNote: Note = new Note();

	get notes(): Note[] {
		return this.$store.getters.notesFor(this.entity);
	}

	get filterdNotes() {
		return this.notes.filter((note) => {
			const isInherited = note.entityId !== this.entity.id;
			if (!this.showInheritedNotes && isInherited) return false;

			const isUnpublished = note.state !== "Published";
			if (!this.showUnpublishedNotes && isUnpublished) return false;

			const isActive = note.start < this.now && (note.end > this.now || !note.end);
			if (this.showActiveNotes && isActive) return true;

			const isScheduled = note.start > this.now;
			if (this.showScheduledNotes && isScheduled) return true;

			const isExpired = note.end && note.end < this.now;
			if (this.showExpiredNotes && isExpired) return true;

			return false;
		});
	}

	get sortedNotes() {
		return this.filterdNotes.sort((a, b) => {
			const av = new Date(a.start).valueOf();
			const bv = new Date(b.start).valueOf();

			if (av > bv) return -1;
			if (av < bv) return 1;

			if (av === bv) {
				if (a.id > b.id) return -1;
				if (a.id < b.id) return 1;
			}

			return 0;
		});
	}

	get regularNotes() {
		return this.sortedNotes.filter((n) => !n.sticky);
	}

	get stickyNotes() {
		return this.sortedNotes.filter((n) => !!n.sticky);
	}

	// get noteBoardBgColor() {
	// 	return this.entity.noteBoardBackgroundColor || "#e6e6e6";
	// }

	@Watch("entity", { immediate: true })
	async onEntityChange() {
		this.loading = true;
		await this.$store.dispatch("fetchNotes", { entity: this.entity });
		this.loading = false;
	}

	edit(note?: Note) {
		const start = new Date();

		start.setHours(0, 0, 0, 0);

		this.editNote =
			note ||
			new Note({
				start,
				entityId: this.entity.id,
			});
		this.showEditDialog = true;
	}
}
</script>

<style lang="scss">
.note-board {
	.filter {
		display: flex;
		flex-wrap: wrap;
		gap: 6px;
		margin: 1.5rem 0;

		&__item {
			cursor: pointer;
			padding: 10px 20px;
			border-radius: 5px;
			background: #fff;
			color: var(--colors-focus-text);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			&:hover {
				text-decoration: underline;
			}

			&--selected {
				background: var(--colors-focus);
			}
		}
	}

	.notes {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding-bottom: 5rem;
	}
}
</style>
