<template>
	<div class="sz-tel-input" :class="inputClasses">
		<span class="sz-tel-input__country-label" @click="focus">
			<input type="text" disabled v-model="countryLabel" v-sz-autosize />
		</span>

		<input type="text" class="sz-tel-input__number-input" ref="input" v-model="tel" v-sz-autosize />
	</div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import {
	AsYouType,
	CountryCode,
	formatNumber,
	getCountryCallingCode,
	isValidNumber,
	parseNumber,
} from "libphonenumber-js";

import { DEFAULT_COUNTRY_CODE } from "@/constants";
import Input from "./Input.vue";

@Component
export default class SzTelInput extends Input {
	@Prop({ type: String })
	declare value: string | null;

	input: AsYouType = new AsYouType(DEFAULT_COUNTRY_CODE);
	country: CountryCode = DEFAULT_COUNTRY_CODE;
	tel: string = "";

	get countryLabel() {
		return `+${this.countryCallingCode} (${this.country})`;
	}

	get countryCallingCode() {
		return getCountryCallingCode(this.country);
	}

	get isValid() {
		return isValidNumber(this.tel, this.country);
	}

	@Watch("country")
	onCountryChanged() {
		this.input = new AsYouType(this.country);
	}

	@Watch("tel")
	onNumberChanged() {
		if (this.tel === "") {
			this.$emit("input", null);

			return;
		}

		const input = this.input;

		input.reset();
		input.input(this.tel);

		if (input.country && input.country !== this.country) {
			this.country = input.country;
		}

		if (this.isValid) {
			this.tel = formatNumber(this.tel, this.country, "NATIONAL");
			this.$emit("input", `00${this.countryCallingCode}${input.getNationalNumber()}`);
		} else {
			this.$emit("input", `00${this.countryCallingCode}${this.tel.replace(/\s/g, "")}`);
		}
	}

	@Watch("value", { immediate: true })
	onValueChanged() {
		if (this.value === "") this.tel = "";
		if (!this.value) return;

		let tel = this.value;

		if (tel.substr(0, 2) === "00") {
			tel = `+${tel.slice(2)}`;
		}

		const parsed = parseNumber(tel);

		if ("country" in parsed) this.country = parsed.country;
		if ("phone" in parsed) this.tel = formatNumber(parsed.phone, this.country, "NATIONAL");
	}
}
</script>

<style lang="scss">
.sz-tel-input {
	&__number-input {
		flex: 1;
	}

	&__country-label {
		input {
			color: rgba(0, 0, 0, 0.37);
			margin-right: 10px;
		}
	}
}
</style>
