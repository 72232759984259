<template>
	<sz-content-card title-extra-class="title" :title="$t(`Users`)">
		<v-data-table item-key="user.email" :headers="headers" :items="users" :items-per-page="-1" hide-default-footer>
			<template v-slot:item.email="{ item }">
				<router-link :to="{ name: 'admin--user-details', params: { userId: item.id } }">
					{{ item.email }}
				</router-link>
			</template>
		</v-data-table>
	</sz-content-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AdminUserList extends Vue {
	get headers() {
		return [
			{ text: this.$t("generic.id"), value: "id", align: "left" },
			{ text: this.$t("generic.email"), value: "email", align: "left" },
			{ text: this.$t("generic.name"), value: "name", align: "left" },
			{ text: this.$t("Phone"), value: "phoneNumber", align: "left" },
		];
	}

	get users() {
		return this.$store.getters.users;
	}
}
</script>
