<template>
	<div><slot /></div>
</template>

<script lang="ts">
	import Vue from "vue";
	import { Component } from "vue-property-decorator";

	@Component
	export default class SzChildWrapper extends Vue {}
</script>
