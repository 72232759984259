<template>
	<sz-content-card
		title-extra-class="new password"
		:title="$t(`Generate New Password`)"
	>
		<template slot="content">
			<v-text-field disabled v-model="password" placeholder="New password will show here" />
		</template>

		<template slot="actions-right">
			<v-btn color="primary" @click="generateNewPassword">New Password</v-btn>
		</template>
	</sz-content-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AdminUserDetailsNewPassword extends Vue {
	@Prop()
	userId!: number;

	password: string = "";

	async generateNewPassword() {
		const newPassword = await this.$store.dispatch("generateNewPasswordForUser", this.userId);

		this.password = newPassword;
	}
}
</script>
