<template>
	<sz-empty-state
		icon="departure_board"
		layout="horizontal"
		:label="$t(`comp.transitTimesStopSelector.empty.label`)"
		:description="$t(`comp.transitTimesStopSelector.empty.description`)"
	>
		<template slot="actions">
			<v-btn color="primary" @click="addFunction">{{ $t("comp.transitTimesStopSelector.empty.addButtonText") }}</v-btn>
		</template>
	</sz-empty-state>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AutoApproveNumbersEmptyState extends Vue {
	@Prop({ required: true, type: [Function] })
	addFunction!: any;
}
</script>
