<template>
	<v-card-text>
		<ul>
			<li v-for="stop of stops" :key="stop.id">
				{{ stop.label }}

				<v-btn icon @click="removeStop(stop)">
					<v-icon>clear</v-icon>
				</v-btn>
			</li>
		</ul>
	</v-card-text>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { TransitStop } from "@/store";

@Component
export default class TransitTimesStopSelectorSelectedStopsList extends Vue {
	@Prop({ default: [] })
	stops!: TransitStop[];

	async removeStop(stop: TransitStop) {
		return this.$store.dispatch("deleteTransitStop", stop);
	}
}
</script>
