import Vue from "vue";
import VuetifyConfirm from "vuetify-confirm";
import { TranslateResult } from "vue-i18n";

import vuetify from "./vuetify";

Vue.use(VuetifyConfirm, { vuetify });

declare module "vue/types/vue" {
	interface VuetifyConfirmOptions {
		buttonTrueText?: string | TranslateResult;
		buttonFalseText?: string | TranslateResult;
		buttonTrueColor?: string;
		buttonFalseColor?: string;
		color?: string;
		icon?: string;
		message?: string | TranslateResult;
		title?: string | TranslateResult;
		width?: number;
	}

	interface Vue {
		$confirm(
			text: string | TranslateResult,
			options?: VuetifyConfirmOptions,
		): Promise<boolean>;
	}
}
