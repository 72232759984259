<template>
	<div class="s-layout-content-nav">
		<div class="s-layout-content-nav__nav">
			<sz-content-card class="s-layout-content-nav__nav__card" title-extra-class="title">
				<!--
					<template slot="title" v-if="hasContentNavTitle && $vuetify.breakpoint.mdAndUp">
						<slot name="content-nav-title">{{ contentNavTitle }}</slot>
					</template>

					<v-toolbar class="elevation-0">
						<v-toolbar-title>
							<slot name="content-nav-title">{{ contentNavTitle }}</slot>
						</v-toolbar-title>
					</v-toolbar>
				-->

				<v-list class="content-nav-list">
					<template v-for="(item, index) of contentNavList">
						<template v-if="shouldShowItem(item)">
							<v-divider :key="index" v-if="isItemDivider(item)" />

							<v-list-item v-else :key="index" :to="item.to">
								<v-list-item-avatar v-if="item.icon || contentNavForceIconSlot">
									<v-icon v-if="item.icon">{{ item.icon }}</v-icon>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title v-text="item.label" />
								</v-list-item-content>
							</v-list-item>
						</template>
					</template>
				</v-list>
			</sz-content-card>
		</div>

		<div class="s-layout-content-nav__content">
			<slot />
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import store from "@/store";

@Component({
	components: {},
})
export default class LayoutContentNav extends Vue {
	@Prop({ default: [] })
	contentNavList!: any[];

	@Prop()
	contentNavTitle!: string;

	@Prop({ type: [Boolean] })
	contentNavForceIconSlot!: boolean;

	leftNavOpen: boolean = false;
	mainNavOpen: boolean = false;

	get classes() {
		return {
			"s-layout-content-nav": true,
			"s-layout-content-nav--desktop": this.$vuetify.breakpoint.mdAndUp,
			"s-layout-content-nav--mobile": this.$vuetify.breakpoint.smAndDown,
		};
	}

	get hasContentNavTitle() {
		return !!this.$slots["content-nav-title"] || this.contentNavTitle;
	}

	isItemDivider(item: any) {
		return item === "divider" || item.type === "divider";
	}

	shouldShowItem(item: any) {
		if (typeof item === "string") return item === "divider";
		if ("can" in item) return this.$can(item.can);
		return true;
	}
}
</script>

<style lang="scss" scoped>
.s-layout-content-nav {
	display: flex;
	flex-wrap: wrap;
	padding: 1rem;
	gap: 1rem;

	&__nav {
		&__card {
			border-radius: 4px;
			overflow: auto;
		}
	}

	&__content {
		flex: 1;
	}
}
</style>
