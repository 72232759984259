<template>
	<v-btn :class="classes"></v-btn>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class TemplatesEditorSlotButton extends Vue {
	get classes() {
		return {
			"templates-editor__slot__button": true,
			"elevation-0": true,
		};
	}
}
</script>

<style lang="scss">
.templates-editor__slot__button {
	&.theme--light.v-btn {
		background: none !important;
	}
}
</style>
