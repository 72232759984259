import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { ServicesState } from "./types";

export const state: ServicesState = {
	services: {},
	entity: null,
};

export const store: Module<ServicesState, RootState> = {
	actions,
	getters,
	mutations,
	state,
};

export default store;
