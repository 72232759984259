<template>
	<layout-centered class="error--404">
		<v-card class="red lighten-4" :min-width="600">
			<v-card-title class="headline red darken-3 white--text">{{ $t("errors.404.title") }}</v-card-title>

			<v-card-text>
				<p><br /></p>
				<p>{{ $t("errors.404.infoText") }}</p>
				<p>
					<router-link :to="{ path: '/' }">{{ $t("errors.404.goToHome") }}</router-link>
				</p>
			</v-card-text>
		</v-card>
	</layout-centered>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

import LayoutCentered from "@/layouts/Centered.vue";

@Component({
	title: () => "errors.404.title",
	components: { LayoutCentered },
})
export default class Error404 extends Vue {}
</script>
