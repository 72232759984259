import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export * from "./assets";
export * from "./auto-approve-numbers";
export * from "./contents";
export * from "./entities";
export * from "./preferences";
export * from "./transit-stops";
export * from "./status";

import AppStore from "./app";
import AssetsStore from "./assets";
import AutoApproveNumbersStore from "./auto-approve-numbers";
import ContentsStore from "./contents";
import EntitiesStore from "./entities";
import PreferencesStore from "./preferences";
import TransitStopsStore from "./transit-stops";
import StatusStore from "./status";

import Notes from "./notes";
import Services from "./service";
import Session from "./session";
import Users from "./users";

import { RootState } from "./types";

export default new Vuex.Store<RootState>({
	modules: {
		AppStore,
		AssetsStore,
		AutoApproveNumbersStore,
		ContentsStore,
		EntitiesStore,
		Notes,
		PreferencesStore,
		Services,
		Session,
		TransitStopsStore,
		StatusStore,
		Users,
	},
});

export * from "./types";
