<template>
	<div :class="classes">
		<v-card class="templates-editor__slot__popup__content">
			<ul>
				<li>
					{{ $t("generic.date.StartDate") }}: <span>{{ template.start | moment("D. MMMM @ HH:mm") }}</span>
				</li>
				<li>
					{{ $t("generic.date.EndDate") }}: <span>{{ template.end | moment("D. MMMM @ HH:mm") }}</span>
				</li>
				<li>
					{{ $t("comp.templatesEditor.minimumDuration") }}:
					<span>{{ template.duration }} {{ $t("generic.date.seconds") }}</span>
				</li>
			</ul>

			<div class="templates-editor__slot__popup__preview" :class="{ 'has-asset': asset }">
				<img v-if="asset" :src="imageUrl" />
				<span v-else>{{ $t("comp.templatesEditor.noAssetUploaded") }}</span>
			</div>
		</v-card>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class TemplatesEditorSlotPopup extends Vue {
	@Prop({ required: true })
	template!: any;

	@Prop()
	asset!: any;

	@Prop()
	imageUrl!: string;

	@Prop()
	open!: boolean;

	get classes() {
		return {
			"templates-editor__slot__popup": true,
			"templates-editor__slot__popup--closed": !this.open,
			"templates-editor__slot__popup--open": this.open,
		};
	}
}
</script>

<style lang="scss">
.templates-editor__slot__popup {
	background: white;
	margin: 0;
	max-width: 280px;
	width: 280px;
	position: absolute;
	right: 100%;
	top: 0;
	z-index: 1000;

	&--closed {
		display: none;
	}

	&--open {
		display: block;
	}

	&__content {
		padding: 10px;
	}

	&__preview {
		margin: 1rem 0 0 auto;

		img {
			display: block;
			border: 1px solid grey;
			box-sizing: border-box;
			max-width: 100%;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li span {
			border: 1px solid rgba(0, 0, 0, 0.2);
			border-radius: 2px;
			padding: 1px 4px;
			background: rgba(0, 0, 0, 0.05);
		}
	}
}
</style>
