export const MODE = import.meta.env.MODE ?? "production";
export const PROD = import.meta.env.PROD;
export const DEV = import.meta.env.DEV;

export const API_URL = import.meta.env.VITE_APP_API_URL ?? "/api";
export const DISPLAY_URL = import.meta.env.VITE_APP_DISPLAY_URL ?? "/display";

export const DEFAULT_COUNTRY_CODE = import.meta.env.VITE_APP_DEFAULT_COUNTRY_CODE;
export const CONTENT_SUPPORT_EMAIL = import.meta.env.VITE_APP_CONTENT_SUPPORT_EMAIL;
export const CONTENT_SMS_NUMBER = import.meta.env.VITE_APP_CONTENT_SMS_NUMBER;

export const ANALYTICS_ID = import.meta.env.VITE_APP_ANALYTICS_ID;
export const SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN;
