import { MutationTree } from "vuex";

import { PreferencesState } from "./types";

import i18n from "@/plugins/i18n";

// tslint:disable:naming-convention
export const mutations: MutationTree<PreferencesState> = {
	SET_LOCALE(state, locale: string) {
		state.locale = locale;
		i18n.locale = locale;
	},
};
