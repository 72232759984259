import { Ability } from "@casl/ability";
import { MutationTree } from "vuex";

import { UserObject } from "@scrinz/dtos";

import { SessionState } from "./types";

export const mutations: MutationTree<SessionState> = {
	RESET_STATE(state) {
		state.ability = null;
		state.token = null;
		state.user = null;
		state.userId = null;
	},

	SET_ABILITY(state, ability: Ability) {
		state.ability = ability;
	},

	SET_TOKEN(state, token: string | null) {
		state.token = token;
	},

	SET_USER(state, user: UserObject) {
		state.user = user;
	},

	SET_USER_ID(state, id: number | null) {
		state.userId = id;
	},
};
