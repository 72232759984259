import { PluginObject } from "vue";
import { formatNumber, parseNumber } from "libphonenumber-js";

export const SzFormatPhoneNumberFilter: PluginObject<any> = {
	install: (V) => {
		V.filter("sz-phone-number", (num: string) => {
			if (!num) return "";

			if (num.substr(0, 2) === "00") {
				num = `+${num.slice(2)}`;
			}

			const parsed = parseNumber(num);

			if ("country" in parsed && "phone" in parsed) {
				return formatNumber(parsed, "INTERNATIONAL", { fromCountry: parsed.country });
			}

			return num;
		});
	},
};

export default SzFormatPhoneNumberFilter;
