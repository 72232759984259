<template>
	<sz-card-form
		class="client-settings-password"
		:title="$t(`route.client.settings.password.title`)"
		:error-message="$t(`route.client.settings.password.errorMessage`)"
		:success-message="$t(`route.client.settings.password.successMessage`)"
		:submit="submit"
		:fields="[
			{ key: 'password', label: $t('generic.currentPassword'), type: 'password' },
			{ key: 'newPassword', label: $t('generic.newPassword'), type: 'password' },
			{ key: 'confirmNewPassword', label: $t('generic.confirmNewPassword'), type: 'password' },
		]"
	/>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ClientSettingsPassword extends Vue {
	async submit(data: any) {
		return this.$store.dispatch("changePassword", data);
	}
}
</script>
