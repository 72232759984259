<template>
	<div :class="inputClasses">
		<input
			ref="input"
			:type="type"
			:disabled="disabled"
			:readonly="readonly"
			v-model="szValue"
			:placeholder="placeholder"
		/>

		<div class="sz-input__actions" v-if="isMounted && hasActions">
			<slot name="actions" :input="self" />
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import validator from "validator";

@Component
export default class SzInput extends Vue {
	@Prop({ type: [Array, Boolean, Date, String, Object, Number] })
	value!: any;

	@Prop({ default: "string" })
	inputType!: string;

	@Prop({ default: "text" })
	type!: string;

	@Prop({ default: false })
	disabled!: boolean;

	@Prop({ default: false })
	readonly!: boolean;

	@Prop({ default: false })
	plain!: boolean;

	@Prop({ default: false })
	monospace!: boolean;

	@Prop({ default: false })
	inlineBlock!: boolean;

	@Prop({ default: "" })
	placeholder!: string;

	szValue: string | number | boolean = "";

	hasFocus = false;
	isDirty = false;
	isMounted = false;

	isSzInput = true;
	disableOutline = false;

	protected _initialValue!: any;

	get self() {
		return this;
	}

	get hasActions() {
		return !!this.$scopedSlots.actions;
	}

	get hasValue() {
		if (typeof this.szValue === "boolean") return true;
		if (typeof this.szValue === "number") return true;

		return !!this.szValue;
	}

	get isValid(): boolean | null {
		if (this.type === "email") {
			return validator.isEmail(this.szValue.toString());
		}

		return null;
	}

	get inputField() {
		const input = this.$refs["input"] as HTMLInputElement;

		return input || (this.$el && (this.$el.querySelector("input,textarea,select") as HTMLInputElement)) || undefined;
	}

	get inputClasses() {
		const classes = ["sz-input", ...this.modifierClasses];

		if (this.disableOutline) classes.push("disable-outline");

		return classes;
	}

	get modifierClasses() {
		const classes = [];

		if (this.disabled) classes.push("disabled");
		if (this.readonly) classes.push("readonly");
		if (this.plain) classes.push("plain");
		if (this.monospace) classes.push("monospace");
		if (this.inlineBlock) classes.push("inline-block");
		if (this.hasFocus) classes.push("focused");
		if (this.hasActions) classes.push("actions");
		if (this.hasValue) classes.push("has-value");
		else classes.push("no-value");
		if (!this.hasValue && this.placeholder) classes.push("placeholder");

		if (this.isDirty) classes.push("dirty");
		else classes.push("clean");

		if (this.isValid === true) classes.push("valid");
		else if (this.isValid === false) classes.push("invalid");

		return classes;
	}

	async mounted() {
		this.setupEventListeners();
		this.isMounted = true;
	}

	@Watch("type")
	@Watch("disabled")
	setupEventListeners() {
		if (this.disabled || !this.inputField) return;

		this.inputField.addEventListener("keypress", (e) => this.$emit("keypress", e));

		this.inputField.addEventListener("focus", (e) => {
			this.hasFocus = true;
			this.$emit("focus", e);
		});

		this.inputField.addEventListener("blur", (e) => {
			this.hasFocus = false;
			this.$emit("blur", e);
		});
	}

	focus() {
		if (this.inputField) this.inputField.focus();
	}

	@Watch("value", { deep: true, immediate: true })
	protected _onValueChanged() {
		let value = this.value;

		if (typeof value === "boolean" && this.inputType !== "boolean") {
			value = "";
		}

		if (this._initialValue === undefined) {
			this._initialValue = value;
		}

		this.isDirty = this._initialValue !== value;
		this.szValue = value;
	}

	@Watch("szValue", { deep: true })
	protected _onSzValueChanged() {
		let value: any = this.szValue;

		if (this.inputType === "number" && typeof value !== "number") {
			value = parseInt(value, 10);
		}

		this.$emit("input", value);
	}
}
</script>

<style lang="scss">
.sz-input {
	cursor: text;
	display: flex;
	// justify-content: center;
	align-items: center;
	padding: 2px 8px;
	border: 1px solid grey;
	transition: 0.3s ease all;

	> *:only-child {
		flex: 1;
	}

	input {
		background: none;
		border: none;
		color: inherit;
		font-size: 1em;
		font-style: inherit;
		letter-spacing: inherit;
		outline: none;
	}

	&:not(.plain) {
		border-radius: 4px;
	}

	&.disabled,
	&.readonly {
		color: rgb(84, 84, 84);
	}

	&.plain {
		border-width: 0;
	}

	&.placeholder {
		color: #9c9c9c;
		font-style: italic;
	}

	&.monospace {
		input {
			// letter-spacing: 2px;
			font-family: monospace;
		}
	}

	&.inline-block {
		display: inline-block;
	}

	&.dirty {
		&.valid {
			border-color: darkgreen;
		}

		&.invalid {
			background: rgba(139, 0, 0, 0.17);
			border-color: darkred;
		}
	}

	&.focused {
		// border-color: darkblue;

		&:not(.disable-outline):not(.readonly) {
			box-shadow: 0 0 5px rgba(81, 203, 238, 1);
		}
	}

	&__actions {
		display: flex;
		align-items: center;
		gap: 4px;
		letter-spacing: normal;
		// line-height: 1;
		// height: 100%;
		margin-left: 8px;

		.md-button {
			height: 28px;
			margin: 0;
			min-width: 28px;
			width: 28px;

			.md-icon {
				font-size: 20px !important;
			}
		}
	}
}
</style>
