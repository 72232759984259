import Vue, { PluginObject, VNode, VNodeDirective } from "vue";

const GHOST_ELEMENT_ID = "__sz_autoresize_ghost_element";
const DATASET_KEY = "szAutosize";

const COPY_STYLES_PROPERTIES = [
	"border-left", "border-right", "box-sizing",
	"font-family", "font-feature-settings", "font-kerning", "font-size", "font-stretch",
	"font-style", "font-variant", "font-variant-caps", "font-variant-ligatures", "font-variant-numeric",
	"font-weight", "letter-spacing", "line-height", "padding-left", "padding-right", "text-indent", "text-transform",
];

function copyStyles(from: HTMLElement, to: HTMLElement, styles: string[]) {
	const fromStyles = window.getComputedStyle(from);

	for (const style of styles) {
		const split = style.split("-");
		const camelCase = [split.shift()].concat(split.map(s => s[0].toUpperCase() + s.slice(1))).join("");

		to.style[camelCase as any] = fromStyles[camelCase as any];
	}
}

function getGhostElement() {
	return document.getElementById(GHOST_ELEMENT_ID) || document.createElement("div");
}

function updateGhostElement(from: HTMLElement, value: string) {
	const ghostElement = getGhostElement();

	ghostElement.id = GHOST_ELEMENT_ID;
	ghostElement.style.display = "block";
	ghostElement.style.position = "absolute";
	ghostElement.style.top = "0px";
	ghostElement.style.left = "0px";
	ghostElement.style.visibility = "hidden";
	ghostElement.style.whiteSpace = "pre";
	ghostElement.innerText = value;

	document.body.appendChild(ghostElement);

	copyStyles(from, ghostElement, COPY_STYLES_PROPERTIES);

	return ghostElement;
}

function hideGhostElement() {
	const ghostElement = getGhostElement();

	ghostElement.style.display = "none";
}

function getInputWith(el: HTMLElement, value: string) {
	const ghostElement = updateGhostElement(el, value);
	const width = window.getComputedStyle(ghostElement).width;

	hideGhostElement();

	return width;
}

function autosizeElement(el: HTMLElement) {
	const value = (el as HTMLInputElement).value || (el as HTMLInputElement).placeholder;
	const width = getInputWith(el, value);

	el.style.width = width;
}

function pickElement(el: HTMLElement, vnode: VNode) {
	if (el.classList.contains("sz-input")) {
		const instance = vnode.componentInstance && vnode.componentInstance as Vue;

		el = instance && (instance as any).inputField;
	}

	return el;
}

const eventHandlers: { [id: string]: () => any } = {};
const transitionListeners: { [id: string]: () => any } = {};
let uid = 0;

export const SzAutosizeDirective: PluginObject<any> = {
	install: V => {
		V.directive("sz-autosize", {
			bind: (el: HTMLElement, _binding: VNodeDirective, vnode: VNode) => {
				const id = `${uid += 1}`;
				const handler = () => {
					autosizeElement(pickElement(el, vnode));
				};

				el.dataset[DATASET_KEY] = id;
				eventHandlers[id] = handler;
				el.addEventListener("input", handler);
				setTimeout(handler, 0);
			},

			componentUpdated: el => {
				const id = el.dataset[DATASET_KEY];
				const handler = eventHandlers[id as any];

				if (!id || !handler) return;

				const oldListener = transitionListeners[id];

				if (oldListener) {
					el.removeEventListener("transitionend", oldListener);
				}

				const listener = () => {
					setTimeout(eventHandlers[id], 0);
				};

				transitionListeners[id] = listener;
				el.addEventListener("transitionend", listener);
				setTimeout(eventHandlers[id], 0);
			},

			unbind: el => {
				const id = el.dataset[DATASET_KEY];

				if (!id || !eventHandlers[id]) return;

				el.removeEventListener("input", eventHandlers[id]);
			},
		});
	},
};

export default SzAutosizeDirective;
