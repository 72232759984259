import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { NotesState } from "./types";
import { EntityInterface } from "@scrinz/dtos";

// tslint:disable:no-shadowed-variable
export const getters: GetterTree<NotesState, RootState> = {
	notes(state) {
		return state.notes;
	},

	noteById(state) {
		return (id: number) => {
			return state.notes.find((note) => note.id === id);
		};
	},

	notesFor(state, getters) {
		return (entity: EntityInterface) => {
			const ids = getters.getEntityChainIds(entity) as number[];

			return state.notes.filter((note) => ids.includes(note.entityId));
		};
	},
};
