<template>
	<h1>
		<router-link :to="`/entities/${entity.id}`">
			<v-icon class="icon">{{ $scrinz.getIconForEntityType(entity.type) }}</v-icon>

			<span class="label">{{ entity.name }}</span>

			<span class="settings" @click.prevent="navigateToSettings">
				<v-icon class="settings__icon">settings</v-icon>
			</span>
		</router-link>
	</h1>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";

import store from "@/store";

@Component({
	name: "s-entity-sidebar-organization-header",
	components: {},
})
export default class SEntitySidebarListItem extends Vue {
	// @Prop({ required: true, type: [EntityInterface, Object] })
	// entity!: EntityInterface;

	get entity() {
		return store.getters.entityContext;
	}

	navigateToSettings() {
		this.$router.push({ name: "client--entity--settings", params: { id: this.entity.id.toString() } });
	}
}
</script>

<style lang="scss" scoped>
h1 {
	margin: 0 0 0.5rem;

	a {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		color: black;
		text-decoration: none;
		padding: 0.5rem;
		min-height: 2.5rem;
		font-size: 1.4rem;
		font-weight: 500;

		.label {
			// margin-left: 0.5rem;
			line-height: 1;
			flex: 1;
		}

		> .settings {
			display: none;
			padding: 0.2rem;
			margin: -0.25rem -0.2rem;
			border-radius: 50%;

			> i {
				display: block;
			}

			&:hover {
				background: rgba(255, 255, 255, 0.3);
			}
		}

		&:hover,
		&.router-link-active {
			> .settings {
				display: inline-block;
			}
		}

		&.router-link-active {
			font-weight: bold;
			background-color: var(--colors-focus);
			color: black !important;
		}
	}
}
</style>
