<template>
	<v-toolbar class="calendar-header" flat>
		<h3 class="header-title">
			<!--
			<div>
				<span>
					{{ title.start | parseDate | moment("D. MMMM") }}
				</span>

				<span v-if="activeView == 'week' || activeView == 'month'">
					&nbsp;-&nbsp;
					{{ title.end | parseDate | moment("D. MMMM") }}
				</span>
			</div>
			-->

			<div>
				<span v-show="activeView === 'month'">
					{{ title.startOfPeriod | parseDate | moment("MMMM") | capitalize }}
				</span>

				<span v-show="activeView === 'week'">
					{{ $t("generic.date.Week") }} {{ title.startOfPeriod | parseDate | weekNum }}
				</span>

				<span> &nbsp;{{ title.startDate | parseDate | moment("YYYY") }} </span>

				<span v-if="!checkIfYearIsEqual(title.startDate, title.endDate)">
					&nbsp;-&nbsp;
					{{ title.endDate | parseDate | moment("YYYY") }}
				</span>
			</div>
		</h3>

		<v-divider vertical class="ml-4 mr-2" />

		<v-toolbar-items v-if="navigationButtonsEnabled">
			<v-btn-toggle class="transparent">
				<v-btn @click="updateNavigation('prev')" class="pa-3" active-class="" text>
					<i class="material-icons">keyboard_arrow_left</i>
				</v-btn>
				<v-btn @click="updateNavigation('today')" class="pa-3" :disabled="isNow" active-class="" text>
					{{ $t("generic.date.Today") }}
				</v-btn>
				<v-btn @click="updateNavigation('next')" class="pa-3" active-class="" text>
					<i class="material-icons">keyboard_arrow_right</i>
				</v-btn>
			</v-btn-toggle>
		</v-toolbar-items>

		<v-divider vertical class="mx-2" />

		<v-toolbar-items v-if="viewButtonsEnabled">
			<v-btn-toggle v-model="activeView" class="transparent" mandatory>
				<v-btn :value="'week'" :disabled="activeView === 'week'" class="pa-3" active-class="active-view" text>
					{{ $t("generic.date.Week") }}
				</v-btn>

				<v-btn :value="'month'" :disabled="activeView === 'month'" class="pa-3" active-class="active-view" text>
					{{ $t("generic.date.Month") }}
				</v-btn>
			</v-btn-toggle>
		</v-toolbar-items>
	</v-toolbar>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { get as getCookie } from "es-cookie";
import moment from "moment";

@Component({
	filters: {
		capitalize(str: string) {
			if (!str) return;

			return str.slice(0, 1).toUpperCase() + str.slice(1);
		},
		parseDate(date: Date | string) {
			if (typeof date === "string") return new Date(date);

			return date;
		},
		weekNum(date: Date) {
			return moment(date).isoWeek();
		},
	},
})
export default class SzCalendarHeader extends Vue {
	@Prop({ default: true })
	navigationButtonsEnabled!: boolean;

	@Prop({ default: true })
	viewButtonsEnabled!: boolean;

	@Prop()
	title!: {};

	@Prop()
	isNow!: boolean;

	activeView: string = getCookie("calendar-view") || "month";

	@Watch("activeView")
	updateView() {
		this.$emit("view", this.activeView);
	}

	updateNavigation(value: any) {
		this.$emit("navigate", value);
	}

	checkIfYearIsEqual(start: Date | string, end: Date | string) {
		if (!start || !end) return;

		if (typeof start === "string") start = new Date(start);

		if (typeof end === "string") end = new Date(end);

		if (start.getFullYear() > end.getFullYear())
			throw new Error(
				"Starting year is higher than ending year. Try re-creating the calendar event, or contact your system administrator.",
			);

		return start.getFullYear() === end.getFullYear();
	}
}
</script>

<style lang="scss" scoped>
$color-logo-green: #33b560;
$color-logo-green-90: $color-logo-green;
$color-logo-green-90: #33b560e6;
$color-logo-green-80: $color-logo-green;
$color-logo-green-80: #33b560cc;
$color-logo-green-75: $color-logo-green;
$color-logo-green-75: #33b560bf;
$color-logo-green-60: $color-logo-green;
$color-logo-green-60: #33b56099;
$color-logo-black: #231f20;
$color-logo-white: #ffffff;

.header-title {
	font-weight: 500;
	opacity: 0.7;
	flex-grow: 1;
	display: flex;
	justify-content: flex-start;
}

.active-view {
	background-color: #d2d2d2;
	color: #2b2b2b;
}
</style>
