<template>
	<div>
		<v-toolbar color="error">
			<v-btn icon @click="$router.push('/entities')">
				<v-icon x-large>arrow_left</v-icon>
			</v-btn>

			<v-toolbar-title>Superadmin</v-toolbar-title>
		</v-toolbar>

		<layout-content-nav
			:content-nav-list="[
				{ to: '/admin/displays', label: 'Displays' },
				'divider',
				{ to: '/admin/users', label: 'Users' },
				'divider',
				{ to: '/admin/master/adsLarge', label: 'Master - Advertisment - Large' },
				{ to: '/admin/master/adsSmall', label: 'Master - Advertisment - Small' },
				{ to: '/admin/master/template', label: 'Master - Template' },
			]"
		>
			<template><router-view /></template>
		</layout-content-nav>
	</div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import LayoutContentNav from "@/layouts/ContentNav.vue";

@Component({
	components: { LayoutContentNav },
})
export default class Admin extends Vue {}
</script>
