<template>
	<div>
		<calendar-header :title="headerTitle" :isNow="isNow" @navigate="navigate" @view="changeView" />

		<div class="calendar" ref="calendar" />

		<template-preview
			v-show="previewEvent"
			ref="preview"
			:event="previewEvent"
			:position="previewPosition"
			:mobile="previewMobile"
			@click="onPreviewClick"
			@mouseenter="onPreviewMouseEnter"
			@mouseleave="onPreviewMouseLeave"
		/>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { throttle } from "lodash";
import { get as getCookie, remove as removeCookie, set as setCookie } from "es-cookie";

import { Calendar as FullCalendar, OptionsInput as FullCalendarOptions } from "@fullcalendar/core";
import FullCalendarDayGridPlugin from "@fullcalendar/daygrid";
import FullCalendarInteractionPlugin from "@fullcalendar/interaction";
import FullCalendarTimeGridPlugin from "@fullcalendar/timegrid";

import { CALENDAR_OPTIONS } from "./consts";
import {
	CalendarNavigateMap,
	CalendarNavigateType,
	CalendarViewMap,
	CalendarViewType,
	DateSelectHandlerInfo,
	EventClickHandlerInfo,
	EventDropHandlerInfo,
	EventMouseEnterHandlerInfo,
	EventMouseLeaveHandlerInfo,
	EventRenderHandlerInfo,
} from "./interfaces";

import CalendarHeader from "./Header.vue";
import TemplatePreview from "./TemplatePreview.vue";

const viewCookieId: string = "calendar-view";

function stripHtmlTags(str: string) {
	// Replace any HTML tags with empty string.
	return str.replace(/<[^>]+>/g, "");
}

function decodeHtmlEntity(str: string) {
	return str.replace(/&#(\d+);/g, (_match: any, dec: any) => {
		return String.fromCharCode(dec);
	});
}

function sanitizeHtmlString(html: string) {
	if (!html) return;

	const strippedHtml = stripHtmlTags(html);
	const decodedHtml = decodeHtmlEntity(strippedHtml);

	return addSpacesAfterStops(decodedHtml);
}

function addSpacesAfterStops(str: string) {
	return str.replace(/(\.)*(\.{1})/g, ". ");
}

@Component({
	components: {
		CalendarHeader,
		TemplatePreview,
	},
})
export default class SzCalendar extends Vue {
	@Prop()
	events!: any[];

	calendar!: FullCalendar;

	headerTitle: { [name: string]: string } = {};
	isNow: boolean = true;

	previewEvent: any | false = false;
	previewPosition?: { top: number; left: number } = { top: 0, left: 0 };
	previewMobile: boolean = false;

	private _removePreviewTimeout?: NodeJS.Timeout;
	private _eventMouseMoveHandler?: any;

	get calendarOptions(): FullCalendarOptions {
		return {
			...CALENDAR_OPTIONS,
			plugins: [FullCalendarDayGridPlugin, FullCalendarInteractionPlugin, FullCalendarTimeGridPlugin],
			datesRender: this.onDatesRender,
			select: this.onDateSelect,
			eventRender: this.onEventRender,
			eventMouseEnter: this.onEventMouseEnter,
			eventMouseLeave: this.onEventMouseLeave,
			eventClick: this.onEventClick,
			eventDrop: this.onEventDrop,
		};
	}

	/**
	 * Lifecycle hook for setting up the calendar once mounted.
	 */
	mounted() {
		if (!this.$refs.calendar) return;

		this._updatePreviewPositionThrottled = throttle(this._updatePreviewPosition, 50);

		this.calendar = new FullCalendar(this.$refs.calendar as HTMLElement, this.calendarOptions);

		this.calendar.render();

		const viewCookie = getCookie(viewCookieId);
		if (viewCookie === "month" || viewCookie === "week") this.changeView(viewCookie);

		this.updateCalendar();
	}

	@Watch("events", { deep: true, immediate: true })
	/**
	 * Handles changes to `events`.
	 */
	async onEventsChange() {
		this.updateCalendar();
	}

	/**
	 * Navigates the calendar based on `type`.
	 */
	navigate(type: CalendarNavigateType) {
		const target = CalendarNavigateMap[type];

		if (!target) return;

		this.calendar[target]();
		this._checkIfNow();
	}

	/**
	 * Changes the view to render for calendar.
	 */
	changeView(view: CalendarViewType) {
		const mappedView = CalendarViewMap[view];

		if (!mappedView) return;

		this.calendar.changeView(mappedView);

		removeCookie(viewCookieId);
		setCookie(viewCookieId, view);
	}

	/**
	 * Updates the calendar, re-rendering events.
	 */
	updateCalendar() {
		if (!this.calendar) return;

		this.addEvents();
	}

	/**
	 * Adds events to calendar, removing all existing events first.
	 */
	addEvents() {
		this.calendar.removeAllEvents();

		this.events.forEach((event) =>
			this.calendar.addEvent({
				...event,
				title: (event && sanitizeHtmlString(event.templateText)) || "",
				color: event && event.backgroundColor,
				textColor: event && event.textColor,
				extendedProps: { event },
			}),
		);
	}

	/**
	 * Handles `dates render` events on calendar.
	 */
	onDatesRender(event: any) {
		this.headerTitle = {
			startDate: event.view.activeStart,
			endDate: event.view.activeEnd,
			startOfPeriod: event.view.currentStart,
		};
	}

	/**
	 * Handles `date select` events on calendar.
	 */
	onDateSelect({ start, end }: DateSelectHandlerInfo) {
		if (start.getDate() + 1 == end.getDate()) {
			end = new Date(end.setDate(end.getDate() - 1));
		}

		this.$emit("edit", { start, end });
	}

	/**
	 * Handles `click` events on calendar event.
	 */
	onEventClick({ event }: EventClickHandlerInfo) {
		const { extendedProps } = event;

		if (!extendedProps.event) return;

		this.$emit("edit", extendedProps.event);
	}

	/**
	 * Handles `drop` events on calendar event.
	 */
	onEventDrop({ event }: EventDropHandlerInfo) {
		if (!event) return;

		const newEvent = {
			...event.extendedProps.event,
			start: event.start,
			end: event.end,
		};

		this.$emit("edit", newEvent);
		this.updateCalendar();
	}

	/**
	 * Handles `render` events on calendar event.
	 */
	onEventRender({ el }: EventRenderHandlerInfo) {
		if (!el) return;
		// if (isMirror) return;

		if (el.style) {
			el.style.borderWidth = "3px";
		}
	}

	/**
	 * Handles `mouseenter` events on calendar event.
	 */
	async onEventMouseEnter({
		el,
		jsEvent,
		event: {
			extendedProps: { event },
		},
	}: EventMouseEnterHandlerInfo) {
		if (!event) return;

		// Cancel any pending preview removals.
		this._cancelRemovePreview();

		// Update position.
		await this._updatePreviewPosition(jsEvent);

		// Attach event listener to `mousemove`.
		this._addOnEventMouseMoveListener(el);

		// Don't update if preview is open and the id's are matching.
		if (this.previewEvent && this.previewEvent.id === event.id) return;

		// Update preview with new contents.
		this.previewEvent = event;
	}

	/**
	 * Handles `mouseleave` events on calendar event.
	 */
	async onEventMouseLeave({ el }: EventMouseLeaveHandlerInfo) {
		this._setRemovePreview();
		await this._removeOnEventMouseMoveListener(el);
	}

	/**
	 * Handles `mousemove` events on calendar event.
	 */
	async onEventMouseMove(e: MouseEvent) {
		await this._updatePreviewPositionThrottled(e);
	}

	/**
	 * Handles `click` events on preview element.
	 */
	onPreviewClick() {
		if (!this.previewEvent) return;

		// Workaround to make sure the preview disappears before the editor is rendered.
		const event = this.previewEvent;
		this.previewEvent = false;

		this.$emit("edit", event);
	}

	/**
	 * Handles `mouseenter` events on preview element.
	 */
	onPreviewMouseEnter() {
		this._cancelRemovePreview();
	}

	/**
	 * Handles `mouseleave` events on preview element.
	 */
	onPreviewMouseLeave() {
		this._setRemovePreview();
	}

	/**
	 * Checkes and sets `isNow` depending on if current range is now.
	 */
	private _checkIfNow() {
		if (!this.calendar) return;

		const now: Date = new Date();
		const start: Date | null = this.calendar.state.dateProfile && this.calendar.state.dateProfile.currentRange.start;
		const end: Date | null = this.calendar.state.dateProfile && this.calendar.state.dateProfile.currentRange.end;

		if (!start || !end) return;

		if (now > start && now < end) {
			this.isNow = true;
		} else {
			this.isNow = false;
		}
	}

	/**
	 * Adds an event listener to `el` pushing event info to `onEventMouseMove`,
	 * for detecting changes to preview position.
	 */
	private _addOnEventMouseMoveListener(el: HTMLElement) {
		// Remove any existing event listener.
		this._removeOnEventMouseMoveListener(el);

		// Add new event listner to `mousemove` events on `el`.
		this._eventMouseMoveHandler = el.addEventListener("mousemove", this.onEventMouseMove);
	}

	/**
	 * Removes `mousemove` event listener attached to `el` referencing
	 * `onEventMouseMove` handler.
	 */
	private _removeOnEventMouseMoveListener(el: HTMLElement) {
		// Ensure handler is defined.
		if (this._eventMouseMoveHandler) {
			// Remove the event listener from `el`.
			el.removeEventListener("mousemove", this.onEventMouseMove);

			// Unset the handler.
			this._eventMouseMoveHandler = undefined;
		}
	}

	/**
	 * Updates the preview position according to mouse position from MouseEvent.
	 * Checks preview position in relation to window size to make sure it
	 * never goes outside of the window.
	 */
	private async _updatePreviewPosition(e: MouseEvent) {
		await this.$nextTick();

		let sizeY: number;
		let sizeX: number;

		if (this.previewMobile) {
			sizeY = 580;
			sizeX = 305;
		} else {
			sizeY = 480;
			sizeX = 540;
		}

		const posY = e.clientY;
		const posX = e.clientX;
		const innerY = (e.view as any).innerHeight;
		const innerX = (e.view as any).innerWidth;

		this.previewPosition = {
			top: innerY - posY < sizeY ? innerY - sizeY : posY,
			left: innerX - posX < sizeX ? innerX - sizeX : posX,
		};
	}

	private _updatePreviewPositionThrottled!: (e: MouseEvent) => Promise<void> | undefined;

	/**
	 * Sets preview to be removed once timeout is reached, unless
	 * `_cancelRemovePreview` has been called in while waiting for timeout.
	 */
	private _setRemovePreview() {
		// Cancel previously pending preview removals.
		this._cancelRemovePreview();

		if (!this.previewEvent) return;

		this._removePreviewTimeout = setTimeout(() => {
			this.previewEvent = false;
		}, 50);
	}

	/**
	 * Cancels removal of preview by clearing the timeout set by
	 * `_setRemovePreview`, resulting in the preview not being removed.
	 */
	private _cancelRemovePreview() {
		// Ensure a timeout has been set.
		if (this._removePreviewTimeout) {
			// Clear the timeout.
			clearTimeout(this._removePreviewTimeout);

			// Unset the timeout reference-
			this._removePreviewTimeout = undefined;
		}
	}
}
</script>

<style lang="scss">
@import "@fullcalendar/core/main.css";
@import "@fullcalendar/daygrid/main.css";
@import "@fullcalendar/timegrid/main.css";

.fc-view > table {
	left: -1px;
}

.fc table {
	width: calc(100% + 2.5px);
}

.calendar {
	position: relative;

	.fc-widget-header {
		margin-right: none !important;
		border-right-width: none !important;
	}

	.fc-day-grid-container {
		overflow: hidden !important;
	}
}
</style>
