import { NavigationGuard } from "vue-router";

import { routeRequiresInstall } from "@/helpers";
import store from "@/store";

export const IsInstalledGuard: NavigationGuard = (to, _from, next) => {
	// Ensure API is installed.
	if (store.getters.isApiInstalled) {
		next();
	}

	// Redirect to `global--error-404` if route is `client--error-404`.
	else if (to.name === "client--error-404") {
		next({ name: "global--error-404", params: { 0: to.path } });
	}

	// Return next if current route don't require install.
	else if (!routeRequiresInstall(to)) {
		next();
	}

	// Else return a redirect to install.
	else {
		next({ name: "install" });
	}
};

export default IsInstalledGuard;
