import moment from "moment-timezone";
import Vue from "vue";
import VueMoment from "vue-moment";

moment.tz.setDefault("Europe/Oslo");

import "moment/dist/locale/nb";

moment.updateLocale("en", {
	relativeTime: {
		future: "in %s",
		past: "%s ago",
		s: "less than an hour",
		ss: "less than an hour",
		m: "less than an hour",
		mm: "less than an hour",
		h: "an hour",
		hh: "%d hours",
		d: "a day",
		dd: "%d days",
	},
});

moment.updateLocale("nb", {
	relativeTime: {
		future: "om %s",
		past: "%s siden",
		s: "under en time",
		ss: "under en time",
		m: "under en time",
		mm: "under en time",
		h: "en time",
		hh: "%d timer",
		d: "en dag",
		dd: "%d dager",
		M: "en måned",
		MM: "%d måneder",
		y: "ett år",
		yy: "%d år",
	},
});

// tslint:disable:no-magic-numbers
moment.relativeTimeThreshold("d", 31);

Vue.use(VueMoment, {
	moment,
});
