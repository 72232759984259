<template>
	<layout-centered id="installation" :class="$route.name">
		<v-form @submit="submit">
			<v-card>
				<v-toolbar dark class="elevation-0">
					<v-toolbar-title>Installation</v-toolbar-title>
				</v-toolbar>

				<v-card-text>
					The API server (<code>{{ apiUrl }}</code
					>) has not been configured yet. Complete the form below to set it up.
				</v-card-text>

				<v-card-text>
					<v-text-field v-model="adminEmail" label="Admin email" required />

					<v-text-field v-model="adminPassword" label="Admin password" type="password" required />

					<v-text-field v-model="rootEntity" label="Root entity" required />
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn text type="submit" @click="submit">Install</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</layout-centered>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";

import { API_URL } from "@/constants";

import LayoutCentered from "@/layouts/Centered.vue";

@Component({
	components: { LayoutCentered },
})
export default class Installation extends Vue {
	adminEmail = "";
	adminPassword = "";
	rootEntity = "";

	get apiUrl() {
		return API_URL;
	}

	async submit(e: Event) {
		e.preventDefault();

		await this.$store.dispatch("installApi", {
			adminEmail: this.adminEmail,
			adminPassword: this.adminPassword,
			rootEntity: this.rootEntity,
		});
	}
}
</script>
