<template>
	<v-container fill-height class="layout-centered">
		<v-layout column align-center>
			<slot />
		</v-layout>
	</v-container>
</template>

<script lang="ts">
	import Vue from "vue";
	import { Component } from "vue-property-decorator";

	@Component
	export default class LayoutCentered extends Vue {}
</script>
