import { ActionTree } from "vuex";

import { RootState } from "@/store";
import { AppState } from "./types";

export const actions: ActionTree<AppState, RootState> = {
	setAppDrawer({ commit }, value: boolean) {
		commit("SET_APP_DRAWER", value);
	},
};
