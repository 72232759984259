<template>
	<v-dialog v-model="open" content-class="edit-template-dialog" persistent :max-width="1200">
		<sz-content-card
			v-if="item"
			:title="$t(open && item.id ? `comp.templatesEditor.editTemplate` : `comp.templatesEditor.newTemplate`)"
		>
			<template v-slot:content>
				<v-layout class="edit-template-dialog__body" row style="min-height: 100px">
					<v-flex xs4 pa-3>
						<sz-date-picker-input
							v-model="item.start"
							:label="$t('generic.date.StartDate')"
							prepend-icon="calendar_today"
							:min-date="minDate"
							:max-date="maxDate"
						/>
					</v-flex>

					<v-flex xs4 pa-3>
						<sz-date-picker-input
							v-model="item.end"
							:label="$t('generic.date.EndDate')"
							prepend-icon="date_range"
							:min-date="item.start"
							:max-date="maxDate"
						/>
					</v-flex>

					<v-flex xs4 pa-3>
						<v-text-field v-model="item.duration" :label="$t('comp.templatesEditor.minimumDurationInSeconds')" />
					</v-flex>
				</v-layout>

				<v-layout row v-if="isUntyped">
					<v-flex xs12 pa-3>
						<v-tabs v-model="templatePickerSelectedTab">
							<v-tabs-slider color="primary" />

							<v-tab key="empty">{{ $t("comp.templatesEditor.templatePicker.startWithEmpty") }}</v-tab>
							<v-tab key="organization">{{ $t("comp.templatesEditor.templatePicker.organizationTemplates") }}</v-tab>
							<!-- <v-tab key="common">{{
								$t("comp.templatesEditor.templatePicker.commonTemplates")
							}}</v-tab> -->
						</v-tabs>
					</v-flex>
				</v-layout>

				<v-layout row v-if="isUntyped">
					<v-flex xs12 pa-3>
						<v-tabs-items v-model="templatePickerSelectedTab">
							<v-tab-item key="empty">
								<v-layout class="edit-template-dialog__body" row>
									<v-flex xs12 pa-3>
										<template-category-selector :templates="templates" @change="onTemplateCategoryChange" />
									</v-flex>
								</v-layout>

								<div class="type-selector">
									<div @click="setType('AssetBanner')" class="type-selector__item type-selector__upload">
										<v-icon>cloud_upload</v-icon>
										<span>{{ $t("comp.templatesEditor.uploadImageOrDocument") }}</span>
									</div>

									<div class="type-selector__item type-selector__or">
										{{ $t("comp.templatesEditor.orChooseTemplate") }}
									</div>

									<div
										class="type-selector__item type-selector__template"
										v-for="template of filteredTemplates"
										:key="template.image"
										@click="setType('TemplateBanner', template.id)"
									>
										<img :src="template.image" />
									</div>
								</div>
							</v-tab-item>

							<v-tab-item key="organization">
								<v-layout class="edit-template-dialog__body" row v-if="isUntyped">
									<v-flex xs12 pa-3>
										<sd-template-container
											class="type-selector__item type-selector__template"
											v-for="template of templateTemplates"
											:key="template.id"
											:template="template.template"
											:text="template.templateText"
											@click="setTemplate(template)"
										>
										</sd-template-container>
									</v-flex>
								</v-layout>
							</v-tab-item>

							<!-- <v-tab-item key="common">Bubba</v-tab-item> -->
						</v-tabs-items>
					</v-flex>
				</v-layout>

				<v-layout row v-else>
					<v-flex xs12 pa-3 style="overflow: auto">
						<div v-if="isAsset">
							<asset-uploader
								ref="asset-uploader"
								v-model="item.assetHashid"
								:entity="entity"
								:width="slotWidth"
								:height="slotHeight"
							/>
						</div>

						<div v-if="isTemplate">
							<v-layout row>
								<div class="template-template">
									<div
										class="template-template__text"
										:class="getTemplateTextClassById(item.template)"
										v-html="item.templateText"
									></div>

									<img :src="getTemplateById(item.templateId)?.image" />
								</div>

								<v-layout column pl-3>
									<v-flex sx12>
										<sz-text-editor v-model="item.templateText"></sz-text-editor>
									</v-flex>
								</v-layout>
							</v-layout>
						</div>

						<!-- <sz-field :label="$t('comp.templatesEditor.bannerImage')"> -->
						<!-- </sz-field> -->
					</v-flex>
				</v-layout>
			</template>

			<template slot="actions-left">
				<v-btn text color="warning" @click="open = false">{{ $t("generic.actions.cancel") }}</v-btn>
				<v-btn text color="error" v-if="item.id" @click="remove">{{ $t("generic.actions.delete") }}</v-btn>
			</template>

			<template slot="actions-right">
				<v-btn text color="primary" v-if="item.id" @click="save()">{{ $t("generic.actions.update") }}</v-btn>
				<v-btn text color="secondary" v-if="!isUntyped && !item.id" @click="saveAsTemplate()">{{
					$t("comp.templatesEditor.createAsTemplate")
				}}</v-btn>
				<v-btn text color="primary" v-if="!isUntyped && !item.id" @click="save()">{{
					$t("generic.actions.create")
				}}</v-btn>
			</template>
		</sz-content-card>
	</v-dialog>
</template>

<script lang="ts">
import clone from "lodash/clone";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { quillEditor } from "vue-quill-editor";
// import Nl2Br from "vue-nl2br";

import { SdTemplateContainer } from "@scrinz/components";
import { ContentTypeEnum, EntityInterface } from "@scrinz/dtos";
import { TEMPLATES } from "@/assets/templates";
import AssetUploader from "@/components/AssetUploader";
import http from "@/http";

import TemplateCategorySelector from "./TemplateCategorySelector.vue";

@Component({
	components: {
		AssetUploader,
		quillEditor,
		// nl2br: Nl2Br,
		TemplateCategorySelector,
		SdTemplateContainer,
	},
})
export default class EditTemplateDialog extends Vue {
	templates = TEMPLATES;

	@Prop({ default: false })
	value!: boolean;

	@Prop({ required: true, type: [Object] })
	template!: any;

	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ required: true })
	slotId!: string;

	@Prop()
	slotWidth!: number;

	@Prop()
	slotHeight!: number;

	item: any | null = null;
	type: ContentTypeEnum = ContentTypeEnum.Banner;

	selectedTemplateCategory: string | null = null;
	templatePickerSelectedTab: number | null = null;

	minDate = new Date();
	maxDate = new Date(new Date().setFullYear(this.minDate.getFullYear() + 1).valueOf());

	get filteredTemplates() {
		if (!this.templates) return [];
		if (this.selectedTemplateCategory === null) return this.templates;

		return this.templates.filter((t: any) => t.category === this.selectedTemplateCategory);
	}

	get isUntyped() {
		return this.type === ContentTypeEnum.Banner;
	}

	get isAsset() {
		return this.type === ContentTypeEnum.AssetBanner;
	}

	get isTemplate() {
		return this.type === ContentTypeEnum.TemplateBanner || this.type === ("TemplateBanner" as any);
	}

	get open() {
		return this.value;
	}

	set open(value: boolean) {
		this.$emit("input", value);
	}

	@Watch("template", { deep: true, immediate: true })
	onNoteChange() {
		this.item = clone(this.template);
		this.type = this.item.type || ContentTypeEnum.Banner;
	}

	onTemplateCategoryChange(category: null | string) {
		this.selectedTemplateCategory = category;
	}

	getTemplateById(id: string) {
		return this.templates.find((t) => t.id === id);
	}

	getTemplateTextClassById(id: string) {
		const template = this.getTemplateById(id);

		if (!template) return {};

		return {
			"template-template__text--dark": template.fontColor === "dark",
			"template-template__text--light": template.fontColor === "light" || !template.fontColor,
		};
	}

	async setType(type: ContentTypeEnum, templateId?: string) {
		this.type = type;
		this.item.type = type;
		this.item.templateId = templateId;
	}

	setTemplate(t: any) {
		const { templateId, templateText, type } = t;
		this.item = { ...this.template, templateId, templateText, type };
		this.type = type;
	}

	async save() {
		if (this.item.type === ContentTypeEnum.AssetBanner) {
			const assetUploader = this.$refs["asset-uploader"] as any;

			await assetUploader.save();
		}

		// FIXME: This is a hack to make sure the item is published.
		this.item.state = "Published";

		await this.$store.dispatch(this.item.id ? "updateContent" : "createContent", {
			entity: this.entity,
			slot: this.slotId,
			content: this.item,
		});

		this.open = false;
		this.$emit("update");
	}

	async saveAsTemplate() {
		this.item.isTemplate = true;

		await this.save();
		await this.updateTemplateTemplates();
	}

	async remove() {
		const confirmed = await this.$confirm(this.$t(`comp.templatesEditor.confirmDelete.text`), {
			color: "error",
			title: this.$t(`comp.templatesEditor.confirmDelete.title`),
		});

		if (!confirmed) return;

		await this.$store.dispatch("deleteContent", {
			entity: this.entity,
			slot: this.slotId,
			content: this.item,
		});

		this.open = false;
		this.$emit("update");
	}

	/**
	 * This needs to be reworked
	 * =========================
	 */

	templateTemplates: any[] = [];

	@Watch("entity", { immediate: true })
	async updateTemplateTemplates() {
		try {
			const res = await http.get(`/content/${this.entity.id}/template?isTemplate=true`);

			if (res && res.data && res.data instanceof Array) {
				this.templateTemplates = res.data;
			}
		} catch (e) {}
	}
}
</script>

<style lang="scss">
.edit-template-dialog {
	display: flex;
	flex-direction: column;
	height: 100%;

	.sz-content-card {
		display: flex;
		flex-direction: column;
		height: 100%;

		&__toolbar {
			max-height: 64px;
		}

		&__content {
			flex: 1;
			overflow: auto;
		}
	}

	&__body {
		overflow: auto;
	}
}

.type-selector {
	&__item {
		width: 158px;
		height: 289px;
		border: 3px solid transparent;

		float: left;
		margin: 0 20px 20px 0;

		img {
			display: block;
			max-width: 100%;
			max-width: 100%;
			margin: auto;
		}

		.sd-template-container__text {
			font-size: 30px * 0.329166667;
			line-height: 35px * 0.329166667;
		}
	}

	&__upload,
	&__template {
		&:hover {
			cursor: pointer;
			border: 3px dashed grey;
		}
	}

	&__upload,
	&__or {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		i.v-icon {
			font-size: 4rem;
		}
	}

	&__or {
		font-style: italic;
	}
}

.template-template {
	position: relative;

	&__text {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;

		text-align: center;
		color: white;
		padding: 20px;

		font-size: 30px;
		line-height: 35px;

		> * {
			margin: 0 0 0.5em;
		}

		h1 {
			font-size: 2em;
			line-height: 1em;
		}

		h2 {
			font-size: 1.5em;
			line-height: 1em;
		}

		ol,
		ul {
			padding: 0;
			list-style-position: inside;
		}

		&--dark {
			color: #333;
		}
	}
}
</style>
