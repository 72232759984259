<template>
	<div class="swatches" :data-pick="pick">
		<div class="swatches__group" v-for="(group, $idx) in palette" :key="$idx">
			<div
				class="swatches__color"
				v-for="c in group" :key="c"
				:data-color="c"
				:style="{ background: c }"
				@click="handleClick(c)"
			>
				<div class="swatches__check-mark" v-if="equal(c)">
					<svg style="width: 24px; height: 24px;" viewBox="0 0 24 24">
						<path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { TinyColor } from "@ctrl/tinycolor";

const defaultColors = [
	["#d3d3d3"],
	["#fd5f5c", "#e1a9a8"],
	["#354a77", "#6f8da7"],
	["#3d6938", "#91ba74"],
	["#1f96ac", "#5ec0c9"],
];

@Component
export default class Swatches extends Vue {
	@Prop({ default: () => defaultColors, type: Array })
	palette!: string[][];

	@Prop()
	value!: string;

	get color() {
		return this.value;
	}

	get pick() {
		return this.color;
	}

	equal(color: string): boolean {
		if (!color || !this.color) return false;

		return color.toLowerCase() === this.color.toLowerCase();
	}

	handleClick(color: any) {
		this.colorChange(color);
	}

	colorChange(color: any) {
		color = new TinyColor(color);
		color.setAlpha(1);

		if (!color.isValid) return;

		this.$emit("input", color.toHexString().toUpperCase());
	}
}
</script>

<style lang="scss">
.swatches {
	background-color: #fff;
	overflow: hidden;
	display: flex;
	padding: 6px;

	&__group {
		width: 40px;
	}

	&__group + &__group {
		margin-left: 5px;
	}

	&__color {
		box-sizing: border-box;
		border-radius: 2px;
		height: 24px;
		width: 100%;
		cursor: pointer;
		background: transparent;
	}

	&__color + &__color {
		margin-top: 4px;
	}

	&__check-mark {
		fill: rgb(255, 255, 255);
		margin-left: 8px;
		display: block;
	}
}
</style>
