<template>
	<div class="templates-editor">
		<!-- <div>:title="$t(`comp.templatesEditor.bannersFor`, entity)"</div> -->

		<div class="actions">
			<v-btn color="primary" @click="edit()">{{ $t("comp.templatesEditor.newTemplate") }}</v-btn>

			<v-btn-toggle v-model="view" mandatory>
				<v-btn text value="swimlanes" :disabled="view === 'swimlanes'" active-class="">
					<v-icon>view_week</v-icon>
				</v-btn>
				<v-btn text value="calendar" :disabled="view === 'calendar'" active-class="">
					<v-icon>calendar_today</v-icon>
				</v-btn>
			</v-btn-toggle>
		</div>

		<div v-if="loading">
			<sz-loading-state />
		</div>

		<div v-if="!loading && view === 'swimlanes'" class="template-editor__content--swimlanes">
			<swimlane-view v-if="upcomingEvents.length > 0" :entity="entity" :events="upcomingEvents" @edit="edit" />

			<!--
				<swimlane-view
					v-else-if="upcomingEvents.length === 0 && events.length > 0"
					:entity="entity"
					:events="upcomingEvents"
					@edit="edit"
				/>
				-->

			<sz-empty-state v-else :label="$t(`comp.templatesEditor.noBanners`)" icon="collections">
				<p>{{ $t(`comp.templatesEditor.addBannersToShowOnDisplay`) }}</p>

				<template slot="actions">
					<v-btn color="primary" @click="edit()">{{ $t("comp.templatesEditor.createBanner") }}</v-btn>
				</template>
			</sz-empty-state>
		</div>

		<div v-if="!loading && view === 'calendar'" class="template-editor__content--calendar">
			<calendar v-if="events.length > 0" :events="events" @edit="edit" />

			<sz-empty-state v-else :label="$t(`comp.templatesEditor.noBanners`)" icon="collections">
				<p>{{ $t(`comp.templatesEditor.addBannersToShowOnDisplay`) }}</p>

				<template slot="actions">
					<v-btn color="primary" @click="edit()">{{ $t("comp.templatesEditor.createBanner") }}</v-btn>
				</template>
			</sz-empty-state>
		</div>

		<edit-template-dialog
			v-model="showEditDialog"
			:template="editTemplate"
			:entity="entity"
			:slotId="slotId"
			:slotWidth="slotWidth"
			:slotHeight="slotHeight"
			@update="update"
		/>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import clone from "lodash/clone";
import { get as getCookie, remove as removeCookie, set as setCookie } from "es-cookie";

import { EntityInterface } from "@scrinz/dtos";
// import Calendar from "@/components/Calendar";
import Calendar from "@/components/Calendar";
import EditTemplateDialog from "./EditTemplateDialog.vue";
import SwimlaneView from "./SwimlaneView.vue";
// import TemplatesEditorSwimlane from "./Swimlane.vue";

import { Getter } from "vuex-class";

const DEFAULT_TEMPLATE = {
	asset: null,
	assetHashid: null,
	start: new Date(),
	end: new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0, 0)).valueOf() + 1000 * 60 * 60 * 24 * 14), // tslint:disable-line
	duration: 30,
};

@Component({
	components: {
		Calendar,
		EditTemplateDialog,
		SwimlaneView,
		// TemplatesEditorSwimlane,
	},
})
export default class TemplatesEditor extends Vue {
	@Getter contentsFor!: (entity: EntityInterface, slot: string) => any[];

	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ default: "template" })
	slotId!: string;

	@Prop()
	slotWidth!: number;

	@Prop()
	slotHeight!: number;

	// view: "swimlanes" | "calendar" = "swimlanes";
	view: string = getCookie("templates-view") || "swimlanes";

	now = new Date();
	events: any[] = [];

	loading = true;
	showEditDialog: boolean = false;
	editTemplate: any = clone(DEFAULT_TEMPLATE);

	get upcomingEvents() {
		return this.events.filter((event) => {
			if (typeof event.isTemplate === "boolean" && event.isTemplate) {
				return false;
			}
			return event.end.valueOf() >= this.now.valueOf();
		});
	}

	get rowHeight() {
		return 30; // tslint:disable-line
	}

	@Watch("entity", { immediate: true })
	@Watch("slotId")
	async fetch() {
		this.loading = true;
		await this.$store.dispatch("fetchContents", { entity: this.entity, slot: this.slotId });
		this.loading = false;
		this.update();
	}

	@Watch("view")
	updateViewCookie() {
		removeCookie("templates-view");
		setCookie("templates-view", this.view);
	}

	edit(template: any = {}) {
		// (this.$refs["edit-template-dialog"] as any).showDialog(template || clone(DEFAULT_TEMPLATE));

		this.editTemplate = {
			...clone(DEFAULT_TEMPLATE),
			...template,
		};

		this.showEditDialog = true;
	}

	update() {
		this.events = this.contentsFor(this.entity, this.slotId);
	}
}
</script>

<style lang="scss" scoped>
.actions {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.75rem;
}
.templates-editor {
	// &__toolbar-actions {
	// 	display: flex;
	// 	gap: 0.4rem;
	// 	align-items: center;
	// }
}
</style>
