import { ActionTree } from "vuex";

import http from "@/http";
import { RootState } from "@/store";
import { ContentState } from "./types";
import { EntityInterface } from "@scrinz/dtos";

export const actions: ActionTree<ContentState, RootState> = {
	async fetchContents({ commit }, { entity, slot }: { entity: EntityInterface; slot: string }) {
		const res = await http.get(`entities/${entity.id}/content/${slot}`);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to load content "${slot}" for entity "${entity.id}".`);
		}

		commit("UPDATE_CONTENTS", { slot, contents: res.data });
	},

	async createContent({ commit }, { entity, slot, content }: { entity: EntityInterface; slot: string; content: any }) {
		// if (!content.owner) {
		// 	content.owner = getters.user.id;
		// }

		const res = await http.post(`entities/${entity.id}/content/${slot}`, content);

		if (!res || res.status !== 201) {
			throw new Error(`Failed to create content "${slot}".`);
		}

		// Add newly created flag.
		res.data.newlyCreated = true;

		commit("UPDATE_CONTENT", { slot, content: res.data });
	},

	async updateContent({ commit }, { entity, slot, content }: { entity: EntityInterface; slot: string; content: any }) {
		const res = await http.put(`entities/${entity.id}/content/${slot}/${content.id}`, content);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to update content "${slot}" with id "${content.id}".`);
		}

		// Add newly updated flag.
		res.data.newlyUpdated = true;

		commit("UPDATE_CONTENT", { slot, content: res.data });
	},

	async deleteContent({ commit }, { entity, slot, content }: { entity: EntityInterface; slot: string; content: any }) {
		const res = await http.delete(`entities/${entity.id}/content/${slot}/${content.id}`);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to delete content "${slot}" with id "${content.id}".`);
		}

		commit("REMOVE_CONTENT", { slot, content });
	},
};
