<template>
	<div class="sz-option" @click="select" ref="option">
		<slot>
			{{ label }}
		</slot>
	</div>
</template>

<script lang="ts">
	import Vue from "vue";
	import { Component, Prop } from "vue-property-decorator";

	@Component
	export default class SzOption extends Vue {
		@Prop({ default: null, type: [Array, Boolean, Object, Number, String] })
		value!: any;

		@Prop()
		label!: string;

		@Prop({ default: false })
		unset!: boolean;

		getLabel() {
			return this.label || (this.$refs.option as any).innerText;
		}

		getValue() {
			if (this.unset) return;
			if (typeof this.value === "boolean") return this.value;

			return this.value || this.getLabel();
		}

		select() {
			this.$emit("selected", this.getValue());
		}
	}
</script>

<style lang="scss">
	.sz-option {
		cursor: pointer;
		padding: 12px 5px;

		&:not(:first-child) {
			border-top: 1px solid lightgray;
		}

		&:hover {
			background: rgba(0, 0, 0, 0.07);
		}
	}
</style>
