<template>
	<form class="authentication-form login-form" @submit.prevent="login">
		<div class="headline">{{ $t("route.authentication.login.title") }}</div>

		<v-text-field v-model="email" :label="$t('generic.email')" />

		<v-text-field v-model="password" type="password" :label="$t('generic.password')" />

		<v-alert :value="isInvalidCredentials" type="error">
			{{ $t("route.authentication.login.invalidCredentialsError") }}
		</v-alert>

		<v-layout align-center justify-space-between>
			<router-link :to="{ name: 'auth--reset-password' }" v-if="displayResetPasswordLink">
				{{ $t("route.authentication.login.forgottenPassword") }}
			</router-link>

			<v-btn text type="submit">{{ $t("route.authentication.login.submit") }}</v-btn>
		</v-layout>
	</form>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { loginRedirect } from "@/helpers";
import { CONTENT_SUPPORT_EMAIL } from "@/constants";

const enum LoginState {
	Loaded,
	Submitting,
	LoggedIn,
	InvalidCredentials,
	ErrorOccured,
}

@Component
export default class LoginForm extends Vue {
	supportEmail: string = CONTENT_SUPPORT_EMAIL;

	@Prop({ default: true })
	displayResetPasswordLink!: boolean;

	email: string = "";
	password: string = "";

	state: LoginState = LoginState.Loaded;

	get isInvalidCredentials() {
		return this.state === LoginState.InvalidCredentials;
	}

	async login(): Promise<any> {
		// Only submit once at a time.
		if (this.state === LoginState.Submitting) return null;
		this.state = LoginState.Submitting;

		try {
			const res = await this.$store.dispatch("login", {
				email: this.email,
				password: this.password,
			});

			if (!res) {
				this.state = LoginState.InvalidCredentials;

				return false;
			}

			this.state = LoginState.LoggedIn;

			await loginRedirect(this);
		} catch (err) {
			this.state = LoginState.ErrorOccured;
			alert(this.$t("errors.unknown"));
		}
	}
}
</script>
