export default abstract class BaseModel {
	[key: string]: any;

	constructor(data?: any) {
		if (data) {
			Object.keys(data).forEach(key => {
				this[key] = data[key];
			});
		}
	}
}
