<template>
	<div class="template-category-selector">
		<div class="template-category-selector__description">
			{{ $t("comp.templatesEditor.selectTemplateCategory") }}
		</div>

		<div class="template-category-selector__categories">
			<a
				v-for="category of filteredCategories"
				:key="category.key ?? 'all'"
				@click="onCategoryClick(category)"
				:class="classForCategory(category)"
				>{{ category.label }}</a
			>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

export interface TemplateCategorySelectorCategory {
	key: null | string;
	label: any;
	hasItems: boolean;
}

@Component
export default class TemplateCategorySelector extends Vue {
	@Prop()
	templates!: any[];

	categories: TemplateCategorySelectorCategory[] = [];
	selected: null | string = null;

	get filteredCategories() {
		return this.categories.filter((c) => c.hasItems);
	}

	classForCategory(category: TemplateCategorySelectorCategory) {
		return {
			"template-category-selector__category": true,
			"template-category-selector__category--selected": category.key === this.selected,
		};
	}

	@Watch("templates", { immediate: true })
	onTemplatesChange() {
		const categories = [
			{ key: null, label: this.$t("comp.templatesEditor.templatesCategories.all"), hasItems: true },
			{ key: "general", label: this.$t("comp.templatesEditor.templatesCategories.general"), hasItems: false },
			{ key: "rules", label: this.$t("comp.templatesEditor.templatesCategories.rules"), hasItems: false },
			{ key: "hse", label: this.$t("comp.templatesEditor.templatesCategories.hse"), hasItems: false },
			{ key: "seasonal", label: this.$t("comp.templatesEditor.templatesCategories.seasonal"), hasItems: false },
		];

		for (const template of this.templates) {
			let category = categories.find((c) => c.key === template.category);

			if (!category) {
				continue;
			}

			category.hasItems = true;
		}

		this.categories = categories;
	}

	onCategoryClick(category: TemplateCategorySelectorCategory) {
		this.selected = category.key;

		if (this.$listeners.change) {
			(this.$listeners.change as Function)(category.key);
		}
	}
}
</script>

<style lang="scss">
.template-category-selector {
	display: flex;

	&__description {
		margin-right: 10px;
		font-style: italic;
	}

	&__category {
		&:hover {
			text-decoration: underline;
		}

		&:not(:first-child) {
			border-left: 1px solid black;
			margin-left: 8px;
			padding-left: 8px;
		}

		&--selected {
			color: black;
			text-decoration: underline;
		}
	}
}
</style>
