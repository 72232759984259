import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { TransitStopsState } from "./types";
import { EntityInterface } from "@scrinz/dtos";

// tslint:disable:no-shadowed-variable
export const getters: GetterTree<TransitStopsState, RootState> = {
	transitStopsFor(state) {
		return (entity: EntityInterface) => {
			return state.transitStops.filter((stop) => {
				return stop.entityId === entity.id;
			});
		};
	},
};
