<template>
	<app />
	<!-- <main>
		<router-view v-if="hasConnectivity"></router-view>

		<div class="global-modals">
			<md-dialog :md-active.sync="showSessionTimedOutModal">
				<md-dialog-title>Session timed out</md-dialog-title>
				<md-dialog-content>
					<p>It seems you're no longer authenticated. This is probably because your session has timed out.</p>
					<p>Please re-authenticate.</p>

					<login-form :display-reset-password-link="false"></login-form>
				</md-dialog-content>
			</md-dialog>

			<md-dialog :md-active.sync="showConnectivityIssueModal">
				<md-dialog-title>Network connectivity issue</md-dialog-title>
				<md-dialog-content>
					<p>The application can't establish a connection to the server.</p>
					<p v-if="isTestingConnectivity">Testing connectivity. Please wait.</p>
				</md-dialog-content>
				<md-dialog-actions>
					<md-button :disabled="isTestingConnectivity" @click="retryConnection()">Retry connection</md-button>
				</md-dialog-actions>
			</md-dialog>
		</div>
	</main> -->
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import App from "./App.vue";

@Component({
	components: {
		App,
	},
})
export default class Main extends Vue {
	@Getter hasSession!: boolean;
	@Getter hasConnectivity!: boolean;
	@Getter hasTimedOut!: boolean;
	@Getter isTestingConnectivity!: boolean;

	@Action testConnectivity!: any; // TODO: Fix type.

	// Show modal if don't have serverconnectivity.
	get showConnectivityIssueModal(): boolean {
		return !this.hasConnectivity;
	}
	// Do nothing. Can't be closed before connectivity is re-established.
	set showConnectivityIssueModal(_value: boolean) {
		// TODO: Not implemented
	}

	// Show modal if session has timed out.
	get showSessionTimedOutModal(): boolean {
		return this.hasTimedOut;
	}
	// Do nothing. Can't be closed before re-authentication.
	set showSessionTimedOutModal(_value: boolean) {
		// TODO: Not implemented
	}

	// Retries the connection until one is established.
	async retryConnection() {
		await this.testConnectivity(true);
	}
}
</script>

<style lang="scss">
// Styling for global errors.
.global-error {
	height: 100%;
	position: relative;

	.md-card {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
</style>

<style lang="scss">
.sz-transition__fade-slide-up-enter-active,
.sz-transition__fade-slide-up-leave-active {
	transition: opacity 0.5s, height 0.5s, max-height 0.5s, padding 0.5s, border 0.5s;
}

.sz-transition__fade-slide-up-leave-active {
	max-height: 10000px;
}

.sz-transition__fade-slide-up-enter,
.sz-transition__fade-slide-up-leave-to {
	opacity: 0;
}

.sz-transition__fade-slide-up-leave-to {
	box-sizing: border-box;
	height: 0px;
	max-height: 0px;
	min-height: 0px;
	border-top: 0px;
	border-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	overflow: hidden;
}
</style>

<style lang="scss">
// $newly-white: rgba(255, 255, 255, 0.99);
/* Settings opacity or nothing might be better because there are less colors and styling to do, and the note
			doesn't change it's border colors to white at the start and end of transition. */
// $newly-opaque: 1;
$newly-green: rgba(134, 202, 88, 0.5);
$newly-blue: rgba(53, 53, 172, 0.5);

@keyframes newly-created {
	// 0% { background-color: $newly-white; }
	// 0% { opacity: $newly-opaque; }
	10% {
		background-color: $newly-green;
	}
	// 100% { background-color: $newly-white; }
	// 100% { opacity: $newly-opaque; }
}

.newly-created {
	animation-name: newly-created;
	animation-duration: 5s;
}

@keyframes newly-updated {
	// 0% { background-color: $newly-white; }
	// 0% { opacity: $newly-opaque; }
	10% {
		background-color: $newly-blue;
	}
	// 100% { background-color: $newly-white; }
	// 100% { opacity: $newly-opaque; }
}

.newly-updated {
	animation-name: newly-updated;
	animation-duration: 5s;
}
</style>
