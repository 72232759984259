<template>
	<div class="s-layout-topbar">
		<header class="s-layout-topbar__top primary elevation-4">
			<v-btn icon x-large @click="$router.go(-1)"><v-icon>arrow_left</v-icon></v-btn>
			<!-- <router-link class="app-logo" :to="`/entities/${entityId}`">
				<styretavla-logo primary-color="white" secondary-color="black" />
			</router-link> -->
		</header>

		<main class="s-layout-topbar__main">
			<slot />
		</main>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import store from "@/store";

import SUserMenu from "@/components/UserMenu.vue";
import StyretavlaLogo from "@/components/StyretavlaLogo.vue";

@Component({
	components: { SUserMenu, StyretavlaLogo },
})
export default class LayoutTopbar extends Vue {
	get entityId() {
		return store.getters.entityContext.id;
	}
}
</script>

<style lang="scss" scoped>
.s-layout-topbar {
	&__top {
		padding: 0.5rem;

		> button {
			color: white !important;
		}
	}

	&__main {
		padding: 1rem;
	}
}
</style>
