import { MutationTree } from "vuex";

import { UsersState } from "./types";
import { EntityInterface } from "@scrinz/dtos";

// tslint:disable:naming-convention
export const mutations: MutationTree<UsersState> = {
	RESET_STATE(state) {
		state.users = [];
	},

	ADD_USER(state, user) {
		state.users.push(user);
	},

	DELETE_USER(state, user) {
		const idx = state.users.findIndex((u) => u.id === user.id);

		if (idx === -1) return;

		state.users.splice(idx, 1);
	},

	UPDATE_USER(state, user) {
		const current = state.users.find((u) => u.id === user.id);

		if (!current) return;

		Object.assign(current, user);
	},

	UPDATE_USER_PERMS(state, { user, permissions }) {
		const currentUser = state.users.find((u) => u.id === user.id);

		if (!currentUser) return;

		const currentPerms = currentUser.entityRelations.find((e: any) => e.entityId === permissions.entityId);

		Object.assign(currentPerms, permissions);
	},

	UPDATE_USERS(state, users: any[]) {
		for (const user of users) {
			const current = state.users.find((u) => u.id === user.id);

			if (current) {
				Object.assign(current, user);
			} else {
				state.users.push(user);
			}
		}
	},

	UPDATE_ENTITY_USER_RELATION(state, relation: any) {
		const userIndex = state.users.findIndex((u) => u.id === relation.userId);
		const user = userIndex !== -1 ? state.users[userIndex] : relation.user;

		if (!user) return;
		if (!user.entityRelations) user.entityRelations = [];

		const relationIndex = user.entityRelations.findIndex((r: any) => r.id === relation.id);
		const newRelation = {
			id: relation.id,
			entityId: relation.entityId,
			userId: relation.userId,
			autoApprove: relation.autoApprove,
			smsNotifications: relation.smsNotifications,
		};

		if (relationIndex === -1) user.entityRelations.push(newRelation);
		else user.entityRelations.splice(relationIndex, 1, newRelation);

		if (userIndex === -1) state.users.push(user);
		else state.users.splice(userIndex, 1, user);
	},

	REMOVE_ENTITY_USER_RELATION(
		state,
		payload: {
			entityId: number;
			userId: number;
		},
	) {
		const user = state.users.find((u) => u.id === payload.userId);
		if (!user) return;

		const idx = (user.entityRelations ?? []).findIndex((p: any) => p.entityId === payload.entityId);
		if (idx === -1) return;

		user.entityRelations.splice(idx, 1);
	},
};
