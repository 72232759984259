import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { PreferencesState } from "./types";

export const getters: GetterTree<PreferencesState, RootState> = {
	locale(state) {
		return state.locale;
	},
};
