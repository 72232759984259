<template>
	<sz-card-form
		class="client-settings-profile"
		:title="$t(`route.client.settings.profile.title`)"
		:error-message="$t(`route.client.settings.profile.errorMessage`)"
		:success-message="$t(`route.client.settings.profile.successMessage`)"
		:data="user"
		:submit="submit"
		:fields="[
			{ key: 'name', label: $t('generic.name') },
			{ key: 'email', label: $t('generic.email'), type: 'email', icon: 'email' },
			{ key: 'phoneNumber', label: $t('generic.phoneNumber'), type: 'tel', icon: 'phone' },
		]"
	/>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ClientSettingsProfile extends Vue {
	get user() {
		return this.$store.getters.user;
	}

	async submit(data: any) {
		return this.$store.dispatch("updateUser", data);
	}
}
</script>
