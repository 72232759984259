import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

import { StatusState } from "./types";
export * from "./types";

const state: StatusState = {
	hasConnectivity: false,
	isOnline: false,
	isTestingConnectivity: false,
	isApiInstalled: true,
};

export const StatusStore: Module<StatusState, RootState> = {
	actions,
	getters,
	mutations,
	state,
};

export default StatusStore;
