<template>
	<div class="sz-select" :class="inputClasses">
		<div class="sz-select--field" @click="isOpen = !isOpen">
			<span class="sz-select--field--label">
				{{ displayValue }}
			</span>

			<span class="sz-select--field--arrow">
				<v-icon>arrow_drop_down</v-icon>
			</span>
		</div>

		<sz-dialog class="sz-select--dialog" :active.sync="isOpen" ref="dialog">
			<sz-child-wrapper class="sz-select--options" v-if="isOpen" ref="options">
				<slot v-if="isOpen">
					<sz-option
						v-for="(label, value) in options"
						:key="value"
						:value="value"
						:label="label"
						@selected="select"
						/>
				</slot>
			</sz-child-wrapper>
		</sz-dialog>

		<sz-child-wrapper v-if="!isOpen" v-show="false" ref="options">
			<slot ref="slot" />
		</sz-child-wrapper>
	</div>
</template>

<script lang="ts">
	import Vue from "vue";
	import { Component, Prop, Watch } from "vue-property-decorator";

	import Input from "./Input.vue";
	import SzChildWrapper from "../ChildWrapper.vue";
	import SzDialog from "../Dialog.vue";
	import SzOption from "./Option.vue";
	import SzOptionGroup from "./OptionGroup.vue";

	@Component({
		components: { SzChildWrapper, SzDialog, SzOption },
	})
	export default class SzSelect extends Input {
		@Prop({ default: () => ({}) })
		options!: { [key: string]: string };

		isOpen = false;
		slottedOptions: { [key: string]: string } = {};
		observer!: MutationObserver;

		get displayValue() {
			const value = this.szValue as any;

			if (value === undefined) return this.placeholder;

			return this.slottedOptions[value] || this.options[value] || value || this.placeholder;
		}

		async mounted() {
			this.observer = new MutationObserver(this.findOptions.bind(this));
			this.observer.observe((this.$refs.options as Vue).$el, {
				attributes: true,
				characterData: true,
				childList: true,
				subtree: true,
			});

			await this.findOptions();
		}

		@Watch("isOpen")
		@Watch("options", { deep: true })
		async findOptions() {
			await this.$nextTick();

			this.slottedOptions = {};
			this._findOptions((this.$refs.options as Vue));
		}

		select(value: string) {
			this.szValue = value;
			this.isOpen = false;
		}

		protected _findOptions(el: Vue) {
			if (!el || !el.$children) return;

			for (const option of el.$children) {
				if (option instanceof SzOptionGroup) {
					this._findOptions(option);
					continue;
				}

				if (!(option instanceof SzOption)) return;

				this.slottedOptions[option.value] = option.getLabel();
				option.$on("selected", () => {
					this.select(option.getValue());
				});
			}
		}
	}
</script>

<style lang="scss">
	.sz-select {
		flex-direction: column;
		position: relative;

		&--field {
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 3px 5px;

			&--arrow {
				margin: -2px -5px -2px 0;
			}
		}

		&.no-value {
			.sz-select--field {
				&--label {
					opacity: 0.2;
				}
			}
		}

		&.placeholder {
			.sz-select--field {
				&--label {
					opacity: 0.5;
					font-style: italic;
				}
			}
		}

		&--dialog {
			position: absolute;
			top: -2px;
			left: 0;
			right: 0;
			user-select: none;
		}
	}
</style>
