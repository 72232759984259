import BaseModel from "./BaseModel";

import { Color } from "./Color";

export class Note extends BaseModel {
	entityId!: number;
	id!: number;
	title!: string;
	text!: string;
	color!: Color;
	author!: string;
	start!: Date;
	end!: Date;
	icon!: string;
	iconColor!: string;
	sticky!: boolean;

	constructor(data?: any) {
		super(data);

		this.entityId = data?.entityId;
		this.start = data?.start;
	}
}
