import Vue from "vue";
import VueAnalytics from "vue-analytics";
import VueRouter from "vue-router";

import { ANALYTICS_ID, PROD } from "@/constants";

export interface AnalyticsOptions {
	router: VueRouter;
}

export default function registerAnalytics(options: AnalyticsOptions) {
	const { router } = options;

	if (!ANALYTICS_ID) {
		return;
	}

	Vue.use(VueAnalytics, {
		router,
		id: ANALYTICS_ID,

		debug: {
			enabled: !PROD,
			sendHitTask: PROD,
		},

		autoTracking: {
			screenview: true,
		},
	});
}
