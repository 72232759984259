import { Field as l, Int as a, InterfaceType as re, registerEnumType as I, ObjectType as b, ArgsType as U, InputType as f } from "@nestjs/graphql";
import { ApiProperty as c, ApiPropertyOptional as i } from "@nestjs/swagger";
import { IsString as O, IsNumber as Se, IsEnum as ht, IsBoolean as or, IsEmail as Qe, MinLength as pr, MaxLength as sr, IsInt as Gt, IsOptional as fe, IsObject as lr, IsDate as ar } from "class-validator";
var ir = Object.defineProperty, ur = Object.getOwnPropertyDescriptor, A = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? ur(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && ir(t, o, e), e;
};
let Ue = class {
};
A([
  l(() => a, { description: "The unique identifier for the object." })
], Ue.prototype, "id", 2);
Ue = A([
  re({ isAbstract: !0 })
], Ue);
let oe = class {
};
A([
  l(() => Date, { description: "Timestamp of when the object was created." })
], oe.prototype, "createdAt", 2);
A([
  l(() => Date, { description: "Timestamp oif when the object was last updated." })
], oe.prototype, "updatedAt", 2);
A([
  l(() => Date, {
    description: "Timestamp of when the object was deleted, or null if it's not deleted.",
    nullable: !0
  })
], oe.prototype, "deletedAt", 2);
oe = A([
  re({ isAbstract: !0 })
], oe);
let D = class {
};
A([
  l(() => a, { description: "The unique identifier for the object." }),
  c()
], D.prototype, "id", 2);
A([
  l(() => Date, { description: "Timestamp of when the object was created." }),
  c()
], D.prototype, "createdAt", 2);
A([
  l(() => Date, { description: "Timestamp oif when the object was last updated." }),
  c()
], D.prototype, "updatedAt", 2);
A([
  l(() => Date, {
    description: "Timestamp of when the object was deleted, or null if it's not deleted.",
    nullable: !0
  })
], D.prototype, "deletedAt", 2);
D = A([
  re({
    isAbstract: !0,
    implements: [Ue, oe]
  })
], D);
const cr = (n) => [
  ...new Set(n)
], fr = (n, t) => n.filter((o) => !t.includes(o)), yr = (n, t) => n.filter((o) => t.includes(o)), vr = (n) => typeof n == "bigint" || !Number.isNaN(Number(n)) && Math.floor(Number(n)) === n, br = (n) => typeof n == "bigint" || n >= 0 && Number.isSafeInteger(n);
function be(n, t) {
  if (t.length === 0)
    return n;
  let o;
  const r = [...n];
  for (let e = r.length - 1, p = 0, s = 0; e > 0; e--, p++) {
    p %= t.length, s += o = t[p].codePointAt(0);
    const d = (o + p + s) % e, g = r[e], _ = r[d];
    r[d] = g, r[e] = _;
  }
  return r;
}
const _r = (n, t) => {
  const o = [];
  let r = n;
  if (typeof r == "bigint") {
    const e = BigInt(t.length);
    do
      o.unshift(t[Number(r % e)]), r /= e;
    while (r > BigInt(0));
  } else
    do
      o.unshift(t[r % t.length]), r = Math.floor(r / t.length);
    while (r > 0);
  return o;
}, hr = (n, t) => n.reduce((o, r) => {
  const e = t.indexOf(r);
  if (e === -1)
    throw new Error(`The provided ID (${n.join("")}) is invalid, as it contains characters that do not exist in the alphabet (${t.join("")})`);
  if (typeof o == "bigint")
    return o * BigInt(t.length) + BigInt(e);
  const p = o * t.length + e;
  if (Number.isSafeInteger(p))
    return p;
  if (typeof BigInt == "function")
    return BigInt(o) * BigInt(t.length) + BigInt(e);
  throw new Error("Unable to decode the provided string, due to lack of support for BigInt numbers in the current environment");
}, 0), dr = /^\+?\d+$/, Or = (n) => dr.test(n) ? Number.parseInt(n, 10) : Number.NaN, gr = (n, t, o) => Array.from({ length: Math.ceil(n.length / t) }, (r, e) => o(n.slice(e * t, (e + 1) * t))), xt = (n) => new RegExp(n.map((t) => Mt(t)).sort((t, o) => o.length - t.length).join("|")), Pr = (n) => new RegExp(`^[${n.map((t) => Mt(t)).sort((t, o) => o.length - t.length).join("")}]+$`), Mt = (n) => n.replace(/[\s#$()*+,.?[\\\]^{|}-]/g, "\\$&"), Nt = 16, Ut = 3.5, mr = 12, Rt = 16, wr = 12, Ht = 100;
class it {
  constructor(t = "", o = 0, r = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", e = "cfhistuCFHISTU") {
    if (this.minLength = o, typeof o != "number")
      throw new TypeError(`Hashids: Provided 'minLength' has to be a number (is ${typeof o})`);
    if (typeof t != "string")
      throw new TypeError(`Hashids: Provided 'salt' has to be a string (is ${typeof t})`);
    if (typeof r != "string")
      throw new TypeError(`Hashids: Provided alphabet has to be a string (is ${typeof r})`);
    const p = Array.from(t), s = Array.from(r), d = Array.from(e);
    this.salt = p;
    const g = cr(s);
    if (g.length < Nt)
      throw new Error(`Hashids: alphabet must contain at least ${Nt} unique characters, provided: ${g.join("")}`);
    this.alphabet = fr(g, d);
    const _ = yr(d, g);
    this.seps = be(_, p);
    let h, w;
    (this.seps.length === 0 || this.alphabet.length / this.seps.length > Ut) && (h = Math.ceil(this.alphabet.length / Ut), h > this.seps.length && (w = h - this.seps.length, this.seps.push(...this.alphabet.slice(0, w)), this.alphabet = this.alphabet.slice(w))), this.alphabet = be(this.alphabet, p);
    const k = Math.ceil(this.alphabet.length / mr);
    this.alphabet.length < 3 ? (this.guards = this.seps.slice(0, k), this.seps = this.seps.slice(k)) : (this.guards = this.alphabet.slice(0, k), this.alphabet = this.alphabet.slice(k)), this.guardsRegExp = xt(this.guards), this.sepsRegExp = xt(this.seps), this.allowedCharsRegExp = Pr([
      ...this.alphabet,
      ...this.guards,
      ...this.seps
    ]);
  }
  encode(t, ...o) {
    const r = "";
    let e = Array.isArray(t) ? t : [...t != null ? [t] : [], ...o];
    return e.length === 0 || (e.every(vr) || (e = e.map((p) => typeof p == "bigint" || typeof p == "number" ? p : Or(String(p)))), !e.every(br)) ? r : this._encode(e).join("");
  }
  decode(t) {
    return !t || typeof t != "string" || t.length === 0 ? [] : this._decode(t);
  }
  /**
   * @description Splits a hex string into groups of 12-digit hexadecimal numbers,
   * then prefixes each with '1' and encodes the resulting array of numbers
   *
   * Encoding '00000000000f00000000000f000f' would be the equivalent of:
   * Hashids.encode([0x100000000000f, 0x100000000000f, 0x1000f])
   *
   * This means that if your environment supports BigInts,
   * you will get different (shorter) results if you provide
   * a BigInt representation of your hex and use `encode` directly, e.g.:
   * Hashids.encode(BigInt(`0x${hex}`))
   *
   * To decode such a representation back to a hex string, use the following snippet:
   * Hashids.decode(id)[0].toString(16)
   */
  encodeHex(t) {
    let o = t;
    switch (typeof o) {
      case "bigint":
        o = o.toString(Rt);
        break;
      case "string":
        if (!/^[\dA-Fa-f]+$/.test(o))
          return "";
        break;
      default:
        throw new Error(`Hashids: The provided value is neither a string, nor a BigInt (got: ${typeof o})`);
    }
    const r = gr(o, wr, (e) => Number.parseInt(`1${e}`, 16));
    return this.encode(r);
  }
  decodeHex(t) {
    return this.decode(t).map((o) => o.toString(Rt).slice(1)).join("");
  }
  isValidId(t) {
    return this.allowedCharsRegExp.test(t);
  }
  _encode(t) {
    let { alphabet: o } = this;
    const r = t.reduce((_, h, w) => _ + (typeof h == "bigint" ? Number(h % BigInt(w + Ht)) : h % (w + Ht)), 0);
    let e = [o[r % o.length]];
    const p = [...e], { seps: s } = this, { guards: d } = this;
    if (t.forEach((_, h) => {
      const w = p.concat(this.salt, o);
      o = be(o, w);
      const k = _r(_, o);
      if (e.push(...k), h + 1 < t.length) {
        const Et = k[0].codePointAt(0) + h, nr = typeof _ == "bigint" ? Number(_ % BigInt(Et)) : _ % Et;
        e.push(s[nr % s.length]);
      }
    }), e.length < this.minLength) {
      const _ = (r + e[0].codePointAt(0)) % d.length;
      if (e.unshift(d[_]), e.length < this.minLength) {
        const h = (r + e[2].codePointAt(0)) % d.length;
        e.push(d[h]);
      }
    }
    const g = Math.floor(o.length / 2);
    for (; e.length < this.minLength; ) {
      o = be(o, o), e.unshift(...o.slice(g)), e.push(...o.slice(0, g));
      const _ = e.length - this.minLength;
      if (_ > 0) {
        const h = _ / 2;
        e = e.slice(h, h + this.minLength);
      }
    }
    return e;
  }
  _decode(t) {
    if (!this.isValidId(t))
      throw new Error(`The provided ID (${t}) is invalid, as it contains characters that do not exist in the alphabet (${this.guards.join("")}${this.seps.join("")}${this.alphabet.join("")})`);
    const o = t.split(this.guardsRegExp), r = o.length === 3 || o.length === 2 ? 1 : 0, e = o[r];
    if (e.length === 0)
      return [];
    const p = e[Symbol.iterator]().next().value, s = e.slice(p.length).split(this.sepsRegExp);
    let d = this.alphabet;
    const g = [];
    for (const _ of s) {
      const h = [p, ...this.salt, ...d], w = be(d, h.slice(0, d.length));
      g.push(hr(Array.from(_), w)), d = w;
    }
    return this._encode(g).join("") !== t ? [] : g;
  }
}
const Re = {
  display: new it("display", 10),
  asset: new it("asset", 20)
};
function Vt(n) {
  Re[n] || (Re[n] = new it(n));
}
function Dr(n, ...t) {
  return Vt(n), Re[n].encode(...t);
}
function $r(n, t) {
  return Vt(n), Re[n].decode(t);
}
var He = /* @__PURE__ */ ((n) => (n.Unknown = "unknown", n.Image = "image", n.Pdf = "pdf", n.Video = "video", n))(He || {});
I(He, {
  name: "AssetType"
});
var jr = Object.defineProperty, Ir = Object.getOwnPropertyDescriptor, R = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Ir(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && jr(t, o, e), e;
};
let P = class extends D {
  get hashid() {
    return P.encodeHashid(this.id);
  }
  static encodeHashid(n) {
    return Dr("asset", n);
  }
  static decodeHashid(n) {
    return $r("asset", n)[0];
  }
};
R([
  l(() => String),
  O()
], P.prototype, "name", 2);
R([
  l(() => String),
  O()
], P.prototype, "mime", 2);
R([
  l(() => a),
  Se()
], P.prototype, "bytes", 2);
R([
  l(() => He),
  ht(He)
], P.prototype, "type", 2);
R([
  l(() => a),
  Se()
], P.prototype, "page", 2);
R([
  l(() => a),
  Se()
], P.prototype, "offsetX", 2);
R([
  l(() => a),
  Se()
], P.prototype, "offsetY", 2);
R([
  l(() => a),
  Se()
], P.prototype, "scale", 2);
R([
  l(() => Boolean),
  or()
], P.prototype, "hasCropped", 2);
P = R([
  b("Asset")
], P);
var Ar = Object.defineProperty, Sr = Object.getOwnPropertyDescriptor, dt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Sr(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Ar(t, o, e), e;
};
let Le = class {
};
dt([
  l(() => String),
  Qe()
], Le.prototype, "email", 2);
dt([
  l(() => String),
  O()
], Le.prototype, "password", 2);
Le = dt([
  U()
], Le);
var Cr = Object.defineProperty, Br = Object.getOwnPropertyDescriptor, qt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Br(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Cr(t, o, e), e;
}, Ot = /* @__PURE__ */ ((n) => (n.Authenticated = "Authenticated", n.Unauthenticated = "Unauthenticated", n))(Ot || {});
I(Ot, { name: "AuthenticationStatusEnum" });
let ut = class {
};
qt([
  l(() => Ot)
], ut.prototype, "status", 2);
ut = qt([
  b()
], ut);
var Tr = Object.defineProperty, Er = Object.getOwnPropertyDescriptor, Kt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Er(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Tr(t, o, e), e;
};
let ct = class {
};
Kt([
  l(() => String),
  O()
], ct.prototype, "token", 2);
ct = Kt([
  b("AuthenticationToken")
], ct);
var xr = Object.defineProperty, Nr = Object.getOwnPropertyDescriptor, Wt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Nr(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && xr(t, o, e), e;
};
let ft = class {
};
Wt([
  l(() => String),
  Qe()
], ft.prototype, "email", 2);
ft = Wt([
  U()
], ft);
var Ur = Object.defineProperty, Rr = Object.getOwnPropertyDescriptor, gt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Rr(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Ur(t, o, e), e;
};
let Fe = class {
};
gt([
  l(() => String),
  O()
], Fe.prototype, "token", 2);
gt([
  l(() => String),
  O()
], Fe.prototype, "newPassword", 2);
Fe = gt([
  U()
], Fe);
var y = /* @__PURE__ */ ((n) => (n.Draft = "Draft", n.PendingApproval = "PendingApproval", n.Published = "Published", n.Rejected = "Rejected", n))(y || {});
I(y, {
  name: "ContentStateEnum"
});
var C = /* @__PURE__ */ ((n) => (n.Unknown = "Unknown", n.Banner = "Banner", n.AssetBanner = "AssetBanner", n.TemplateBanner = "TemplateBanner", n.Note = "Note", n))(C || {});
I(C, {
  name: "ContentType",
  description: "The different content types supported."
});
var Hr = Object.defineProperty, Lr = Object.getOwnPropertyDescriptor, Jt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Lr(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Hr(t, o, e), e;
};
let ze = class {
  // @Field(() => Boolean, { nullable: true })
  // showCodeWord? boolean | null;
  // @Field(() => String, { nullable: true })
  // displayInformation? string | null;
};
Jt([
  l(() => String, { nullable: !0 })
], ze.prototype, "codeWord", 2);
ze = Jt([
  b("EntityConfiguration")
], ze);
var Fr = Object.defineProperty, zr = Object.getOwnPropertyDescriptor, ye = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? zr(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Fr(t, o, e), e;
};
let K = class {
};
ye([
  l(() => String, { nullable: !0 })
], K.prototype, "street", 2);
ye([
  l(() => String, { nullable: !0 })
], K.prototype, "zip", 2);
ye([
  l(() => String, { nullable: !0 })
], K.prototype, "city", 2);
ye([
  l(() => String, { nullable: !0 })
], K.prototype, "region", 2);
ye([
  l(() => String, { nullable: !0 })
], K.prototype, "country", 2);
K = ye([
  b("EntityLocation")
], K);
var S = /* @__PURE__ */ ((n) => (n.System = "System", n.Group = "Group", n.Organization = "Organization", n.Display = "Display", n))(S || {});
I(S, {
  name: "EntityType"
});
var _e = /* @__PURE__ */ ((n) => (n.api = "api", n.user = "user", n))(_e || {});
I(_e, {
  name: "UserType"
});
var Gr = Object.defineProperty, Mr = Object.getOwnPropertyDescriptor, Pt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Mr(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Gr(t, o, e), e;
};
let B = class extends D {
};
Pt([
  l(() => _e, { description: "The type of user." })
], B.prototype, "type", 2);
Pt([
  l(() => String, { nullable: !0, description: "The name to be used for user." })
], B.prototype, "name", 2);
B = Pt([
  re("BaseUserInterface", {
    isAbstract: !0,
    description: "Interface describing the base properties for all user types.",
    resolveType(n) {
      switch (n?.type) {
        case _e.api:
          return "ApiUser";
        case _e.user:
          return "User";
        default:
          throw new TypeError("failed to resolve entity type");
      }
    }
  })
], B);
var mt = /* @__PURE__ */ ((n) => (n.Owner = "Owner", n.Administrator = "Administrator", n.Moderator = "Moderator", n))(mt || {});
I(mt, {
  name: "EntityUserRole"
});
var Vr = Object.defineProperty, qr = Object.getOwnPropertyDescriptor, Q = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? qr(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Vr(t, o, e), e;
};
let T = class {
};
Q([
  l(() => a)
], T.prototype, "entityId", 2);
Q([
  l(() => u)
], T.prototype, "entity", 2);
Q([
  l(() => a)
], T.prototype, "userId", 2);
Q([
  l(() => B)
], T.prototype, "user", 2);
Q([
  l(() => mt)
], T.prototype, "role", 2);
Q([
  l(() => Boolean)
], T.prototype, "autoApprove", 2);
Q([
  l(() => Boolean)
], T.prototype, "smsNotifications", 2);
T = Q([
  b("EntityUserRelation")
], T);
var Kr = Object.defineProperty, Wr = Object.getOwnPropertyDescriptor, H = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Wr(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Kr(t, o, e), e;
};
let u = class extends D {
};
H([
  l(() => S, { description: "The type of entity." }),
  c({ enum: S })
], u.prototype, "type", 2);
H([
  l(() => String, { description: "The name of the entity." }),
  c()
], u.prototype, "name", 2);
H([
  l(() => ze, { description: "Object of configurations for the entity." })
], u.prototype, "config", 2);
H([
  l(() => K, { description: "Object of geographical location data for the entity." })
], u.prototype, "location", 2);
H([
  l(() => a, {
    nullable: !0,
    description: "The ID of the immediate parent of entity."
  }),
  c({ type: Number, nullable: !0 })
], u.prototype, "parentId", 2);
H([
  l(() => u, {
    nullable: !0,
    description: "The immediate parent object of entity."
  })
], u.prototype, "parent", 2);
H([
  l(() => [u], {
    nullable: !0,
    description: "Array of all ancestors of entity, ordered by the closest parent, ending with topmost ancestor. Is null if entity is a root level entity."
  })
], u.prototype, "ancestors", 2);
H([
  l(() => [u], {
    nullable: !0,
    description: "Array of the immediate children of entity; empty array if entity has no children, or null if entity can't have children."
  })
], u.prototype, "children", 2);
H([
  l(() => [T], {
    description: "Array of objects describing relation between entity and member users."
  })
], u.prototype, "userRelations", 2);
u = H([
  re({
    isAbstract: !0,
    resolveType(n) {
      switch (n?.type) {
        case S.Display:
          return "Display";
        case S.Group:
          return "Group";
        case S.Organization:
          return "Organization";
        case S.System:
          return "SystemEntity";
        default:
          throw new TypeError("Failed to resolve entity type.");
      }
    }
  })
], u);
var Jr = Object.defineProperty, Qr = Object.getOwnPropertyDescriptor, m = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Qr(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Jr(t, o, e), e;
};
let v = class extends D {
};
m([
  l(() => C)
], v.prototype, "type", 2);
m([
  l(() => String)
], v.prototype, "slot", 2);
m([
  l(() => y),
  c({ enum: y })
], v.prototype, "state", 2);
m([
  l(() => Date, { nullable: !0 }),
  c({ type: Date })
], v.prototype, "start", 2);
m([
  l(() => Date, { nullable: !0 }),
  c({ type: Date, nullable: !0 })
], v.prototype, "end", 2);
m([
  l(() => Boolean, { description: "Boolean flag of whether the content should cascade down to all descendants." })
], v.prototype, "cascade", 2);
m([
  l(() => Boolean, {
    description: "Boolean flag of whether the content only should be used if no other non-fallback content is available."
  })
], v.prototype, "fallback", 2);
m([
  l(() => a),
  c({ type: Number })
], v.prototype, "entityId", 2);
m([
  l(() => u)
], v.prototype, "entity", 2);
m([
  l(() => a)
], v.prototype, "userId", 2);
m([
  l(() => B, { nullable: !0 })
], v.prototype, "user", 2);
v = m([
  re({
    isAbstract: !0,
    implements: [D],
    description: "Interface describing the base properties for all content types.",
    resolveType(n) {
      switch (n?.type) {
        case C.AssetBanner:
          return "AssetBannerContent";
        case C.TemplateBanner:
          return "TemplateBannerContent";
        case C.Note:
          return "NoteContent";
        default:
          throw new TypeError("failed to resolve entity type");
      }
    }
  })
], v);
var Xr = Object.defineProperty, Yr = Object.getOwnPropertyDescriptor, Xe = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Yr(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Xr(t, o, e), e;
};
let pe = class {
};
Xe([
  l(() => String, {
    nullable: !0,
    description: "An optional content component slot to filter returned content on."
  })
], pe.prototype, "slot", 2);
Xe([
  l(() => y, { nullable: !0 })
], pe.prototype, "state", 2);
Xe([
  l(() => C, {
    nullable: !0,
    description: "An optional content type to filter returned content on."
  })
], pe.prototype, "type", 2);
pe = Xe([
  f("FindContentFilter")
], pe);
var Zr = Object.defineProperty, kr = Object.getOwnPropertyDescriptor, Ce = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? kr(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Zr(t, o, e), e;
};
let se = class {
};
Ce([
  l(() => a, { description: "The ID of entity to find content for." })
], se.prototype, "entityId", 2);
Ce([
  l(() => Boolean, {
    nullable: !0,
    description: "Flag whether to include content inherited from any ancestor entity."
  })
], se.prototype, "includeInherited", 2);
Ce([
  l(() => Boolean, {
    nullable: !0,
    description: "Flag whether to include content from descending entities."
  })
], se.prototype, "includeDescendants", 2);
Ce([
  l(() => pe, { nullable: !0 })
], se.prototype, "filter", 2);
se = Ce([
  U()
], se);
var en = Object.defineProperty, tn = Object.getOwnPropertyDescriptor, Qt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? tn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && en(t, o, e), e;
};
let yt = class {
};
Qt([
  l(() => a)
], yt.prototype, "id", 2);
yt = Qt([
  f()
], yt);
var rn = Object.defineProperty, nn = Object.getOwnPropertyDescriptor, X = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? nn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && rn(t, o, e), e;
};
let z = class {
};
X([
  l(() => String, { nullable: !0 })
], z.prototype, "assetHashid", 2);
X([
  l(() => a)
], z.prototype, "entityId", 2);
X([
  l(() => String)
], z.prototype, "slot", 2);
X([
  l(() => C)
], z.prototype, "type", 2);
X([
  l(() => y, { defaultValue: y.Draft })
], z.prototype, "state", 2);
X([
  l(() => Date, { nullable: !0 })
], z.prototype, "start", 2);
X([
  l(() => Date, { nullable: !0 })
], z.prototype, "end", 2);
z = X([
  f()
], z);
var on = Object.defineProperty, pn = Object.getOwnPropertyDescriptor, Be = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? pn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && on(t, o, e), e;
};
let le = class {
};
Be([
  l(() => a, { nullable: !0 })
], le.prototype, "entityId", 2);
Be([
  l(() => y, { nullable: !0 })
], le.prototype, "state", 2);
Be([
  l(() => Date, { nullable: !0 })
], le.prototype, "start", 2);
Be([
  l(() => Date, { nullable: !0 })
], le.prototype, "end", 2);
le = Be([
  f()
], le);
var sn = Object.defineProperty, ln = Object.getOwnPropertyDescriptor, Y = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? ln(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && sn(t, o, e), e;
};
let G = class {
};
Y([
  l(() => a),
  c()
], G.prototype, "entityId", 2);
Y([
  l(() => a),
  c()
], G.prototype, "assetId", 2);
Y([
  l(() => String, { nullable: !0 })
], G.prototype, "slot", 2);
Y([
  l(() => y, { nullable: !0 }),
  i({ enum: y, default: y.Draft })
], G.prototype, "state", 2);
Y([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], G.prototype, "start", 2);
Y([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], G.prototype, "end", 2);
Y([
  l(() => a, { nullable: !0 }),
  i({ type: Number })
], G.prototype, "duration", 2);
G = Y([
  f()
], G);
var an = Object.defineProperty, un = Object.getOwnPropertyDescriptor, ne = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? un(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && an(t, o, e), e;
};
let W = class {
};
ne([
  l(() => a)
], W.prototype, "entityId", 2);
ne([
  l(() => String, { nullable: !0 })
], W.prototype, "slot", 2);
ne([
  l(() => y, { nullable: !0 })
], W.prototype, "state", 2);
ne([
  l(() => Date, { nullable: !0 })
], W.prototype, "start", 2);
ne([
  l(() => Date, { nullable: !0 })
], W.prototype, "end", 2);
ne([
  l(() => a, { nullable: !0 })
], W.prototype, "duration", 2);
W = ne([
  f()
], W);
var cn = Object.defineProperty, fn = Object.getOwnPropertyDescriptor, V = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? fn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && cn(t, o, e), e;
};
let E = class {
};
V([
  l(() => a),
  c()
], E.prototype, "entityId", 2);
V([
  l(() => String, { nullable: !0 })
], E.prototype, "slot", 2);
V([
  l(() => y, { nullable: !0 }),
  i({ enum: y, default: y.Draft })
], E.prototype, "state", 2);
V([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], E.prototype, "start", 2);
V([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], E.prototype, "end", 2);
V([
  l(() => a, { nullable: !0 }),
  i({ type: Number })
], E.prototype, "duration", 2);
V([
  l(),
  c()
], E.prototype, "templateId", 2);
V([
  l(() => String, { nullable: !0 }),
  i({ type: String })
], E.prototype, "templateText", 2);
E = V([
  f()
], E);
var yn = Object.defineProperty, vn = Object.getOwnPropertyDescriptor, Z = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? vn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && yn(t, o, e), e;
};
let M = class {
};
Z([
  l(() => a, { nullable: !0 }),
  i({ type: Number })
], M.prototype, "entityId", 2);
Z([
  l(() => a, { nullable: !0 }),
  i({ type: Number })
], M.prototype, "assetId", 2);
Z([
  l(() => String, { nullable: !0 })
], M.prototype, "slot", 2);
Z([
  l(() => y, { nullable: !0 }),
  i({ enum: y })
], M.prototype, "state", 2);
Z([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], M.prototype, "start", 2);
Z([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], M.prototype, "end", 2);
Z([
  l(() => a, { nullable: !0 }),
  i({ type: Number })
], M.prototype, "duration", 2);
M = Z([
  f()
], M);
var bn = Object.defineProperty, _n = Object.getOwnPropertyDescriptor, q = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? _n(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && bn(t, o, e), e;
};
let x = class {
};
q([
  l(() => a, { nullable: !0 }),
  i({ type: Number })
], x.prototype, "entityId", 2);
q([
  l(() => String, { nullable: !0 })
], x.prototype, "slot", 2);
q([
  l(() => y, { nullable: !0 }),
  i({ enum: y })
], x.prototype, "state", 2);
q([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], x.prototype, "start", 2);
q([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], x.prototype, "end", 2);
q([
  l(() => a, { nullable: !0 }),
  i({ type: Number })
], x.prototype, "duration", 2);
q([
  l(() => String, { nullable: !0 }),
  i({ type: String })
], x.prototype, "templateId", 2);
q([
  l(() => String, { nullable: !0 }),
  i({ type: String })
], x.prototype, "templateText", 2);
x = q([
  f()
], x);
var hn = Object.defineProperty, dn = Object.getOwnPropertyDescriptor, Xt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? dn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && hn(t, o, e), e;
};
let ee = class extends v {
};
Xt([
  l(() => a, { nullable: !0 })
], ee.prototype, "duration", 2);
ee = Xt([
  re({
    implements: [v],
    isAbstract: !0,
    description: "Interface describing common properties for all banner content sub-types.",
    resolveType(n) {
      switch (n?.type) {
        case C.AssetBanner:
          return "ContentBannerAsset";
        case C.TemplateBanner:
          return "ContentBannerTemplate";
        default:
          throw new TypeError("Failed to resolve banner type");
      }
    }
  })
], ee);
var On = Object.defineProperty, gn = Object.getOwnPropertyDescriptor, Yt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? gn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && On(t, o, e), e;
};
let vt = class extends ee {
  // @Field(() => AssetInterface)
  // asset!: AssetInterface;
};
Yt([
  l(() => a),
  c()
], vt.prototype, "assetId", 2);
vt = Yt([
  b("ContentBannerAsset", { implements: [v, ee] })
], vt);
var Pn = Object.defineProperty, mn = Object.getOwnPropertyDescriptor, wt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? mn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Pn(t, o, e), e;
};
let Ge = class extends ee {
};
wt([
  l(),
  c()
], Ge.prototype, "templateId", 2);
wt([
  l(() => String, { nullable: !0 }),
  i()
], Ge.prototype, "templateText", 2);
Ge = wt([
  b("ContentBannerTemplate", { implements: [v, ee] })
], Ge);
var Zt = /* @__PURE__ */ ((n) => (n.Asset = "Asset", n.Template = "Template", n))(Zt || {});
I(Zt, {
  name: "ContentBannerType",
  description: "The different content banner types supported."
});
var wn = Object.defineProperty, Dn = Object.getOwnPropertyDescriptor, Te = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Dn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && wn(t, o, e), e;
};
let ae = class extends v {
  // TODO: Swagger: QR code
};
Te([
  l(() => String),
  c()
], ae.prototype, "text", 2);
Te([
  l(() => String, { nullable: !0 }),
  c({ type: String })
], ae.prototype, "icon", 2);
Te([
  l(() => String, { nullable: !0 }),
  c({ type: String, nullable: !0 })
], ae.prototype, "iconColor", 2);
Te([
  l(() => Boolean, { nullable: !0 }),
  c({ type: Boolean })
], ae.prototype, "sticky", 2);
ae = Te([
  b("NoteContent", { implements: [v] })
], ae);
var $n = Object.defineProperty, jn = Object.getOwnPropertyDescriptor, L = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? jn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && $n(t, o, e), e;
};
let $ = class {
};
L([
  l(() => a),
  c()
], $.prototype, "entityId", 2);
L([
  l(() => String, { nullable: !0 })
], $.prototype, "slot", 2);
L([
  l(() => y, { nullable: !0 }),
  i({ enum: y, default: y.Draft })
], $.prototype, "state", 2);
L([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], $.prototype, "start", 2);
L([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], $.prototype, "end", 2);
L([
  l(() => String),
  c()
], $.prototype, "text", 2);
L([
  l(() => String, { nullable: !0 }),
  i({ type: String })
], $.prototype, "icon", 2);
L([
  l(() => String, { nullable: !0 }),
  i({ type: String })
], $.prototype, "iconColor", 2);
L([
  l(() => Boolean, { nullable: !0 }),
  i({ type: Boolean })
], $.prototype, "sticky", 2);
$ = L([
  f()
], $);
var In = Object.defineProperty, An = Object.getOwnPropertyDescriptor, F = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? An(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && In(t, o, e), e;
};
let j = class {
};
F([
  l(() => a, { nullable: !0 }),
  i({ type: Number })
], j.prototype, "entityId", 2);
F([
  l(() => String, { nullable: !0 })
], j.prototype, "slot", 2);
F([
  l(() => y, { nullable: !0 }),
  i({ enum: y })
], j.prototype, "state", 2);
F([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], j.prototype, "start", 2);
F([
  l(() => Date, { nullable: !0 }),
  i({ type: Date })
], j.prototype, "end", 2);
F([
  l(() => String, { nullable: !0 }),
  i({ type: String })
], j.prototype, "text", 2);
F([
  l(() => String, { nullable: !0 }),
  i({ type: String })
], j.prototype, "icon", 2);
F([
  l(() => String, { nullable: !0 }),
  i({ type: String })
], j.prototype, "iconColor", 2);
F([
  l(() => Boolean, { nullable: !0 }),
  i({ type: Boolean })
], j.prototype, "sticky", 2);
j = F([
  f()
], j);
var Sn = Object.defineProperty, Cn = Object.getOwnPropertyDescriptor, Ye = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Cn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Sn(t, o, e), e;
};
let he = class {
};
Ye([
  l(() => String),
  Qe()
], he.prototype, "adminEmail", 2);
Ye([
  l(() => String),
  O()
], he.prototype, "adminPassword", 2);
Ye([
  l(() => String),
  O()
], he.prototype, "rootEntity", 2);
he = Ye([
  f()
], he);
var Bn = Object.defineProperty, Tn = Object.getOwnPropertyDescriptor, Dt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Tn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Bn(t, o, e), e;
};
let de = class extends B {
};
Dt([
  l(() => String, { description: "The email of the entity the User is associated with." })
], de.prototype, "email", 2);
Dt([
  l(() => String, {
    nullable: !0,
    description: "An optional phone number associated with user, used to send notifications."
  })
], de.prototype, "phoneNumber", 2);
de = Dt([
  b("User", {
    implements: [B],
    description: "User type representing a regular (human) user, authenticated using email and password."
  })
], de);
var En = Object.defineProperty, xn = Object.getOwnPropertyDescriptor, Ze = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? xn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && En(t, o, e), e;
};
let Oe = class {
};
Ze([
  l(() => de)
], Oe.prototype, "admin", 2);
Ze([
  l(() => u)
], Oe.prototype, "mainDisplay", 2);
Ze([
  l(() => u)
], Oe.prototype, "rootEntity", 2);
Oe = Ze([
  b()
], Oe);
var $t = /* @__PURE__ */ ((n) => (n.Horizontal = "horizontal", n.Vertical = "vertical", n))($t || {});
I($t, {
  name: "DisplayOrientationEnum"
});
var Nn = Object.defineProperty, Un = Object.getOwnPropertyDescriptor, ke = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Un(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Nn(t, o, e), e;
};
let ge = class {
};
ke([
  l(() => a, { nullable: !0 })
], ge.prototype, "parentId", 2);
ke([
  l(() => Boolean, { defaultValue: !0 })
], ge.prototype, "includeParent", 2);
ke([
  l(() => Boolean, { nullable: !0 })
], ge.prototype, "withDecendants", 2);
ge = ke([
  U()
], ge);
var Rn = Object.defineProperty, Hn = Object.getOwnPropertyDescriptor, kt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Hn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Rn(t, o, e), e;
};
let N = class {
  // @Field(() => Boolean, { nullable: true })
  // showCodeWord? boolean | null;
  // @Field(() => String, { nullable: true })
  // displayInformation? string | null;
};
kt([
  l(() => String, { nullable: !0 })
], N.prototype, "codeWord", 2);
N = kt([
  f()
], N);
var Ln = Object.defineProperty, Fn = Object.getOwnPropertyDescriptor, Ee = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Fn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Ln(t, o, e), e;
};
let ie = class {
};
Ee([
  l(() => String, { description: "The name the entity should be given." }),
  O({ message: "Entity name must be a string." }),
  pr(3, { message: "Entity name has to be at least 3 characters long." }),
  sr(255, { message: "Entity name cannot be more than 255 characters long." })
], ie.prototype, "name", 2);
Ee([
  l(() => S, { description: "The type of entity being created." }),
  ht(S, { message: "Entity type is not one of accepted values of `EntityTypeEnum`." })
], ie.prototype, "type", 2);
Ee([
  l(() => a, { description: "The ID of the parent entity should be created under." }),
  Gt({ message: "Entity parentId must be an integer." })
], ie.prototype, "parentId", 2);
Ee([
  l(() => N, {
    nullable: !0,
    description: "An optional object of configurations to set for entity."
  }),
  fe()
], ie.prototype, "config", 2);
ie = Ee([
  f()
], ie);
var zn = Object.defineProperty, Gn = Object.getOwnPropertyDescriptor, et = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Gn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && zn(t, o, e), e;
};
let Pe = class {
};
et([
  l(() => String, { nullable: !0 })
], Pe.prototype, "name", 2);
et([
  l(() => a, { nullable: !0 })
], Pe.prototype, "parentId", 2);
et([
  l(() => N, { nullable: !0 })
], Pe.prototype, "config", 2);
Pe = et([
  f()
], Pe);
var Mn = Object.defineProperty, Vn = Object.getOwnPropertyDescriptor, jt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Vn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Mn(t, o, e), e;
}, It = /* @__PURE__ */ ((n) => (n.delete = "delete", n.move = "move", n))(It || {});
I(It, { name: "DeleteEntityInputWithChildren" });
let Me = class {
};
jt([
  l(() => String, { description: "How to handle children of entity being deleted." }),
  ht(It, { message: "Entity withChildren must be one of accepted values." })
], Me.prototype, "withChildren", 2);
jt([
  l(() => a, { description: "Entity ID to move children to.", nullable: !0 }),
  Gt({ message: "Entity moveToEntityId must be an integer." }),
  fe()
], Me.prototype, "moveToEntityId", 2);
Me = jt([
  U()
], Me);
var qn = Object.defineProperty, Kn = Object.getOwnPropertyDescriptor, ve = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Kn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && qn(t, o, e), e;
};
let J = class {
};
ve([
  l(() => String, { nullable: !0 })
], J.prototype, "street", 2);
ve([
  l(() => String, { nullable: !0 })
], J.prototype, "zip", 2);
ve([
  l(() => String, { nullable: !0 })
], J.prototype, "city", 2);
ve([
  l(() => String, { nullable: !0 })
], J.prototype, "region", 2);
ve([
  l(() => String, { nullable: !0 })
], J.prototype, "country", 2);
J = ve([
  f()
], J);
var Wn = Object.defineProperty, Jn = Object.getOwnPropertyDescriptor, er = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Jn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Wn(t, o, e), e;
};
let bt = class {
  // @Field(() => EntityUserRoleEnum)
  // @IsEnum(EntityUserRoleEnum)
  // role!: EntityUserRoleEnum;
};
er([
  l(() => String),
  Qe()
], bt.prototype, "email", 2);
bt = er([
  U()
], bt);
var Qn = Object.defineProperty, Xn = Object.getOwnPropertyDescriptor, At = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Xn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Qn(t, o, e), e;
};
let Ve = class {
};
At([
  l(() => Boolean, { nullable: !0 })
], Ve.prototype, "autoApprove", 2);
At([
  l(() => Boolean, { nullable: !0 })
], Ve.prototype, "smsNotifications", 2);
Ve = At([
  U()
], Ve);
var Yn = Object.defineProperty, Zn = Object.getOwnPropertyDescriptor, xe = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Zn(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Yn(t, o, e), e;
};
let ue = class extends u {
};
xe([
  c({ enum: ["Display"] })
], ue.prototype, "type", 2);
xe([
  l(() => Boolean, { nullable: !0 })
], ue.prototype, "onlineStatus", 2);
xe([
  l(() => String, { nullable: !0 })
], ue.prototype, "apiKey", 2);
xe([
  l(() => $t, { nullable: !0 })
], ue.prototype, "orientation", 2);
ue = xe([
  b("Display", { implements: [u] })
], ue);
var kn = Object.defineProperty, eo = Object.getOwnPropertyDescriptor, tt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? eo(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && kn(t, o, e), e;
};
let me = class {
};
tt([
  l(() => String)
], me.prototype, "name", 2);
tt([
  l(() => a)
], me.prototype, "parentId", 2);
tt([
  l(() => N, { nullable: !0 })
], me.prototype, "config", 2);
me = tt([
  f()
], me);
var to = Object.defineProperty, ro = Object.getOwnPropertyDescriptor, rt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? ro(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && to(t, o, e), e;
};
let we = class {
};
rt([
  l(() => String, { nullable: !0 })
], we.prototype, "name", 2);
rt([
  l(() => a, { nullable: !0 })
], we.prototype, "parentId", 2);
rt([
  l(() => N, { nullable: !0 })
], we.prototype, "config", 2);
we = rt([
  f()
], we);
var no = Object.defineProperty, oo = Object.getOwnPropertyDescriptor, tr = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? oo(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && no(t, o, e), e;
};
let _t = class extends u {
};
tr([
  c({ enum: ["Group"] })
], _t.prototype, "type", 2);
_t = tr([
  b("Group", { implements: [u] })
], _t);
var po = Object.defineProperty, so = Object.getOwnPropertyDescriptor, nt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? so(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && po(t, o, e), e;
};
let te = class {
};
nt([
  l(() => String)
], te.prototype, "name", 2);
nt([
  l(() => a)
], te.prototype, "parentId", 2);
nt([
  l(() => N, { nullable: !0 })
], te.prototype, "config", 2);
te = nt([
  f()
], te);
var lo = Object.defineProperty, ao = Object.getOwnPropertyDescriptor, ot = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? ao(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && lo(t, o, e), e;
};
let De = class {
};
ot([
  l(() => String, { nullable: !0 })
], De.prototype, "name", 2);
ot([
  l(() => a, { nullable: !0 })
], De.prototype, "parentId", 2);
ot([
  l(() => N, { nullable: !0 })
], De.prototype, "config", 2);
De = ot([
  f()
], De);
var io = Object.defineProperty, uo = Object.getOwnPropertyDescriptor, St = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? uo(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && io(t, o, e), e;
};
let qe = class extends u {
};
St([
  c({ enum: ["Organization"] })
], qe.prototype, "type", 2);
St([
  l(() => String, { nullable: !0 }),
  c({ type: String, nullable: !0 })
], qe.prototype, "organizationIdentifier", 2);
qe = St([
  b("Organization", { implements: [u] })
], qe);
var co = Object.defineProperty, fo = Object.getOwnPropertyDescriptor, Ct = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? fo(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && co(t, o, e), e;
};
let Ke = class extends te {
};
Ct([
  l(() => String, { nullable: !0 }),
  O({ message: "Organization ID name must be a string." }),
  fe()
], Ke.prototype, "organizationIdentifier", 2);
Ct([
  l(() => J, { nullable: !0 }),
  lr(),
  fe()
], Ke.prototype, "location", 2);
Ke = Ct([
  f()
], Ke);
var yo = Object.defineProperty, vo = Object.getOwnPropertyDescriptor, Ne = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? vo(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && yo(t, o, e), e;
};
let ce = class {
};
Ne([
  l(() => String, { nullable: !0 })
], ce.prototype, "name", 2);
Ne([
  l(() => a, { nullable: !0 })
], ce.prototype, "parentId", 2);
Ne([
  l(() => N, { nullable: !0 })
], ce.prototype, "config", 2);
Ne([
  l(() => String, { nullable: !0 })
], ce.prototype, "organizationIdentifier", 2);
ce = Ne([
  f()
], ce);
var bo = Object.defineProperty, _o = Object.getOwnPropertyDescriptor, ho = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? _o(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && bo(t, o, e), e;
};
let Lt = class extends u {
};
Lt = ho([
  b("SystemEntity", { implements: [u] })
], Lt);
var Oo = Object.defineProperty, go = Object.getOwnPropertyDescriptor, Po = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? go(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Oo(t, o, e), e;
};
let Ft = class extends te {
};
Ft = Po([
  f()
], Ft);
var mo = Object.defineProperty, wo = Object.getOwnPropertyDescriptor, pt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? wo(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && mo(t, o, e), e;
};
let $e = class {
};
pt([
  l(() => String),
  O()
], $e.prototype, "number", 2);
pt([
  l(() => String),
  O()
], $e.prototype, "description", 2);
pt([
  l(() => Date, { nullable: !0 }),
  ar(),
  fe()
], $e.prototype, "expirationDate", 2);
$e = pt([
  b("EntityAutoApproveNumber")
], $e);
var Do = Object.defineProperty, $o = Object.getOwnPropertyDescriptor, st = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? $o(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Do(t, o, e), e;
};
let je = class {
};
st([
  l(() => String)
], je.prototype, "number", 2);
st([
  l(() => String)
], je.prototype, "description", 2);
st([
  l(() => Date, { nullable: !0 })
], je.prototype, "expirationDate", 2);
je = st([
  f()
], je);
var jo = Object.defineProperty, Io = Object.getOwnPropertyDescriptor, lt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Io(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && jo(t, o, e), e;
};
let Ie = class {
};
lt([
  l(() => String, { nullable: !0 })
], Ie.prototype, "number", 2);
lt([
  l(() => String, { nullable: !0 })
], Ie.prototype, "description", 2);
lt([
  l(() => Date, { nullable: !0 })
], Ie.prototype, "expirationDate", 2);
Ie = lt([
  f()
], Ie);
var Ao = /* @__PURE__ */ ((n) => (n[n.Draft = 0] = "Draft", n[n.PendingApproval = 1] = "PendingApproval", n[n.Published = 2] = "Published", n))(Ao || {}), So = Object.defineProperty, Co = Object.getOwnPropertyDescriptor, Bt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Co(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && So(t, o, e), e;
};
let We = class {
};
Bt([
  l(() => Boolean, { defaultValue: !1 })
], We.prototype, "installed", 2);
Bt([
  l(() => String, { nullable: !0 })
], We.prototype, "version", 2);
We = Bt([
  b("ServerStatus")
], We);
var rr = /* @__PURE__ */ ((n) => (n.Unprocessed = "Unprocessed", n.Processed = "Processed", n.InvalidCodeWord = "InvalidCodeWord", n.Failed = "Failed", n))(rr || {});
I(rr, {
  name: "SmsIncomingMessageStatus"
});
var Bo = Object.defineProperty, To = Object.getOwnPropertyDescriptor, Tt = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? To(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Bo(t, o, e), e;
};
let Je = class {
};
Tt([
  l(() => a, { nullable: !0 })
], Je.prototype, "entityId", 2);
Tt([
  l(() => Boolean, { nullable: !0 })
], Je.prototype, "includeInherited", 2);
Je = Tt([
  U()
], Je);
var Eo = Object.defineProperty, xo = Object.getOwnPropertyDescriptor, No = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? xo(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Eo(t, o, e), e;
};
let zt = class extends B {
};
zt = No([
  b("ApiUser", {
    implements: [B],
    description: "User type for direct API communication, authenticating with an key and secret."
  })
], zt);
var Uo = Object.defineProperty, Ro = Object.getOwnPropertyDescriptor, at = (n, t, o, r) => {
  for (var e = r > 1 ? void 0 : r ? Ro(t, o) : t, p = n.length - 1, s; p >= 0; p--)
    (s = n[p]) && (e = (r ? s(t, o, e) : s(e)) || e);
  return r && e && Uo(t, o, e), e;
};
let Ae = class {
};
at([
  l(() => a, { nullable: !0 })
], Ae.prototype, "userId", 2);
at([
  l(() => String, { nullable: !0 }),
  fe(),
  O()
], Ae.prototype, "password", 2);
at([
  l(() => String),
  O()
], Ae.prototype, "newPassword", 2);
Ae = at([
  U()
], Ae);
export {
  je as AddEntityAutoApproveNumberInput,
  bt as AddEntityUserArgs,
  zt as ApiUserObject,
  P as AssetObject,
  He as AssetTypeEnum,
  Le as AuthenticateArgs,
  Ot as AuthenticationStatusEnum,
  ut as AuthenticationStatusObject,
  ct as AuthenticationTokenObject,
  B as BaseUserInterface,
  Fe as ConfirmPasswordResetArgs,
  vt as ContentBannerAssetObject,
  ee as ContentBannerInterface,
  Ge as ContentBannerTemplateObject,
  Zt as ContentBannerTypeEnum,
  v as ContentInterface,
  y as ContentStateEnum,
  C as ContentTypeEnum,
  G as CreateAssetBannerContentInput,
  W as CreateBannerContentInput,
  z as CreateContentInput,
  me as CreateDisplayInput,
  ie as CreateEntityInput,
  te as CreateGroupInput,
  $ as CreateNoteContentInput,
  Ke as CreateOrganizationInput,
  Ft as CreateSystemEntityInput,
  E as CreateTemplateBannerContentInput,
  Me as DeleteEntityArgs,
  It as DeleteEntityInputWithChildren,
  ue as DisplayObject,
  $t as DisplayOrientationEnum,
  $e as EntityAutoApproveNumberObject,
  N as EntityConfigurationInput,
  ze as EntityConfigurationObject,
  u as EntityInterface,
  J as EntityLocationInput,
  K as EntityLocationObject,
  S as EntityTypeEnum,
  T as EntityUserRelationObject,
  mt as EntityUserRoleEnum,
  se as FindContentArgs,
  ge as FindEntitiesArgs,
  Je as FindUsersFilterArgs,
  yt as GetContentArgs,
  _t as GroupObject,
  Ue as IdBaseInterface,
  D as IdTimestampedBaseInterface,
  he as InstallServerInput,
  Oe as InstallServerObject,
  ae as NoteContentObject,
  Ao as NoteStatus,
  qe as OrganizationObject,
  ft as RequestPasswordResetArgs,
  We as ServerStatusObject,
  Ae as SetUserPasswordArgs,
  rr as SmsIncomingMessageStatus,
  Lt as SystemEntityObject,
  oe as TimestampedBaseInterface,
  M as UpdateAssetBannerContentInput,
  le as UpdateContentInput,
  we as UpdateDisplayInput,
  Ie as UpdateEntityAutoApproveNumberInput,
  Pe as UpdateEntityInput,
  Ve as UpdateEntityUserArgs,
  De as UpdateGroupInput,
  j as UpdateNoteContentInput,
  ce as UpdateOrganizationInput,
  x as UpdateTemplateBannerContentInput,
  de as UserObject,
  _e as UserTypeEnum
};
