<template>
	<sz-content-card class="sz-card-form" :title="title" :collapsible="collapsible" :collapsed="collapsed">
		<template slot="toolbar" v-if="$slots.toolbar">
			<slot name="toolbar" />
		</template>

		<template slot="title" v-if="$slots.title">
			<slot name="title">{{ title }}</slot>
		</template>

		<v-card-text>
			<form @submit="_submit">
				<div class="sz-card-form__field" v-for="(field, index) of fieldsWithAccess" :key="index">
					<v-checkbox
						v-if="isCheckbox(field)"
						v-model="formData[field.key]"
						:label="field.label"
						:hide-details="true"
					/>

					<sz-color-swatches
						v-else-if="isColorSwatches(field)"
						v-model="formData[field.key]"
						:label="field.label"
						:palette="field.palette"
					/>

					<sz-date-picker-input
						v-else-if="isDate(field)"
						v-model="formData[field.key]"
						:prepend-icon="field.icon"
						:label="field.label"
					/>

					<v-select
						v-else-if="isSelect(field)"
						v-model="formData[field.key]"
						:items="field.options"
						:label="field.label"
						:hide-details="true"
					/>

					<v-switch
						v-else-if="isSwitch(field)"
						v-model="formData[field.key]"
						:label="field.label"
						:hide-details="true"
					/>

					<sz-text-editor v-else-if="isTextEditor(field)" v-model="formData[field.key]" :label="field.label" />

					<v-text-field
						v-else-if="!isSelect(field) && !isTel(field)"
						v-model="formData[field.key]"
						:type="field.type"
						:prepend-icon="field.icon"
						:label="field.label"
						:hide-details="true"
					/>

					<sz-field v-else :label="field.label" :icon="field.icon">
						<sz-tel-input v-if="isTel(field)" v-model="formData[field.key]" />
					</sz-field>
				</div>

				<input type="submit" class="sz-card-form__hidden" />
			</form>
		</v-card-text>

		<transition name="sz-transition__fade-slide-up">
			<v-alert v-if="showResponse" class="mb-0" :value="showResponse" :type="showResponseType">
				<slot name="error-message" v-if="showResponseType === 'error'">{{ errorMessage }}</slot>
				<slot name="success-message" v-if="showResponseType === 'success'">{{ successMessage }}</slot>
			</v-alert>
		</transition>

		<template slot="actions-left" v-if="hasCancel">
			<v-btn color="error" @click="_cancel">{{ cancelText }}</v-btn>
		</template>

		<template slot="actions-right">
			<v-btn color="primary" @click="_submit">{{ submitText }}</v-btn>
		</template>
	</sz-content-card>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import clone from "lodash/clone";

import i18n from "@/plugins/i18n";

import { FormField, FormState } from "../interfaces";

function reduceToDotNotation(data: any) {
	const res: any = {};
	(function recurse(obj, current?: string) {
		for (const key in obj) {
			const value = obj[key];
			const newKey = current ? current + "." + key : key; // joined key with dot
			if (value && typeof value === "object") {
				recurse(value, newKey); // it's a nested object, so do it again
			} else {
				res[newKey] = value; // it's not an object, so set the property
			}
		}
	})(clone(data));
	return res;
}

@Component
export default class SzCardForm extends Vue {
	@Prop({ default: () => ({}) })
	data!: any;

	@Prop()
	title!: string;

	@Prop()
	errorMessage!: string;

	@Prop()
	successMessage!: string;

	@Prop({ required: true, type: [Function] })
	submit!: any;

	@Prop({ default: i18n.t("generic.actions.update") })
	submitText!: string;

	@Prop({ type: [Function] })
	cancel!: any;

	@Prop({ default: i18n.t("generic.actions.cancel") })
	cancelText!: string;

	@Prop({ required: true, type: [Array] })
	fields!: FormField[];

	@Prop({ default: false })
	collapsible!: boolean;

	@Prop({ default: false })
	collapsed!: boolean;

	state: FormState = FormState.Uninitialized;
	formData: any = {};

	showResponse = false;
	showResponseType: "success" | "error" | null = null;
	showResponseTimeout: NodeJS.Timer | null = null;

	get fieldsWithAccess() {
		return (this.fields ?? []).filter((field) => this.hasRightsTo(field));
	}

	get hasToolbar() {
		return this.$slots.toolbar || false;
	}

	get hasTitle() {
		return !!this.$slots.title || !!this.title;
	}

	get hasCancel() {
		return !!this.cancel;
	}

	get hasErrorMessage() {
		return !!this.$slots["error-message"] || !!this.errorMessage;
	}

	get hasSuccessMessage() {
		return !!this.$slots["success-message"] || !!this.successMessage;
	}

	hasRightsTo(field: FormField) {
		if (field.$can) {
			return this.$can(field.$can);
		}

		return true;
	}

	isCheckbox(field: FormField) {
		return field.type === "checkbox";
	}
	isColorSwatches(field: FormField) {
		return field.type === "color-swatches";
	}
	isDate(field: FormField) {
		return field.type === "date";
	}
	isSelect(field: FormField) {
		return field.type === "select";
	}
	isSwitch(field: FormField) {
		return field.type === "switch";
	}
	isTel(field: FormField) {
		return ["tel", "phone", "telephone"].includes(field.type);
	}
	isTextEditor(field: FormField) {
		return field.type === "text-editor";
	}

	async created() {
		this.state = FormState.Initialized;
	}

	@Watch("data", { immediate: true })
	onDataChange() {
		this.formData = reduceToDotNotation(this.data);
	}

	async _cancel(e: Event) {
		return this.cancel(e);
	}

	async _submit(e: Event) {
		e.preventDefault();

		this.state = FormState.Submitting;

		if (this.showResponseTimeout) {
			clearTimeout(this.showResponseTimeout);
		}

		try {
			const data: any = {};

			for (const field of this.fields) {
				if (this.formData[field.key] !== undefined) {
					data[field.key] = this.formData[field.key];
				}
			}

			if (this.data.id) {
				data.id = this.data.id;
			}

			const res = await this.submit(data);

			if (!res) throw new Error();

			this.showResponseType = "success";
		} catch (err) {
			this.state = FormState.Error;

			this.showResponseType = "error";
		}

		this.showResponse = true;
		this.formData = reduceToDotNotation(this.data);

		this.showResponseTimeout = setTimeout(
			() => {
				this.showResponse = false;
			},
			5000, // tslint:disable-line
		);
	}
}
</script>

<style lang="scss">
.sz-card-form {
	&__field:not(:last-of-type) {
		margin-bottom: 20px;
	}
	&__hidden {
		display: none;
	}
}
</style>
