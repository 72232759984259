<template>
	<div class="sz-field" :class="fieldClasses" @click="focus">
		<label v-if="label">{{ label }}</label>

		<div class="sz-field__content">
			<div class="sz-field__icon" v-if="icon">
				<v-icon>{{ icon }}</v-icon>
			</div>

			<div class="sz-field__input" ref="input">
				<slot />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import SzInput from "./Input.vue";

@Component
export default class SzField extends Vue {
	@Prop()
	label!: string;

	@Prop({ default: "above" })
	labelPlacement!: "above" | "left";

	@Prop({ default: true })
	fillInput!: boolean;

	@Prop()
	icon!: string;

	hasFocus = false;
	inputClasses: string[] = [];

	get fieldClasses() {
		return [`label-placement-${this.labelPlacement}`, this.fillInput ? `fill-input` : undefined, ...this.inputClasses];
	}

	get szInput(): SzInput | undefined {
		return this.szInputs.length > 0 ? this.szInputs[0] : undefined;
	}

	get szInputs(): SzInput[] {
		if (!this.$slots.default) return [];

		return this.$slots.default
			.filter((slot) => slot.componentInstance && "isSzInput" in slot.componentInstance)
			.map((slot) => slot.componentInstance as SzInput);
	}

	async mounted() {
		this.szInputs.forEach((input) => {
			const updateClasses = (...args: any[]) => {
				this.updateClasses(input, ...args);
			};

			(input as any).disableOutline = true;
			input.$on("focus", updateClasses);
			input.$on("blur", updateClasses);
			input.$on("input", updateClasses);
		});
	}

	focus() {
		if (!this.$slots.default) return;

		this.$slots.default.forEach((slot) => {
			if (slot && slot.componentInstance && (slot.componentInstance as any).focus) {
				(slot.componentInstance as any).focus();
			}
		});
	}

	updateClasses(input: SzInput, ..._args: any[]) {
		this.inputClasses = (input as any).modifierClasses;
	}
}
</script>

<style lang="scss">
.sz-field {
	display: flex;

	> label {
		color: rgba(0, 0, 0, 0.67);
		display: block;
		cursor: pointer;
	}

	&.label-placement-above {
		flex-direction: column;

		label {
			margin: 0 0 8px;
		}
	}

	&.label-placement-left {
		align-items: center;

		label {
			margin: 0 8px 0 0;
		}

		&.fill-input {
			.sz-field__content {
				flex: 1;
			}
		}
	}

	// * + label, label + * {
	// 	// margin-left: 0.6rem;
	// }

	select {
		background: none;
		border: none;
		display: block;
		width: 100%;
		height: 100%;
		font-size: 1rem;
	}

	&__content {
		display: flex;
		cursor: text;
	}

	&__icon {
		margin-right: 10px;
		cursor: pointer;
	}

	&__input {
		border-bottom: 1px solid rgba(0, 0, 0, 0.37);
		display: flex;
		flex: 1;

		> .sz-input,
		> input {
			border: none;
			// display: inline-block;
			flex: 1;
			font-size: 1rem;
			padding: 3px 5px;
			width: 100%;
		}

		> .sz-select {
			padding: 0;
		}

		input {
			&:focus {
				outline: none;
			}
		}
	}

	&.invalid {
		label {
			color: rgba(255, 0, 0, 0.67);
		}

		.sz-field__input {
			border-color: rgba(255, 0, 0, 0.37);
		}
	}

	&.valid {
		.sz-field__input {
			border-color: darkgreen;
		}
	}

	&.focused {
		.sz-field__input {
			border-color: blue;
		}
	}
}
</style>
