<template>
	<div class="oauth-dialog">
		<div v-if="confirming" class="confirming">
			<p class="info-text loading" v-if="!confirmed && !confirmedError">
				<sz-loading-state :title="$t('route.oauth.dialog.loadingConfirm', [client.name, organization.name])" />
			</p>
			<h2 v-else-if="confirmed">
				{{ $t("route.oauth.dialog.confirmed", [client.name, organization.name]) }}
			</h2>
			<p class="info-text error" v-else-if="confirmedError">
				{{ $t("route.oauth.dialog.failedToGiveAccessToClient", [client.name]) }}
			</p>
			<p class="info-text error" v-else>Unknown error</p>
		</div>

		<template v-else-if="client">
			<h1>
				<a :href="client.website" target="_blank" rel="noopener">{{ client.name }}</a>
				{{ $t("route.oauth.dialog.clientWantsToAccess") }}
			</h1>
			<h2>
				{{ $t("route.oauth.dialog.youAreLoggedInWithEmail") }}
				<strong>{{ user.email }}</strong>
			</h2>

			<div v-if="!organization" class="organization-select">
				<p>{{ $t("route.oauth.dialog.selectOrganizationToGiveAccessTo") }}</p>
				<ul class="organization-select__list">
					<li
						v-for="org in organizations"
						:key="org.id"
						:class="{ selected: organization === org }"
						@click="organization = org"
					>
						{{ org.name }}
					</li>
				</ul>
			</div>

			<template v-else>
				<div class="selected-organization">
					<p v-html="$t('route.oauth.dialog.thisWillGiveClientAccessTo', [client.name, organization.name])" />
					<h3 v-html="$t('route.oauth.dialog.beSureYouTrustClient', [client.name])" />
				</div>

				<div class="submit-footer">
					<!-- <v-btn text>
						{{ $t("route.oauth.dialog.cancel") }}
					</v-btn> -->
					<v-btn color="primary" @click="confirm">
						{{ $t("route.oauth.dialog.giveAccess") }}
					</v-btn>
				</div>
			</template>
		</template>

		<p class="info-text error" v-else-if="loadingClientError">{{ $t("route.oauth.dialog.loadingClientError") }}</p>
		<p class="info-text loading" v-else>
			<sz-loading-state :title="$t('route.oauth.dialog.loading')" />
		</p>
	</div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

import http from "@/http";

@Component
export default class OAuthDialog extends Vue {
	@Getter user!: any;
	@Getter organizations!: any[];

	// Query parameters
	qClientId?: string = undefined;
	qRedirectUri?: string = undefined;
	qResponseType?: string = undefined;
	qState?: string = undefined;

	client: any = null;
	loadingClientError = false;
	organization: any = null;

	confirming = false;
	confirmed = false;
	confirmedError = false;

	async created() {
		if (this.organizations.length === 1) this.organization = this.organizations[0];
		this.parseQuery();
		await this.loadClient();
	}

	parseQuery() {
		const { client_id, redirect_uri, response_type, state } = this.$route.query;
		this.qClientId = client_id as string;
		this.qRedirectUri = redirect_uri as string;
		this.qResponseType = response_type as string;
		this.qState = state as string;
	}

	async loadClient() {
		try {
			const res = await http.get(`/oauth/client/${this.qClientId}`);
			if (res.status !== 200) throw new Error("Failed to load client");
			this.client = res.data;
		} catch {
			this.loadingClientError = true;
		}
	}

	async confirm() {
		// Ensure we're not already confirming or confirmed.
		if (this.confirming || this.confirmed) return;
		this.confirming = true;

		try {
			// Request authorization.
			const res = await http.post(`/oauth/authorize`, {
				clientId: this.qClientId,
				redirectUri: this.qRedirectUri,
				responseType: this.qResponseType,
				state: this.qState,
				organizationId: this.organization.id,
			});

			// Validate response.
			if (res.status !== 201) throw new Error("Failed to authorize");
			if (!res.data || !(typeof res.data.redirectUri === "string") || !(typeof res.data.code === "string"))
				throw new Error("Invalid response");

			// Update UI.
			this.confirmed = true;

			// Build URL.
			const redirectUrl = new URL(res.data.redirectUri);
			redirectUrl.searchParams.set("code", res.data.code);
			if (res.data.state) redirectUrl.searchParams.set("state", res.data.state);

			// Redirect.
			setTimeout(() => {
				window.location.href = redirectUrl.href;
			}, 500);
		} catch {
			// Update UI with error message.
			this.confirmedError = true;
		}
	}
}
</script>

<style lang="scss" scoped>
.oauth-dialog {
	background: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	margin: 2rem auto;
	max-width: 40rem;
	width: calc(100% - 4rem);
	// max-height: calc(100vh - 4rem);

	p.info-text {
		margin: 0;
		padding: 1rem;
		text-align: center;
	}

	h1,
	h2 {
		padding: 1rem;
		text-align: center;
	}

	h1 {
		background: #019939;
		color: white;
		font-size: 1.8rem;

		a {
			color: white;
		}
	}

	h2 {
		background: #eee;
		font-size: 1.2rem;
		font-weight: normal;
		padding: 0.5rem 1rem;
	}

	.organization-select {
		padding: 2rem;

		&__list {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				background: #eee;
				padding: 0.5rem 1rem;
				border-radius: 0.5rem;
				cursor: pointer;
				transition: background 0.2s ease-in-out;

				&.selected {
					background: #ddd;
				}

				&:hover {
					background: #ccc;
				}
			}

			li + li {
				margin-top: 0.5rem;
			}
		}
	}

	.selected-organization {
		padding: 2rem;

		p {
			margin: 0;
		}

		h3 {
			margin-top: 1rem;
		}
	}

	.submit-footer {
		margin-top: -1rem;
		padding: 1rem;
		display: flex;
		justify-content: flex-end;
	}

	.confirming {
		h2 {
			font-weight: bold;
			padding: 2rem;
		}
	}
}
</style>
