<template>
	<v-container fill-height class="layout-fluid">
		<v-layout column>
			<slot />
		</v-layout>
	</v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class LayoutFluid extends Vue {}
</script>

<style lang="scss">
.container.layout-fluid {
	max-width: 100%;
}
</style>
