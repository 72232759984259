import { MutationTree } from "vuex";

import { ServicesState } from "./types";

// tslint:disable:naming-convention
export const mutations: MutationTree<ServicesState> = {
	DELETE_SERVICE(state, service) {
		const idx = state.services.service.findIndex(s => s.id === service.id);

		if (idx === -1) return;

		state.services.service.splice(idx, 1);
	},

	UPDATE_SERVICE(state, service) {
		state.services.service.push(service);
	},

	UPDATE_SERVICES(state, services: any[]) {
		services.forEach(service => {
			mutations.UPDATE_SERVICE(state, service);
		});
	},
};
