<template>
	<sz-dialog-card-form
		v-model="isOpen"
		:max-width="400"
		:data="_data"
		:submit="submit"
		:title="$t(`route.client.entityDetails.members.users.addDialog.title`)"
		:fields="[
			{ key: 'email', label: $t(`generic.email`) },
			// {
			// 	key: 'role',
			// 	label: $t(`generic.role`),
			// 	type: 'select',
			// 	options: [
			// 		{ value: 'Owner', text: $t('generic.roles.owner') },
			// 		{ value: 'Administrator', text: $t('generic.roles.administrator') },
			// 		{ value: 'Moderator', text: $t('generic.roles.moderator') },
			// 	],
			// },
		]"
	/>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import clone from "lodash/clone";

import { EntityInterface } from "@scrinz/dtos";

@Component
export default class UsersAddDialog extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ default: false })
	value!: boolean;

	@Prop({ type: [Object] })
	data!: any;

	protected _data: any | null = null;

	get isOpen() {
		return this.value;
	}

	set isOpen(value: boolean) {
		this.$emit("input", value);
	}

	@Watch("data", { immediate: true })
	onDataChange() {
		this._data = clone(this.data);
	}

	async submit(data: any) {
		await this.$store.dispatch("addEntityUserRelation", {
			entity: this.entity,
			members: [data],
		});

		this.isOpen = false;
		this.onDataChange();
	}
}
</script>
