<template>
	<div v-if="entity">
		<h2 class="title">Edit master for slot "{{ slotId }}"</h2>

		<templates-editor v-if="isSetup" :entity="entity" :slotId="slotId" :slotWidth="width" :slotHeight="height" />
	</div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import http from "@/http";

import AssetUploader from "@/components/AssetUploader";
import TemplatesEditor from "@/components/TemplatesEditor";

@Component<AdminMaster>({
	title: (vm) => `Master "${vm.slotId}"`,
	components: { AssetUploader, TemplatesEditor },
})
export default class AdminMaster extends Vue {
	@Prop({ required: true })
	slotId!: string;

	// asset: string = "";
	// contentId: number|undefined;

	entity: any = null;

	width: number | undefined;
	height: number | undefined;
	isSetup = false;

	@Watch("slotId", { immediate: true })
	async fetch() {
		// this.asset = "";
		// this.contentId = undefined;
		this.isSetup = false;
		this.entity = null;
		this.width = undefined;
		this.height = undefined;

		// tslint:disable:no-magic-numbers
		if (this.slotId === "adsSmall") {
			this.width = 480;
			this.height = 175;
		} else if (this.slotId === "adsLarge") {
			this.width = 590;
			this.height = 630;
		} else if (this.slotId === "template") {
			this.width = 480;
			this.height = 895;
		} else {
			throw new Error("Not a recognized slot id");
		}
		// tslint:enable:no-magic-numbers

		const master = await http.get("admin/master");

		this.entity = master.data;
		this.isSetup = true;
	}
}
</script>

<style lang="scss" scoped>
h2 {
	margin-bottom: 2rem;
}
</style>
