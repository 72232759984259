<template>
	<v-dialog v-model="isOpen" :max-width="600">
		<sz-content-card :title="$t('route.client.entityDetails.settings.housekeeping.deleteDialog.title', entity)">
			<v-alert type="warning" :value="true">
				<strong>{{ $t(`route.client.entityDetails.settings.housekeeping.deleteDialog.alert.strong`) }}</strong>
				{{ $t(`route.client.entityDetails.settings.housekeeping.deleteDialog.alert.text`) }}
			</v-alert>

			<v-card-text>
				<div v-if="hasChildren">
					<p>{{ $t(`route.client.entityDetails.settings.housekeeping.deleteDialog.children.intro`) }}</p>

					<p>
						<v-select
							solo
							hide-details
							v-model="withChildren"
							:label="$t(`route.client.entityDetails.settings.housekeeping.deleteDialog.children.select.label`)"
							:items="[
								{
									value: 'delete',
									text: $t(
										`route.client.entityDetails.settings.housekeeping.deleteDialog.children.select.options.delete`,
									),
								},
								{
									value: 'move',
									text: $t(
										`route.client.entityDetails.settings.housekeeping.deleteDialog.children.select.options.move`,
									),
								},
							]"
						/>
					</p>

					<p v-if="withChildren === 'delete'">
						<v-checkbox
							color="error"
							hide-details
							:label="$t(`route.client.entityDetails.settings.housekeeping.deleteDialog.children.delete.confirmLabel`)"
							v-model="confirmDeleteChildren"
						/>
					</p>

					<div v-if="withChildren === 'move'">
						<p v-if="moveToEntities.length > 0">
							<v-select
								solo
								hide-details
								v-model="moveTo"
								:label="$t(`route.client.entityDetails.settings.housekeeping.deleteDialog.children.move.selectLabel`)"
								:items="moveToEntities"
								item-text="name"
								item-value="id"
							/>
						</p>

						<p v-else>
							{{ $t(`route.client.entityDetails.settings.housekeeping.deleteDialog.children.move.noValidTarget`) }}
						</p>
					</div>
				</div>

				<p>
					{{ $t(`route.client.entityDetails.settings.housekeeping.deleteDialog.instructions.preInput`) }}
					<sz-input class="caution" :value="entity.name" readonly plain inline-block v-sz-autosize v-sz-click-select />
					{{ $t(`route.client.entityDetails.settings.housekeeping.deleteDialog.instructions.postInput`) }}
				</p>

				<p>
					<v-text-field
						solo
						hide-details
						:label="$t(`route.client.entityDetails.settings.housekeeping.deleteDialog.confirmationInput.label`)"
						v-model="confirmDelete"
					/>
				</p>
			</v-card-text>

			<template slot="actions-left">
				<v-btn text @click="isOpen = false">{{
					$t(`route.client.entityDetails.settings.housekeeping.deleteDialog.cancelButtonText`)
				}}</v-btn>
			</template>

			<template slot="actions-right">
				<v-btn color="error" @click="deleteEntity" :disabled="!confirmationIsValid">{{
					$t(`route.client.entityDetails.settings.housekeeping.deleteDialog.deleteButtonText`)
				}}</v-btn>
			</template>
		</sz-content-card>
	</v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import { entityCanHaveChildren } from "@scrinz/helpers";

@Component
export default class EntityDeleteDialog extends Vue {
	@Prop({ default: false })
	value!: boolean;

	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	confirmDelete = "";
	withChildren: "delete" | "move" | null = null;
	confirmDeleteChildren = false;
	moveTo: string | null = null;

	get isOpen() {
		return this.value;
	}

	set isOpen(value: boolean) {
		this.confirmDelete = "";
		this.withChildren = null;
		this.confirmDeleteChildren = false;
		this.moveTo = null;

		this.$emit("input", value);
	}

	get confirmationIsValid() {
		if (this.entity.name !== this.confirmDelete) return false;

		if (this.hasChildren) {
			if (this.withChildren === "delete" && this.confirmDeleteChildren) {
				return true;
			}
			if (this.withChildren === "move" && this.moveTo !== null) return true;

			return false;
		}

		return true;
	}

	get children(): EntityInterface[] {
		return this.$store.getters.getChildrenOfEntity(this.entity);
	}

	get hasChildren() {
		return this.children.length > 0;
	}

	get moveToEntities() {
		const childIds = this.children.map((e) => e.id);

		return this.$store.getters.entitiesInContext
			.filter((e: EntityInterface) => e.id !== this.entity.id)
			.filter((e: EntityInterface) => !childIds.includes(e.id))
			.filter(entityCanHaveChildren);
	}

	async deleteEntity() {
		if (!this.confirmationIsValid) return;

		await this.$store.dispatch("deleteEntity", {
			entity: this.entity,
			withChildren: this.withChildren,
			moveTo: this.moveTo,
		});

		this.$router.push("/entities");
	}
}
</script>

<style lang="scss" scoped>
.caution {
	color: orangered;
}

.sz-input.caution {
	@extend .caution;
}

input.caution,
tt.caution {
	background: rgba(0, 0, 0, 0.09);
	border: 1px solid rgba(0, 0, 0, 0.67);
	display: inline;
	font-family: monospace;
	// line-height: normal;
	padding: 0 3px;
}
</style>
