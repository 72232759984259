import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

import { EntitiesState } from "./types";
export * from "./types";

const state: EntitiesState = {
	entities: [],
	entityContext: null,
};

export const EntitiesStore: Module<EntitiesState, RootState> = {
	actions,
	getters,
	mutations,
	state,
};

export default EntitiesStore;
