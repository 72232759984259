import { ActionTree } from "vuex";

import http from "@/http";
import { RootState } from "@/store";
import { UsersState } from "./types";

export const actions: ActionTree<UsersState, RootState> = {
	async fetchUsers({ commit }) {
		const res = await http.get(`users`);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to load users.`);
		}

		commit("UPDATE_USERS", res.data);
	},

	// async createUser({ commit, getters }, note) {
	// 	if (!note.ownerId) {
	// 		note.ownerId = getters.user.id;
	// 	}

	// 	const res = await http.post(`/content/${note.entityId}/note`, note);

	// 	if (!res || res.status !== 201) {
	// 		throw new Error(`Failed to create note.`);
	// 	}

	// 	// Add newly created flag.
	// 	res.data.newlyCreated = true;

	// 	commit("ADD_USER", res.data);
	// },

	async updateUser({ commit }, user) {
		const res = await http.put(`/users/${user.id}`, user);

		if (!res || res.status !== 200) {
			throw new Error(`Failed to update user with id "${user.id}".`);
		}

		// Add newly updated flag.
		res.data.newlyUpdated = true;

		commit("UPDATE_USER", res.data);

		return res.data;
	},

	async generateNewPasswordForUser({}, userId) {
		const res = await http.post(`/users/${userId}/generateNewPassword`);

		if (!res || res.status !== 201) {
			throw new Error(`Failed to generate new password for user with id "${userId}".`);
		}

		return res.data;
	},

	// async deleteUser({ commit }, note) {
	// 	const res = await http.delete(`/content/${note.entityId}/note/${note.id}`, note);

	// 	if (!res || res.status !== 200) {
	// 		throw new Error(`Failed to delete note with id "${note.id}".`);
	// 	}

	// 	commit("DELETE_USER", note);
	// },
};
