import { OptionsInput as FCOptions } from "@fullcalendar/core";

type PropertyType<T, K extends keyof T> = T[K];

type HandlerTypes = "select" | "eventClick" | "eventDrop" | "eventMouseEnter" | "eventMouseLeave" | "eventRender";
type HandlerInfoArgType<T extends HandlerTypes> = Parameters<PropertyType<Required<FCOptions>, T>>[0];

export type EventMouseEnterHandlerInfo = HandlerInfoArgType<"eventMouseEnter">;
export type EventMouseLeaveHandlerInfo = HandlerInfoArgType<"eventMouseLeave">;
export type DateSelectHandlerInfo = HandlerInfoArgType<"select">;
export type EventClickHandlerInfo = HandlerInfoArgType<"eventClick">;
export type EventDropHandlerInfo = HandlerInfoArgType<"eventDrop">;
export type EventRenderHandlerInfo = HandlerInfoArgType<"eventRender">;

export enum CalendarNavigateMap {
	prev = "prev",
	previous = "prev",
	next = "next",
	today = "today",
}

export type CalendarNavigateType = keyof typeof CalendarNavigateMap;

export enum CalendarViewMap {
	week = "dayGridWeek",
	month = "dayGridMonth",
}

export type CalendarViewType = keyof typeof CalendarViewMap;
