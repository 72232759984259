<template>
	<sz-dialog-card-form
		v-if="$can('admin')"
		v-model="isOpen"
		:title="$t('route.entities.newEntity')"
		:data="entity"
		:submit="submit"
		:max-width="400"
		:fields="[
			{ key: 'name', label: $t('generic.name') },
			{
				key: 'type',
				label: $t('generic.type'),
				type: 'select',
				options: [
					{ value: types.Organization, text: $t('route.client.entities.types.Organization') },
					{ value: types.Group, text: $t('route.client.entities.types.Group') },
					{ value: types.Display, text: $t('route.client.entities.types.Display') },
				],
			},
		]"
	/>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { EntityInterface, EntityTypeEnum } from "@scrinz/dtos";

const EMPTY_ENTITY = { name: "", type: "" };

@Component
export default class EntityAddDialog extends Vue {
	types = EntityTypeEnum;

	@Prop({ default: false })
	value!: boolean;

	@Prop({ required: true, type: [EntityInterface, Object] })
	parentEntity!: EntityInterface;

	entity = EMPTY_ENTITY;

	get isOpen() {
		return this.value;
	}

	set isOpen(value: boolean) {
		this.$emit("input", value);
	}

	async submit(data: any) {
		await this.$store.dispatch("createEntity", {
			name: data.name,
			type: data.type,
			parentId: this.parentEntity.id,
		});

		this.entity = EMPTY_ENTITY;
		this.isOpen = false;
	}
}
</script>
