import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { PreferencesState } from "./types";
export * from "./types";

const state: PreferencesState = {
	locale: null,
};

export const PreferencesStore: Module<PreferencesState, RootState> = {
	actions,
	getters,
	mutations,
	state,
};

export default PreferencesStore;
