import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { ContentState } from "./types";

export const state: ContentState = {
	slots: {},
};

export const ContentStore: Module<ContentState, RootState> = {
	actions,
	getters,
	mutations,
	state,
};

export default ContentStore;
