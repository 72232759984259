export enum FormState {
	Uninitialized,
	Initialized,
	Submitting,
	Success,
	Error,
}

export interface FormField {
	$can?: string;
	key: string;
	label: string;
	type: string;
	options?: string[] | { value: number | string; text: string }[];
	icon?: string;
	palette?: string[][];
}
