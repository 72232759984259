import { PluginObject, VNode, VNodeDirective } from "vue";

const DATASET_KEY = "szClickSelect";

const eventTypes: { [ el: string]: string } = {};
const eventListeners: { [el: string]: (...args: any[]) => any } = {};
let uid = 0;

// tslint:disable:strict-type-predicates no-unbound-method
function selectText(el: HTMLElement) {
	if (!el) return;

	if (el.nodeName.match(/^(INPUT|TEXTAREA)$/i)) {
		const disabled = (el as HTMLInputElement).disabled;

		if (disabled) (el as any).disabled = false;
		el.focus();
		(el as HTMLInputElement).select();
		if (disabled) (el as any).disabled = true;
	} else if ((document.body as any).createTextRange === "function") {
		// IE or Opera <10.5
		const range = (document.body as any).createTextRange();

		range.moveToElementText(el);
		range.select();
	} else if (window.getSelection) {
		const selection = window.getSelection();

		if (!selection) return;

		if (typeof selection.setBaseAndExtent === "function") {
			// Safari
			selection.setBaseAndExtent(el, 0, el, 1);
		} else if (
			typeof selection.addRange === "function"
			&& typeof selection.removeAllRanges === "function"
			&& typeof document.createRange === "function"
		) {
			// Mozilla or Opera 10.5+
			const range = document.createRange();

			range.selectNodeContents(el);
			selection.removeAllRanges();
			selection.addRange(range);
		}
	}

	return el;
}
// tslint:enable:strict-type-predicates no-unbound-method

function pickElement(el: HTMLElement, vnode: VNode) {
	const instance = vnode.componentInstance as any;

	if (instance && instance.isSzInput && instance.inputField) {
		return instance.inputField as HTMLElement;
	}

	return el;
}

export const SzClickSelectDirective: PluginObject<any> = {
	install: V => {
		V.directive("sz-click-select", {
			bind: (el: HTMLElement, binding: VNodeDirective, vnode: VNode) => {
				const type = binding.arg || "click";
				const id = `${uid += 1}`;
				const handler = (e: Event) => {
					e.preventDefault();
					e.stopPropagation();

					selectText(pickElement(el, vnode));

					return false;
				};

				el.dataset[DATASET_KEY] = id;
				eventTypes[id] = type;
				eventListeners[id] = handler;
				el.addEventListener(type, handler);
			},

			unbind: el => {
				const id = el.dataset[DATASET_KEY];

				if (!id || !eventListeners[id] || !eventTypes[id]) return;

				el.removeEventListener(eventTypes[id], eventListeners[id]);
			},
		});
	},
};

export default SzClickSelectDirective;
