import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { AppState } from "./types";

// tslint:disable:no-shadowed-variable
export const getters: GetterTree<AppState, RootState> = {
	appDrawer(state) {
		return state.app.drawer.open;
	},
};
