<template>
	<div class="asset-uploader-cropper">
		<div
			class="cropper-wrapper"
			:ref="`cropper-wrapper-${id}`"
			@mousedown="startOffsetDrag"
			@mousemove="calculateOffset"
			@mouseup="stopOffsetDrag"
			@mouseout="stopOffsetDrag"
		>
			<div
				class="cropper-offset"
				:ref="`cropper-offset-${id}`"
				:style="{
					top: `${localOffsetY}px`,
					left: `${localOffsetX}px`,
				}"
			>
				<div
					class="cropper-scale"
					:ref="`cropper-scale-${id}`"
					:style="{
						transform: `scale(${localScale})`,
					}"
				>
					<slot name="default" />
				</div>
			</div>
		</div>

		<canvas
			class="cropper-canvas"
			:ref="`cropper-canvas-${id}`"
			:style="{
				width: `${width}px`,
				height: `${height}px`,
			}"
		></canvas>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { throttle } from "lodash";

@Component
export default class AssetUploaderCropper extends Vue {
	@Prop({ required: true })
	id!: string;

	@Prop({ required: true, type: [Object] })
	asset!: any;

	@Prop({ required: true })
	width!: number;

	@Prop({ required: true })
	height!: number;

	dragging = false;
	dragOffsetX!: number;
	dragOffsetY!: number;
	dragThrottle!: Date;

	localOffsetX: number = 0;
	localOffsetY: number = 0;
	localScale: number = 1;
	localStateThrottle!: (() => void) & { cancel(): void };

	@Watch("asset", { deep: true, immediate: true })
	resetLocalState() {
		this.localStateThrottle = throttle(() => {
			if (this.localStateThrottle) this.localStateThrottle.cancel();
			this.localOffsetX = this.asset.offsetX;
			this.localOffsetY = this.asset.offsetY;
			this.localScale = this.asset.scale;
		}, 100);

		this.localStateThrottle();
	}

	startOffsetDrag(event: MouseEvent) {
		if (event.button !== 0) return;
		event.preventDefault();

		this.localOffsetX = this.localOffsetX || this.asset.offsetX;
		this.localOffsetY = this.localOffsetY || this.asset.offsetY;

		this.dragOffsetX = event.screenX;
		this.dragOffsetY = event.screenY;
		this.dragging = true;
	}

	stopOffsetDrag(event: MouseEvent) {
		if (!this.dragging || event.button !== 0) return;
		event.preventDefault();

		this.dragging = false;
		this.asset.offsetX = this.localOffsetX;
		this.asset.offsetY = this.localOffsetY;
	}

	calculateOffset(event: MouseEvent) {
		if (!this.dragging) return;
		event.preventDefault();

		const now = new Date();

		if (this.dragThrottle && now.valueOf() < this.dragThrottle.valueOf() + 10) {
			return;
		}

		this.dragThrottle = now;

		this.localOffsetX = this.localOffsetX + (event.screenX - this.dragOffsetX);
		this.localOffsetY = this.localOffsetY + (event.screenY - this.dragOffsetY);

		this.dragOffsetX = event.screenX;
		this.dragOffsetY = event.screenY;
	}

	async getCropped() {
		return new Promise<Blob | null>((resolve) => {
			const container = this.$refs[`cropper-scale-${this.id}`] as HTMLElement;

			if (!container || (container && !container.children)) return;

			if (container.children.length > 1) {
				throw new Error("AssetUploaderCropper can only have one slotted child");
			}

			const containerCanvas = container.children[0] as HTMLCanvasElement;
			// const canvas = this.$refs[`cropper-canvas-${this.id}`] as HTMLCanvasElement;
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

			canvas.width = this.width;
			canvas.height = this.height;

			ctx.drawImage(
				containerCanvas,
				0,
				0,
				containerCanvas.width,
				containerCanvas.height,
				this.localOffsetX,
				this.localOffsetY,
				containerCanvas.width * this.localScale,
				containerCanvas.height * this.localScale,
			);

			// container.style.display = "none";

			// return ctx.getImageData(0, 0, canvas.width, canvas.height);
			canvas.toBlob(resolve);
		});
	}
}
</script>

<style lang="scss" scoped>
.asset-uploader-cropper {
	height: 100%;
	width: 100%;

	.cropper-wrapper {
		cursor: move;
		position: absolute;
		height: 100%;
		width: 100%;
	}

	.cropper-offset,
	.cropper-scale {
		position: absolute;
		top: 0;
		left: 0;
	}

	.cropper-scale {
		transform-origin: 0 0;
	}

	.cropper-scale > * {
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	}

	.cropper-canvas {
		background: rgba(0, 0, 0, 0.17);
	}
}
</style>
