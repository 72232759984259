<template>
	<div class="sz-option-group">
		<div class="sz-option-group--label">{{ label }}</div>
		<div class="sz-option-group--options">
			<slot />
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue";
	import { Component, Prop } from "vue-property-decorator";

	@Component
	export default class SzOptionGroup extends Vue {
		@Prop()
		label!: string;
	}
</script>

<style lang="scss">
	.sz-option-group {
		&:not(:first-child) {
			border-top: 1px solid lightgray;
		}

		&--label {
			background: rgba(0, 0, 0, 0.37);
			cursor: default;
			color: white;
			padding: 12px 5px;
			font-weight: bold;
		}

		&--options {
			.sz-option {
				padding-left: 25px;
			}
		}
	}
</style>
