import { GetterTree } from "vuex";

import { RootState } from "@/store/types";
import { StatusState } from "./types";

export const getters: GetterTree<StatusState, RootState> = {
	hasConnectivity(state) {
		return state.isOnline && state.hasConnectivity;
	},

	isApiInstalled(state) {
		return state.isApiInstalled;
	},

	isOnline(state) {
		return state.isOnline;
	},

	isTestingConnectivity(state) {
		return state.isTestingConnectivity;
	},
};
