<template>
	<sz-content-card
		class="transit-times-stop-selector"
		:collapsible="true"
		:collapsed="true"
		:title="$t(`comp.transitTimesStopSelector.title`)"
		:hide-actions="isEmpty"
	>
		<add-dialog v-model="showAddDialog" :entity="entity" :stop="addStop" />

		<empty-state v-if="isEmpty" :add-function="openAddStopDialog" />
		<selected-stops-list v-else :stops="stops" />

		<template slot="actions-right">
			<v-btn color="primary" @click="openAddStopDialog">{{ $t("comp.transitTimesStopSelector.addButtonText") }}</v-btn>
		</template>
	</sz-content-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import AddDialog from "./AddDialog.vue";
import EmptyState from "./EmptyState.vue";
import SelectedStopsList from "./SelectedStopsList.vue";

@Component({
	components: {
		AddDialog,
		EmptyState,
		SelectedStopsList,
	},
})
export default class TransitTimesStopSelectorCard extends Vue {
	@Prop({ required: true, type: [Object] })
	entity!: any;

	showAddDialog = false;
	addStop: any = null;

	get EMPTY_STOP(): any {
		return {
			entityId: this.entity.id,
			stopId: "",
			label: "",
		};
	}

	get stops() {
		return this.$store.getters.transitStopsFor(this.entity);
	}

	get isEmpty() {
		return this.stops.length === 0;
	}

	async mounted() {
		await this.fetchTransitStops();
	}

	async fetchTransitStops() {
		await this.$store.dispatch("fetchTransitStops", this.entity);
	}

	openAddStopDialog() {
		this.addStop = this.EMPTY_STOP;
		this.showAddDialog = true;
	}
}
</script>
