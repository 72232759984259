import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import Vue from "vue";

import { UserDto } from "@scrinz/dtos";
import store from "@/store";

Vue.use(abilitiesPlugin);

function abilitiesPlugin(vue: typeof Vue) {
	vue.mixin({
		methods: {
			$can(...args) {
				return store.getters.ability.can(...args);
			},

			$cannot(...args) {
				return store.getters.ability.cannot(...args);
			},
		},
	});
}

export function defineAbilitiesFor(user: UserDto) {
	const { can, rules, build } = new AbilityBuilder(createMongoAbility);

	if (user.admin) {
		can("admin", "all");
		can("create", ["Entity"]);
	}

	can("update", ["Entity"]);
	// can("delete", ["Entity"], {  });
	can("read", "all");

	// return new Ability(rules);
	return build();
}

declare module "vue/types/vue" {
	interface Vue {
		$can(...args: any[]): boolean;
		$cannot(...args: any[]): boolean;
	}
}
