<template>
	<div
		class="note-board-note"
		:class="{
			active: isActive,
			expired: isExpired,
			inherited: isInherited,
			scheduled: isScheduled,
			'newly-created': note.newlyCreated,
			'newly-updated': note.newlyUpdated,
		}"
	>
		<div class="header">
			<div class="meta">
				<span v-if="isUnpublished" class="state warning--text">
					{{ $t("comp.noteBoard.Unpublished") }}
				</span>

				<span v-else-if="isActive" class="state success--text">
					{{ $t("comp.noteBoard.Active") }}
				</span>

				<span v-else-if="isScheduled" class="state info--text">
					{{ $t("comp.noteBoard.Scheduled") }}
				</span>

				<span v-else-if="isExpired" class="state error--text">
					{{ $t("comp.noteBoard.Expired") }}
				</span>

				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<span v-on="on" class="date">
							{{ note.start | moment("D. MMMM") }}
						</span>
					</template>

					{{ note.start | moment("D. MMMM YYYY") }}
				</v-tooltip>

				<span v-if="showEndDate">&nbsp;-&nbsp;</span>

				<v-tooltip top v-if="showEndDate">
					<template v-slot:activator="{ on }">
						<span v-on="on">
							{{ displayEndDate | moment("D. MMMM") }}
						</span>
					</template>

					{{ displayEndDate | moment("D. MMMM YYYY") }}
				</v-tooltip>

				<span class="statuses">
					<v-tooltip top v-if="note.sticky">
						<template v-slot:activator="{ on }">
							<span v-on="on" class="pinned">
								<v-icon>lock_outline</v-icon>
							</span>
						</template>

						{{ $t("comp.noteBoard.pinnedToTop") }}
					</v-tooltip>

					<span class="unapproved center-text" v-if="note.state === 'Draft'">
						<v-icon :color="'warning'">block</v-icon>
						<span>{{ $t("generic.state.draft") }}</span>
					</span>
					<span class="unapproved center-text" v-else-if="note.state === 'PendingApproval'">
						<v-icon :color="'warning'">block</v-icon>
						<span>{{ $t("generic.state.pendingApproval") }}</span>
					</span>
					<span class="unapproved center-text" v-else-if="note.state === 'Rejected'">
						<v-icon :color="'error'">block</v-icon>
						<span>{{ $t("generic.state.notApproved") }}</span>
					</span>
					<span class="approved center-text" v-else-if="note.state === 'Published'">
						<v-icon :color="'success'">done</v-icon>
						<span>{{ $t("generic.state.published") }}</span>
					</span>
				</span>
			</div>
		</div>

		<div>
			<div class="actions" v-if="!isInherited">
				<v-menu bottom left>
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on">
							<v-icon>menu</v-icon>
						</v-btn>
					</template>

					<v-list>
						<v-list-item @click="edit(note)">
							<v-list-item-content>
								<v-list-item-title>{{ $t("comp.noteBoard.editNote") }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-avatar>
								<v-icon>edit</v-icon>
							</v-list-item-avatar>
						</v-list-item>

						<v-list-item v-if="isUnpublished" @click="toggleApproval(note)">
							<v-list-item-content>
								<v-list-item-title>{{ $t("comp.noteBoard.approveNote") }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-avatar>
								<v-icon>done</v-icon>
							</v-list-item-avatar>
						</v-list-item>

						<v-list-item v-else @click="toggleApproval(note)">
							<v-list-item-content>
								<v-list-item-title>{{ $t("comp.noteBoard.rejectNote") }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-avatar>
								<v-icon>block</v-icon>
							</v-list-item-avatar>
						</v-list-item>

						<v-divider />

						<v-list-item @click="deleteNote(note)">
							<v-list-item-content>
								<v-list-item-title>{{ $t("comp.noteBoard.deleteNote") }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-avatar>
								<v-icon>delete_forever</v-icon>
							</v-list-item-avatar>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>

			<span class="actions inherited-info" v-if="isInherited">
				{{ $t("generic.inheritedFrom") }}
				<router-link :to="`/entities/${note.entityId}/notes`">{{
					$store.getters.getEntity(note.entityId).name
				}}</router-link>
			</span>

			<div class="qr-code" v-if="note.qrContent">
				<sd-qr-code :qr-content="note.qrContent" />
			</div>

			<div class="content" v-html="note.text" />

			<div class="statuses">
				<NoteStatusPill v-if="isUnpublished" :label="$t('comp.noteBoard.Unpublished')" color="red" />
				<!-- <NoteStatusPill v-else-if="isActive" :label="$t('comp.noteBoard.Active')" color="blue" /> -->
				<!-- <NoteStatusPill v-if="isScheduled" :label="$t('comp.noteBoard.Scheduled')" color="orange" /> -->
				<NoteStatusPill v-if="isExpired" :label="$t('comp.noteBoard.Expired')" color="red" />
				<!-- <NoteStatusPill v-if="!isExpired" :color="isScheduled ? 'blue' : 'green'">
					<span v-if="isActive">
						<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10.6673 1L4.25065 7.41667L1.33398 4.5"
								stroke="#29634D"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						{{ $t("comp.noteBoard.Active") }}
					</span>
					<span v-else-if="isScheduled">{{ $t("comp.noteBoard.Scheduled") }}:</span>
					<span
						>({{ note.start | moment("D. MMMM") }}<span v-if="note.end"> - {{ note.end | moment("D. MMMM") }}</span
						>)</span
					>
				</NoteStatusPill> -->

				<NoteStatusPill v-if="isScheduled" :label="$t('comp.noteBoard.Scheduled')" color="orange" />
				<NoteStatusPill v-else-if="!isUnpublished && isActive" color="green">
					<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M10.6673 1L4.25065 7.41667L1.33398 4.5"
							stroke="#29634D"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					{{ $t("comp.noteBoard.Active") }}
				</NoteStatusPill>
				<NoteStatusPill :color="'blue'"
					>{{ note.start | moment("D. MMMM") }}<span v-if="note.end"> - {{ note.end | moment("D. MMMM") }}</span>
				</NoteStatusPill>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import { SdNote, SdQrCode } from "@scrinz/components";
import { EntityInterface } from "@scrinz/dtos";
import { EditFunction } from "./interfaces";
import NoteStatusPill from "./NoteStatusPill.vue";

@Component({
	components: {
		SdNote,
		SdQrCode,
		NoteStatusPill,
	},
})
export default class NoteBoardNote extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ required: true })
	note!: any;

	@Prop({ required: true, type: [Function] })
	edit!: EditFunction;

	@Prop({ default: 0 })
	index!: number;

	@Prop({ default: 0 })
	listLength!: number;

	now = new Date();

	get start(): Date {
		return this.note.start;
	}

	get end(): Date {
		return this.note.end;
	}

	get isUnpublished(): boolean {
		return this.note.state !== "Published";
	}

	get isActive(): boolean {
		return this.note.start < this.now && (this.note.end > this.now || !this.note.end);
	}

	get isScheduled(): boolean {
		return this.note.start > this.now;
	}

	get isExpired(): boolean {
		return this.note.end && this.note.end < this.now;
	}

	get isInherited() {
		return this.note.entityId !== this.entity.id;
	}

	get showEndDate() {
		if (!this.end) return false;

		const test = new Date(this.end);

		test.setDate(this.end.getDate() - 1);

		if (
			this.start.getFullYear() === test.getFullYear() &&
			this.start.getMonth() === test.getMonth() &&
			this.start.getDate() === test.getDate() &&
			test.getHours() === 0 &&
			test.getMinutes() === 0
		) {
			return false;
		}

		return this.end;
	}

	get displayEndDate() {
		const end = this.end;

		if (end) {
			if (end.getHours() === 0 && end.getMinutes() === 0) {
				end.setDate(end.getDate() - 1);
			}
		}

		return end;
	}

	async toggleApproval(note: any) {
		note.state = note.state === "Published" ? "Rejected" : "Published";
		await this.$store.dispatch("updateNote", note);
	}

	async deleteNote(note: any) {
		const confirmed = await this.$confirm(this.$t(`comp.noteBoard.confirmDelete.text`), {
			color: "error",
			title: this.$t(`comp.noteBoard.confirmDelete.title`),
		});

		if (!confirmed) return;

		await this.$store.dispatch("deleteNote", note);
	}
}
</script>

<style lang="scss" scoped>
.note-board-note {
	border-radius: 30px;
	border: 1px solid #ebebeb;
	background: #fff;
	padding: 30px 40px;
	box-shadow: var(--shadow);

	&.expired {
		color: rgb(60, 60, 60);
		filter: grayscale(0.5);
		opacity: 0.5;
	}

	&.inherited {
		background: rgba(112, 194, 194, 0.15);
		font-style: italic;
		// opacity: 0.8;
	}

	.header {
		background: rgba(0, 0, 0, 0.01);
		background: rgba(184, 168, 150, 0.3);
		// background: rgba(23, 178, 93, 0.05);
		border-bottom: 1px solid rgba(0, 0, 0, 0.043);
		display: flex;
		align-items: center;
		min-height: 56px;
		padding: 8px 16px;
		display: none;

		.meta {
			display: flex;
			align-items: center;
			flex-grow: 1;

			.icon {
				margin-right: 0.8rem;
				// flex-basis: 4%;
				width: 28px;

				&.small {
					font-size: 18px;
					opacity: 0.6;
				}
			}

			.state {
				font-size: 1.1rem;
				font-weight: bold;
				margin-right: 0.8rem;
				text-transform: uppercase;
			}

			.statuses {
				flex-grow: 1;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.center-text {
					display: flex;
					align-items: center;

					> *:last-child {
						margin: 0 0.33em;
					}
				}
			}
		}

		.actions,
		.inherited-info {
			margin-left: 2.5%;
			min-width: 10%;
			text-align: right;
		}
	}

	.content {
		margin-bottom: 1rem;
	}
}

.actions {
	float: right;
}

.qr-code {
	clear: right;
	float: right;
	margin-top: 10px;
	margin-left: 10px;
	border-radius: 6px;
	overflow: hidden;

	> * {
		display: block;
	}
}

.statuses {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
}
</style>
