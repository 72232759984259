<template>
	<div>
		<v-card-text>
			<v-text-field
				ref="search-input"
				:label="$t(`comp.transitTimesStopSelector.input.label`)"
				v-model="query"
				clearable
				prepend-icon="search"
				@focus="onFocus"
				@keydown="onKeydown"
				@input="search"
			/>
		</v-card-text>

		<div ref="results-menu-wrapper">
			<v-menu
				content-class="results-menu"
				v-model="showResults"
				:close-on-click="false"
				:position-x="resultsMenuX"
				:position-y="resultsMenuY"
				:max-height="300"
				:min-width="resultsMenuMinWidth"
			>
				<v-card class="results-menu--card" v-if="currentSearch">
					<v-progress-linear class="results-menu--loading" v-if="isLoading" :indeterminate="true" />

					<v-list v-else>
						<template v-if="hasResults">
							<v-list-item
								tag="div"
								v-for="(item, index) of results"
								:key="index"
								:class="{
									'results-menu--list--item': true,
									selected: resultsMenuSelectIndex === index,
								}"
								@click="selectStop(item)"
							>
								<v-layout row justify-start align-center>
									<span class="subheading">{{ item.label }}</span>

									<span class="results-menu--list--item--icons">
										<v-icon v-for="icon in item.icons" :key="icon">{{ icon }}</v-icon>
									</span>

									<v-spacer />

									<v-btn text class="results-menu--list--item--select-button">{{
										$t("comp.transitTimesStopSelector.input.selectButtonText")
									}}</v-btn>
								</v-layout>
							</v-list-item>
						</template>

						<template v-if="hasNoResults">
							<v-list-item class="results-menu--list--no-items">
								<v-list-item-content>
									<span class="subheading">
										{{ $t("comp.transitTimesStopSelector.input.noResults", { query }) }}
									</span>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-list>
				</v-card>
			</v-menu>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import { TransitStop } from "@/store";

export interface TransitTimesStopSelectorSearches {
	[query: string]: {
		loading: boolean;
		results?: TransitStop[];
	};
}

@Component
export default class TransitTimesStopSelectorSearchInput extends Vue {
	query = "";
	searches: TransitTimesStopSelectorSearches = {};

	showResults = false;
	resultsMenuMargin = 16;
	resultsMenuX = 0;
	resultsMenuY = 0;
	resultsMenuMinWidth = 0;
	resultsMenuSelectIndex: number | null = null;

	get currentSearch() {
		return this.searches[this.query];
	}

	get hasQuery() {
		return this.query && this.query.length >= 2;
	}

	get hasResults() {
		return this.results && this.results.length > 0;
	}

	get hasNoResults() {
		return !this.currentSearch || (this.results && this.results.length === 0);
	}

	get isLoading() {
		const search = this.currentSearch;

		return search && search.loading;
	}

	get results() {
		const search = this.currentSearch;

		return (search && search.results) || null;
	}

	async mounted() {
		this.attachEventListeners();
	}

	attachEventListeners() {
		document.addEventListener("click", this.onDocumentClick.bind(this));
	}

	onDocumentClick(e: MouseEvent & { path?: HTMLElement[] }) {
		const searchInput = this.$refs["search-input"] as Vue;

		if (!searchInput || !e.path) return;

		if (!e.path.includes(searchInput.$el as HTMLElement)) {
			this.closeResults();
		}
	}

	onKeydown(e: KeyboardEvent) {
		const moveList = e.code === "ArrowDown" ? 1 : e.code === "ArrowUp" ? -1 : null;

		if (this.results && moveList !== null) {
			e.preventDefault();

			if (moveList === -1 && (this.resultsMenuSelectIndex === null || this.resultsMenuSelectIndex === 0)) {
				this.resultsMenuSelectIndex = this.results.length - 1;
			} else if (
				moveList === 1 &&
				(this.resultsMenuSelectIndex === null || this.resultsMenuSelectIndex === this.results.length - 1)
			) {
				this.resultsMenuSelectIndex = 0;
			} else if (this.resultsMenuSelectIndex !== null) {
				this.resultsMenuSelectIndex = this.resultsMenuSelectIndex + moveList;
			}
		}

		if (this.results && e.code === "Enter" && this.resultsMenuSelectIndex !== null) {
			e.preventDefault();

			this.selectStop(this.results[this.resultsMenuSelectIndex]);
		}

		if (e.code === "Escape") {
			e.preventDefault();

			this.closeResults();
		}
	}

	onFocus() {
		if (this.hasQuery) {
			this.openResults();
		}
	}

	openResults() {
		this.calculateResultsMenuPosition();
		this.showResults = true;
	}

	closeResults() {
		this.showResults = false;
	}

	calculateResultsMenuPosition() {
		const resultsMenu = this.$refs["results-menu-wrapper"] as HTMLElement;
		const rect = resultsMenu.getBoundingClientRect();

		this.resultsMenuY = rect.top;
		this.resultsMenuX = rect.left + this.resultsMenuMargin;
		this.resultsMenuMinWidth = rect.width - this.resultsMenuMargin * 2;
	}

	async search() {
		this.resultsMenuSelectIndex = null;

		if (!this.hasQuery) {
			this.closeResults();

			return;
		}

		this.openResults();

		if (this.searches[this.query]) return;

		this.searches[this.query] = { loading: true };

		const results = await this.$store.dispatch("searchTransitStops", this.query);
		const newSearches = {
			...this.searches,
		};

		newSearches[this.query] = {
			results,
			loading: false,
		};

		this.searches = newSearches;
	}

	async selectStop(stop: TransitStop) {
		this.closeResults();
		this.query = "";

		if (!stop.stopId) return;

		this.$emit("stop-selected", stop);
	}
}
</script>

<style lang="scss" scoped>
.results-menu {
	transform: translateY(-38px);

	&--loading {
		margin: 0;
	}

	&--list {
		&--item {
			&.selected {
				background: rgba(23, 178, 93, 0.12);
			}

			&--icons {
				margin: 0 1rem;
			}

			&--select-button {
				opacity: 0;
				transition: 0.2s ease opacity;
			}

			&.selected &--select-button,
			&:hover &--select-button {
				opacity: 1;
			}
		}
	}
}
</style>
