<template>
	<layout-fluid class="layout-fluid-card">
		<sz-content-card title-extra-class="headline">
			<template slot="title" v-if="hasTitle">
				<slot name="title" />
			</template>

			<template slot="toolbar-actions">
				<slot name="toolbar-actions" />
			</template>

			<slot />
		</sz-content-card>
	</layout-fluid>
</template>

<script lang="ts">
	import Vue from "vue";
	import { Component } from "vue-property-decorator";

	import LayoutFluid from "@/layouts/Fluid.vue";

	@Component({
		components: { LayoutFluid },
	})
	export default class LayoutFluidCard extends Vue {
		get hasTitle() {
			return !!this.$slots.title;
		}
	}
</script>
