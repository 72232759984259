<template>
	<sz-empty-state
		icon="phone"
		layout="horizontal"
		:label="$t(`comp.autoApproveNumbers.emptyState.label`)"
		:description="$t(`comp.autoApproveNumbers.emptyState.description`)"
	>
		<template slot="actions">
			<v-btn color="primary" @click="editFunction()">{{
				$t("comp.autoApproveNumbers.emptyState.createButtonText")
			}}</v-btn>
		</template>
	</sz-empty-state>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class AutoApproveNumbersEmptyState extends Vue {
	@Prop({ required: true, type: [Function] })
	editFunction!: any;
}
</script>
