import { Component, Vue } from "vue-property-decorator";
import { Route } from "vue-router";

import router from "@/router";

const INITIAL_TITLE = document.title;
const TITLE_SEPARATOR = " - ";

// Returns the last matched route in stack (i.e. the deepest match in the tree)
// or `null` if no route matches were found.
function getLastMatchedRoute(route: Route) {
	return route.matched.length > 0
		? route.matched[route.matched.length - 1]
		: null;
}

// Returns the Vue component class of the last matched route, or `null` if no
// route match or component class was found.
function getLastMatchedComponentFromRoute(
	route: Route,
	componentName = "default"
) {
	const matched = getLastMatchedRoute(route);

	return matched && matched.components[componentName];
}

// Returns the Vue component instance of the last matched route, or `null` if no
// route match or instance was found.
function getLastMatchedInstanceFromRoute(
	route: Route,
	instanceName = "default"
) {
	let i = route.matched.length;

	while (i-- > 0) {
		const instance = route.matched[i].instances[instanceName];

		if (instance) {
			return instance;
		}
	}

	return null;
}

// Sets document title as defined by Vue instance `vm`.
function setTitle(vm: Vue) {
	let title: undefined | string | string[];

	// Get title from instance options.
	const { title: optionsTitle } = vm.$options;

	// If options title is defined use that.
	if (optionsTitle) {
		// Call function if callback, or use raw string.
		title =
			typeof optionsTitle === "function"
				? optionsTitle.call(vm, vm)
				: optionsTitle;
	}

	// Else if there exists a translation entry with key `title use that.
	else if (vm.$i18n && vm.$te("title")) {
		// But only if `vm` is the instance of current route.
		if (vm === getLastMatchedInstanceFromRoute(vm.$route)) {
			title = vm.$t("title") as string;
		}
	}

	// Else if current route has a meta with key `title`.
	else if (vm.$route && vm?.$route?.meta?.title) {
		title = vm.$route.meta.title;
	}

	// Stop process if no title has been found yet.
	if (!title) {
		return;
	}

	// Ensure title is an array of segments.
	if (!(title instanceof Array)) {
		title = [title];
	}

	// If `vm` has a route, scan through matches and append titles of parents.
	if (vm.$route) {
		title = title.concat(
			vm.$route.matched.map(r => r.meta.appendTitle).filter(r => !!r)
		);
	}

	// Apply translations.
	title = title.map(t => (vm.$te(t) && (vm.$t(t) as string)) || t);

	// Set new document title with retrieved value.
	setDocumentTitle(title);
}

// Sets the document title to provided `title`, appending the initial title to
// the end. If `title` is an array joins all items with title separator.
function setDocumentTitle(title: string[] = []) {
	document.title = [...title, INITIAL_TITLE].join(TITLE_SEPARATOR);
}

// Subscribe to after route events, so we can't unload the current title, and
// set up new manually if component is being reused.
router.afterEach(async (to, from) => {
	// Set title to blank
	setDocumentTitle();

	// Get component from both routes for testing.
	const toC = getLastMatchedComponentFromRoute(to);
	const fromC = getLastMatchedComponentFromRoute(from);

	// Stop processing if not the same route, as Vue will create a new instance of
	// the `to` component, triggering the mixin `create` call.
	if (toC !== fromC) {
		return;
	}

	// Update title manually if route is the same, as Vue reuses the DOM, and
	// hence the `created` on the mixin won't fire again.
	const instance = getLastMatchedInstanceFromRoute(to);

	// Return if no instance was found.
	if (!instance) {
		return;
	}

	// Await next tick, so Vue gets time to update itself.
	await instance.$nextTick();

	// Set title from new instance.
	setTitle(instance);
});

// Mixin for initiating the set title logic.
@Component
export default class UpdateDocumentTitleMixin extends Vue {
	// Only tries to run `setTitle` upon creation of the instance.
	created() {
		setTitle(this);
	}
}

// Type annotation to allow trouble free TS validation.
declare module "vue/types/options" {
	interface ComponentOptions<V extends Vue> {
		title?: string | ((this: V, vm: V) => string | string[]);
	}
}

// Attach mixin to global scope, so all components gets a chance to update
// the title.
Vue.mixin(UpdateDocumentTitleMixin);
