<template>
	<sz-content-card title-extra-class="title" :title="$t(`route.admin.displays.title`)">
		<template slot="toolbar-actions">
			<div class="toolbar-wrapper">
				<v-btn @click="showDebug = !showDebug" x-small>
					<v-icon class="ml-2">{{ showDebug ? "visibility" : "visibility_off" }}</v-icon>
					{{ "debug" }}
				</v-btn>

				<v-btn @click="showDemo = !showDemo" x-small>
					<v-icon class="ml-2">{{ showDemo ? "visibility" : "visibility_off" }}</v-icon>
					{{ "demo" }}
				</v-btn>

				<div v-if="selected.length > 0">
					<span>{{
						$tc(`route.admin.displays.selected.countSelected`, selected.length, { count: selected.length })
					}}</span>

					<v-btn color="primary" @click="update(selected)" small>
						{{ $t(`route.admin.displays.selected.updateSelected`) }}
						<v-icon class="ml-2">sync</v-icon>
					</v-btn>
				</div>

				<div v-else>
					<v-btn color="error" @click="update('all')" small>
						{{ $t(`route.admin.displays.updateAll`) }}
						<v-icon class="ml-2">sync</v-icon>
					</v-btn>
				</div>
			</div>
		</template>

		<v-data-table
			v-model="selected"
			:headers="headers"
			item-key="id"
			:items="displays"
			:items-per-page="-1"
			hide-default-footer
			show-select
		>
			<template v-slot:item.onlineStatus="{ item }">
				<display-online-status :display="item" />
			</template>

			<template v-slot:item.parentId="{ item }">
				<router-link v-if="item.parentId" :to="{ name: 'client--entity', params: { id: item.parentId } }">{{
					getOrganizationName(item)
				}}</router-link>
			</template>

			<template v-slot:item.name="{ item }">
				<router-link :to="{ name: 'client--entity', params: { id: item.id } }">{{ item.name }}</router-link>
			</template>
		</v-data-table>

		<v-snackbar bottom left v-model="updateSnackbar" :color="updateSnackbarColor" :timeout="2000">{{
			updateSnackbarText
		}}</v-snackbar>
	</sz-content-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

import { EntityInterface } from "@scrinz/dtos";
import { GetOrganizationOfEntityFunction } from "@/store";
import http from "@/http";

import { DisplayOnlineStatus } from "@/components/Display";

@Component({
	components: { DisplayOnlineStatus },
})
export default class AdminDisplays extends Vue {
	@Getter
	getOrganizationOfEntity!: GetOrganizationOfEntityFunction;

	showDebug = false;
	showDemo = true;

	internalDisplays: any[] = [];
	selected: any[] = [];

	updateSnackbar = false;
	updateSnackbarColor = "";
	updateSnackbarText = "";

	get headers() {
		return [
			{ text: this.$t("generic.id"), value: "id", align: "left" },
			{ text: this.$t("route.client.entities.types.Organization"), value: "parentId", align: "left" },
			{ text: this.$t("generic.name"), value: "name", align: "left" },
			{ text: this.$t("generic.codeWord"), value: "config.codeWord", align: "left" },
			{ text: this.$t("generic.online"), value: "onlineStatus", align: "center" },
		];
	}

	get displays() {
		return this.internalDisplays
			.filter((display) => !display.isDebug || this.showDebug)
			.filter((display) => !display.isDemo || this.showDemo);
	}

	getOrganizationName(entity: EntityInterface) {
		const org = this.getOrganizationOfEntity(entity);

		return org ? org.name : "-";
	}

	async created() {
		const res = await http.get("/admin/displays");

		this.internalDisplays = res.data.map((display: any) => {
			console.log(
				"ONLINE?:",
				display.onlineStatus,
				display.onlineStatus === false ? 3 : display.onlineStatus === true ? 1 : 2,
			);
			return {
				...display,
				value: false,
				onlineStatus: display.onlineStatus === false ? 3 : display.onlineStatus === true ? 1 : 2,
			};
		});
	}

	async update(entities: "all" | any[]) {
		if ((entities as any) === "all") {
			alert("Not implemented yet, due to risk of ddos'ing ourselves.");
			return;
		}

		const confirmed = await this.$confirm(
			entities === "all"
				? this.$t(`route.admin.displays.update.confirm.updateAll`)
				: this.$t(`route.admin.displays.update.confirm.updateSelected`, {
						count: this.selected.length,
				  }),
			{
				title: this.$t(`route.admin.displays.update.confirm.title`),
			},
		);

		if (!confirmed) return;

		const ids = entities === "all" ? "all" : entities.map((item) => item.id);

		const res = await http.post("admin/displays/update", { entities: ids });

		if (res.data.updated) {
			this.updateSnackbarText = this.$t(`route.admin.displays.update.success`) as string;
			this.updateSnackbarColor = "success";
		} else {
			this.updateSnackbarText = this.$t(`route.admin.displays.update.error`) as string;
			this.updateSnackbarColor = "error";
		}

		this.updateSnackbar = true;
		this.selected = [];
	}
}
</script>

<style lang="scss" scoped>
.toolbar-wrapper {
	display: flex;
	align-items: center;
	gap: 0.7rem;

	> button.v-btn {
		padding-left: 0;

		::v-deep .v-icon {
			font-size: 1.2rem;
			margin: 0.3rem;
		}
	}
}
</style>
