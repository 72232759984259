<template>
	<sz-card-form
		title-extra-class="title"
		:title="$t(`User Details`)"
		:error-message="$t(`Failed to update user details.`)"
		:success-message="$t(`User details successfully updated.`)"
		:data="user"
		:submit="submit"
		:fields="[
			{ key: 'name', label: $t('generic.name') },
			{ key: 'email', label: $t('generic.email') },
			{ key: 'phoneNumber', label: $t('generic.phoneNumber'), type: 'phone' },
		]"
	/>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AdminUserDetailsDetails extends Vue {
	@Prop()
	userId!: number;

	user!: any;

	created() {
		this.user = this.$store.getters.userById(this.userId, 10);
	}

	async submit(data: any) {
		return this.$store.dispatch("updateUser", data);
	}
}
</script>
