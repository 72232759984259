<template>
	<sz-card-form
		v-if="$can('admin')"
		:title="$t(`Special features`)"
		:error-message="$t(`Failed to update special features.`)"
		:success-message="$t(`Special features have been upated.`)"
		:data="features"
		:submit="submit"
		:submit-text="$t(`generic.actions.update`)"
		:fields="[
			{
				key: 'usbl',
				label: $t('Usbl features'),
				type: 'checkbox',
			},
		]"
	/>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import store from "@/store";

@Component
export default class ClientEntityDetailsSettingsFeatures extends Vue {
	@Prop({ required: true })
	id!: string;

	features: { [feature: string]: boolean } = {};

	@Watch("id", { immediate: true })
	async onEntityIdChange() {
		await this._updateFeatures();
	}

	async submit(data: any) {
		const features = Object.keys(data).filter((key) => data[key] === true);
		const entity = {
			features,
			id: this.id,
		};

		const res = await this.$store.dispatch("updateEntity", entity);
		await this._updateFeatures();

		return res;
	}

	private async _updateFeatures() {
		this.features = {};

		const entity = store.getters.getEntity(this.id);
		const features = (entity.features as string[]) ?? [];

		for (const feat of features) {
			this.features[feat] = true;
		}
	}
}
</script>
