import bunny from "./bunny.png";
import _17May from "./17-may.png";
import autum from "./autum.png";
import barbequing from "./barbequing.png";
import cigarette from "./cigarette.png";
import clearExits from "./clear-exits.png";
import closeDoor from "./close-door.png";
import dogBackyard from "./dog-backyard.png";
import drainage from "./drainage.png";
import dugnad from "./dugnad.png";
import electrical from "./electrical.png";
import fire from "./fire.png";
import garage from "./garage.png";
import grass from "./grass.png";
import halloween from "./halloween.png";
import handyman from "./handyman.png";
import meeting from "./meeting.png";
import quiet from "./quiet.png";
import roofTerrace from "./roof-terrace.png";
import summer from "./summer.png";
import trash from "./trash.png";
import winter from "./winter.png";
import warning from "./warning.png";

export const TEMPLATES = [
	{ id: "bunny", category: "seasonal", image: bunny, fontColor: "dark" },
	{ id: "17-may", category: "seasonal", image: _17May },
	{ id: "autum", category: "seasonal", image: autum },
	{ id: "barbequing", category: "rules", image: barbequing },
	{ id: "cigarette", category: "rules", image: cigarette },
	{ id: "clear-exits", category: "rules", image: clearExits },
	{ id: "close-door", category: "rules", image: closeDoor },
	{ id: "dog-backyard", category: "rules", image: dogBackyard },
	{ id: "drainage", category: "general", image: drainage },
	{ id: "dugnad", category: "rules", image: dugnad },
	{ id: "electrical", category: "hse", image: electrical },
	{ id: "fire", category: "hse", image: fire },
	{ id: "garage", category: "rules", image: garage },
	{ id: "grass", category: "rules", image: grass },
	{ id: "halloween", category: "seasonal", image: halloween },
	{ id: "handyman", category: "general", image: handyman },
	{ id: "meeting", category: "general", image: meeting },
	{ id: "quiet", category: "rules", image: quiet },
	{ id: "roof-terrace", category: "rules", image: roofTerrace },
	{ id: "summer", category: "seasonal", image: summer },
	{ id: "trash", category: "general", image: trash },
	{ id: "winter", category: "seasonal", image: winter },
	{ id: "warning", category: "general", image: warning },
];
