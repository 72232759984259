import { NavigationGuard } from "vue-router";

import store from "@/store";

export const IsAdminGuard: NavigationGuard = (to, _from, next) => {
	// Check user has the "admin" ability.
	if (store.getters.ability.can("admin")) {
		next();
	} else {
		next({ name: "client--error-404", params: { 0: to.path }});
	}
};

export default IsAdminGuard;
