<template>
	<div class="sz-loading-state">
		<v-progress-circular indeterminate :size="40" :width="5" />
		<span class="title">{{ title }}</span>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SzLoadingState extends Vue {
	@Prop({ default: "Loading..." }) title!: string;
}
</script>

<style lang="scss" scoped>
.sz-loading-state {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px;

	.title {
		margin-left: 40px;
	}
}
</style>
