<template>
	<div class="templates-editor-swimlane" :class="{ locked }">
		<templates-editor-slot
			class="slot"
			v-for="(slot, index) of slots"
			:key="index"
			:template="slot"
			:entity="entity"
			:is-locked="locked"
			:starts-before-view="doesSlotStartBeforeNow(slot)"
			:style="getStyleForSlot(slot)"
			:class="getClassForSlot(slot)"
			@edit="$listeners.edit"
		/>
	</div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { EntityInterface } from "@scrinz/dtos";
import TemplatesEditorSlot from "./Slot.vue";

// tslint:disable:no-magic-numbers
function toSizePx(days: number) {
	return `${days * 30}px`;
}

function diffInDays(first: Date, second: Date) {
	const diff = second.valueOf() - first.valueOf();

	return Math.ceil(diff / 1000 / 60 / 60 / 24);
}
// tslint:enable:no-magic-numbers

@Component({
	components: { TemplatesEditorSlot },
})
export default class TemplatesEditorSwimlane extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ required: true, type: [Date] })
	now!: Date;

	@Prop({ required: true })
	slots!: any[];

	@Prop({ default: false })
	locked!: boolean;

	getClassForSlot(slot: any) {
		return {
			"starts-before-now": this.doesSlotStartBeforeNow(slot),
		};
	}

	doesSlotStartBeforeNow({ start }: any) {
		return start.valueOf() < this.now.valueOf();
	}

	getStyleForSlot(slot: any) {
		return {
			top: this.getPositionOfSlot(slot),
			height: this.getHeightOfSlot(slot),
		};
	}

	getPositionOfSlot({ start }: any) {
		let pos = diffInDays(this.now, start);

		if (pos < 0) pos = 0;

		return toSizePx(pos);
	}

	getHeightOfSlot({ start, end }: any) {
		if (start.valueOf() < this.now.valueOf()) start = this.now;

		return toSizePx(diffInDays(start, end));
	}
}
</script>

<style lang="scss" scoped>
.templates-editor-swimlane {
	border: 1px solid lightgray;
	background: rgba(0, 0, 0, 0.1);
	border-top: none;
	border-bottom: none;

	&.locked {
		background: rgba(0, 0, 0, 0.3);
	}
}

.slot {
	position: absolute;
	width: 80%;
	left: 50%;
	transform: translate(-50%, 0);
}
</style>
