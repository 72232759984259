import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// TODO:
// * NoteBoard/EditNoteDialog save state

import en from "./locales/en.json";
import no from "./locales/no.json";

const messages: VueI18n.LocaleMessages = { en, no };

const i18n = new VueI18n({
	messages,
	locale: "no",
	fallbackLocale: "en",
});

export default i18n;
