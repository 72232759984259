import * as Cookie from "es-cookie";
import { ActionTree } from "vuex";

import { RootState } from "@/store";
import { PreferencesState } from "./types";

const LOCALE_COOKIE_KEY = "locale";

export const actions: ActionTree<PreferencesState, RootState> = {
	async init({ dispatch }) {
		const cookieLocale = Cookie.get(LOCALE_COOKIE_KEY);

		if (cookieLocale) await dispatch("setLocale", cookieLocale);
	},

	async onSessionChange({ getters }, state) {
		if (state) await this.dispatch("setLocale", getters.user.language);
	},

	setLocale({ commit }, locale: string | null) {
		if (locale) {
			Cookie.set(LOCALE_COOKIE_KEY, locale);
			commit("SET_LOCALE", locale);
		}
	},
};
