<template>
	<div class="note-status-pill" :class="[`note-status-pill--${color ?? 'default'}`]">
		<slot>
			{{ label }}
		</slot>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
	components: {},
})
export default class NoteBoardNoteStatusPill extends Vue {
	@Prop({ type: [String] })
	label!: string;

	@Prop({ type: [String] })
	color!: string;
}
</script>

<style lang="scss" scoped>
.note-status-pill {
	display: flex;
	padding: 4px 10px;
	justify-content: center;
	align-items: center;
	gap: 5px;
	text-wrap: nowrap;

	background: rgb(247, 247, 247);
	border: 1px solid grey;
	border-radius: var(--Spacing-xl, 40px);

	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	&--blue {
		color: #0070ff;
		border-color: #0070ff;
		background: #ebf4ff;
	}

	&--green {
		color: #29634d;
		border-color: #29634d;
		background: rgba(41, 99, 77, 0.1);
	}

	&--orange {
		color: #984200;
		border-color: #984200;
		background: #ffefe3;
	}

	&--red {
		color: #980000;
		border-color: #980000;
		background: #f0b6b6;
	}
}
</style>
