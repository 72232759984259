import { Module } from "vuex";

import { RootState } from "@/store/types";

import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

import { TransitStopsState } from "./types";
export * from "./types";

const state: TransitStopsState = {
	transitStops: [],
};

export const TransitStopsStore: Module<TransitStopsState, RootState> = {
	actions,
	getters,
	mutations,
	state,
};

export default TransitStopsStore;
