<template>
	<layout-topbar>
		<ClientSettingsProfile />
		<ClientSettingsPassword />
		<!--
			<layout-content-nav
				:content-nav-list="[
					{ to: '/settings/profile', label: 'Profilinstillinger' },
					{ to: '/settings/password', label: 'Passord' },
				]"
			>
				<router-view />
			</layout-content-nav>
		-->
	</layout-topbar>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import LayoutContentNav from "@/layouts/ContentNav.vue";
import LayoutTopbar from "@/layouts/Topbar.vue";

// import ClientSettingsLanguage from "./Settings/Language.vue";
import ClientSettingsPassword from "./Settings/Password.vue";
import ClientSettingsProfile from "./Settings/Profile.vue";

@Component({
	title: "generic.settings",
	components: {
		// ClientSettingsLanguage,
		ClientSettingsPassword,
		ClientSettingsProfile,
		LayoutContentNav,
		LayoutTopbar,
	},
})
export default class ClientSettings extends Vue {}
</script>
