import { MutationTree } from "vuex";

import { TransitStop, TransitStopsState } from "./types";

// tslint:disable:naming-convention
export const mutations: MutationTree<TransitStopsState> = {
	RESET_STATE(state) {
		state.transitStops = [];
	},

	ADD_TRANSIT_STOP(state, transitStop: TransitStop) {
		state.transitStops.push(transitStop);
	},

	DELETE_TRANSIT_STOP(state, transitStop: TransitStop) {
		const idx = state.transitStops.findIndex(s => s.id === transitStop.id);

		if (idx === -1) return;

		state.transitStops.splice(idx, 1);
	},

	UPDATE_TRANSIT_STOP(state, transitStop: TransitStop) {
		const current = state.transitStops.find(s => s.id === transitStop.id);

		if (!current) return mutations.ADD_TRANSIT_STOP(state, transitStop);

		Object.assign(current, transitStop);
	},

	UPDATE_TRANSIT_STOPS(state, transitStops: TransitStop[]) {
		for (const transitStop of transitStops) {
			mutations.UPDATE_TRANSIT_STOP(state, transitStop);
		}
	},
};
