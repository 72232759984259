<template>
	<v-data-table hide-default-footer :headers="headers" :items="sorted" :items-per-page="-1">
		<template v-slot:item.number="{ item }">
			{{ item.number | (sz - phone - number) }}
		</template>

		<template v-slot:item.expirationDate="{ item }">
			<span v-if="item.expirationDate" class="has-expiration-date" :class="{ expired: isExpired(item) }">
				<span v-if="isExpired(item)">
					{{ $t("comp.autoApproveNumbers.list.expirationDate.isExpired") }}
				</span>

				{{ item.expirationDate | moment("D. MMMM YYYY") }}
			</span>
			<span v-else class="no-expiration-date">
				{{ $t("comp.autoApproveNumbers.list.expirationDate.noExpirationDate") }}
			</span>
		</template>

		<template v-slot:item.actions="{ item }">
			<v-btn text icon @click="editFunction(item)">
				<v-icon>edit</v-icon>
			</v-btn>
			<v-btn text icon @click="remove(item)">
				<v-icon>delete</v-icon>
			</v-btn>
		</template>
	</v-data-table>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import natsort from "javascript-natural-sort";

import { EntityInterface } from "@scrinz/dtos";
import { AutoApproveNumber } from "@/store";

@Component
export default class AutoApporveNumbersList extends Vue {
	@Prop({ required: true, type: [EntityInterface, Object] })
	entity!: EntityInterface;

	@Prop({ required: true, type: [Function] })
	editFunction!: any;

	get headers() {
		return [
			{ text: this.$t("generic.phoneNumber"), value: "number", align: "right", sortable: false, width: "160px" },
			{ text: this.$t("generic.description"), value: "description", align: "left", sortable: false },
			{ text: this.$t("generic.date.ExpirationDate"), value: "expirationDate", align: "left", sortable: false },
			{ value: "actions", sortable: false },
		];
	}

	get numbers(): AutoApproveNumber[] {
		return this.$store.getters.autoApproveNumbersFor(this.entity);
	}

	get sorted() {
		return this.numbers.sort((a, b) => natsort(a.description, b.description));
	}

	isExpired(number: AutoApproveNumber) {
		const now = new Date();

		if (!number.expirationDate) return false;
		if (!(number.expirationDate instanceof Date)) {
			number.expirationDate = new Date(number.expirationDate);
		}

		return number.expirationDate.valueOf() < now.valueOf();
	}

	async remove(num: AutoApproveNumber) {
		const confirmed = await this.$confirm(this.$t(`comp.autoApproveNumbers.list.confirmDelete.text`, num), {
			color: "error",
			title: this.$t(`comp.autoApproveNumbers.list.confirmDelete.title`),
		});

		if (num.id && confirmed) {
			await this.$store.dispatch("deleteAutoApproveNumber", num);
		}
	}
}
</script>

<style lang="scss" scoped>
.no-expiration-date {
	color: grey;
	font-style: italic;
}

.expired {
	color: rgba(255, 0, 0, 0.6);
}
</style>
